import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getData } from '../services/request';
import * as jose from 'jose';
import { categoryFormatter, walletDataFormatter } from '../utils/formatter';
import { readCategory } from '../services/category-service';
import { readSimplePayment } from '../services/payment-service';
import { getServerPublicKey } from '../services/axios-instance';

export const status = [
  { name: 'Active', value: 'true' },
  { name: 'Non-Active', value: 'false' },
];

export const getWalletData = createAsyncThunk('app/getWalletData', async () => {
  const response = await readSimplePayment();
  if (!response.error) {
    const [payment, destination] = walletDataFormatter(response?.message?.list || []);
    return { payment, destination };
  }
  return { payment: [], destination: [] };
});

export const getCategoryData = createAsyncThunk('app/getCategoryData', async () => {
  const response = await readCategory({ word: '' });
  if (!response.error) {
    const category = categoryFormatter(response?.message?.list || []);
    return { category };
  }
  return { category: [{ value: 'test', name: 'test' }] };
});

export const getServerPublicKeyThunk = createAsyncThunk('app/getServerPublicKey', async () => {
  const response = await getServerPublicKey();
  if (!response.error) {
    const serverPublicKey = response?.data?.message?.data?.publicKey || null;
    return { serverPublicKey };
  }
  return { serverPublicKey: null };
});

export const generateClientKeyPairThunk = createAsyncThunk('app/generateClientKeyPairThunk', async (_, { getState }) => {
  const { alg } = getState().app;
  const { privateKey, publicKey } = await jose.generateKeyPair(alg, { extractable: true });

  const clientPrivateKey = await jose.exportPKCS8(privateKey);
  const clientPublicKey = await jose.exportSPKI(publicKey);

  return { clientPrivateKey, clientPublicKey };
});

const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    payment: [],
    destination: [],
    refreshPage: false,
    category: [],
    accessToken: null,
    csrfToken: null,
    // JWE
    serverPublicKey: null,
    clientPrivateKey: null,
    clientPublicKey: null,
    alg: 'RSA-OAEP-256',
    enc: 'A256GCM',
  },
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setRefreshPage: (state, action) => {
      state.refreshPage = action.payload;
    },
    adminLogin: (state, action) => {
      state.accessToken = action.payload;
    },
    adminLogout: (state) => {
      state.accessToken = null;
    },
    setCsrfToken: (state, action) => {
      state.csrfToken = action.payload;
    },
    unsetCsrfToken: (state) => {
      state.csrfToken = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getWalletData.fulfilled, (state, action) => {
        state.payment = action.payload.payment;
        state.destination = action.payload.destination;
      })
      .addCase(getWalletData.rejected, (state) => {
        state.payment = [];
        state.destination = [];
      })
      .addCase(getCategoryData.fulfilled, (state, action) => {
        state.category = action.payload.category;
      })
      .addCase(getCategoryData.rejected, (state) => {
        state.category = [];
      })
      .addCase(getServerPublicKeyThunk.fulfilled, (state, action) => {
        state.serverPublicKey = action.payload.serverPublicKey;
      })
      .addCase(getServerPublicKeyThunk.rejected, (state) => {
        state.serverPublicKey = null;
      })
      .addCase(generateClientKeyPairThunk.fulfilled, (state, action) => {
        state.clientPrivateKey = action.payload.clientPrivateKey;
        state.clientPublicKey = action.payload.clientPublicKey;
      })
      .addCase(generateClientKeyPairThunk.rejected, (state) => {
        state.clientPrivateKey = null;
        state.clientPublicKey = null;
      });
  },
});

export const {
  startLoading, stopLoading,
  startRefresh, stopRefresh, setRefreshPage,
  adminLogin, adminLogout,
  setCsrfToken, unsetCsrfToken,
} = appSlice.actions;
export default appSlice.reducer;

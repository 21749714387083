import React, { useEffect, useState } from 'react';
import { MdFirstPage, MdLastPage } from 'react-icons/md';

function Pagination(props) {
  const { pagination, setPagination } = props;
  const [pageNumberLists, setPageNumberLists] = useState([]);
  // const [maxPage, setMaxPage] = useState(Math.ceil(pagination.summary / pagination.limit));
  const maxPage = Math.ceil(pagination.summary / pagination.limit);

  const changePage = (page, maxPageArg, limit = pagination.limit) => {
    let current = page;
    if (page < 1) {
      current = 1;
    } else if (page > maxPageArg) {
      current = maxPageArg;
    }
    // setMaxPage(maxPageArg);

    const last = maxPageArg;
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    setPageNumberLists(rangeWithDots);
    return ({ page, limit, summary: pagination.summary });
  };

  useEffect(() => {
    changePage(pagination.page, maxPage);
  }, [pagination.page, pagination.limit, pagination.summary]);

  const start = 1 + ((pagination.page - 1) * pagination.limit);
  let end = pagination.limit * pagination.page;
  if (end > pagination.summary) {
    end = pagination.summary;
  }

  return (
    <div className="flex flex-row bg-[#F2F2F2] items-center p-2 justify-between">
      <div className="hidden sm:flex flex-row text-[#10397C]">
        Menampilkan
        <span className="font-bold">
          &nbsp;
          {start}
          {end === start
            ? ''
            : `-${end}`}
          {' '}
          dari
          {' '}
          {pagination.summary}
          &nbsp;
        </span>
        data
      </div>
      <div className="flex flex-row">
        Tampilkan
        <form className="mx-3">
          <select onChange={async (e) => {
            setPagination(changePage(
              pagination.page,
              Math.ceil(pagination.summary / e.target.value),
              e.target.value,
            ));
          }}
          >
            <option value={10}>10</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </form>
        data
      </div>
      <div className="flex flex-row gap-2">
        <button
          className="h-[30px] hover:bg-[#FFFFFF]"
          onClick={() => { setPagination(changePage(1, maxPage)); }}
          type="button"
        >
          <MdFirstPage />
        </button>
        {pageNumberLists.map((page, index) => {
          if (Number.isInteger(page)) {
            return (
              <button
                key={index}
                className="h-[30px] hover:bg-[#FFFFFF]"
                onClick={() => { setPagination(changePage(page, maxPage)); }}
                type="button"
              >
                {page}
              </button>
            );
          }
          return (
            <button
              key={index}
              className="h-[30px]"
              type="button"
            >
              {page}
            </button>
          );
        })}
        <button
          className="h-[30px] hover:bg-[#FFFFFF]"
          onClick={() => { setPagination(changePage(maxPage, maxPage)); }}
          type="button"
        >
          <MdLastPage />
        </button>
      </div>
    </div>
  );
}

export default Pagination;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import Pagination from '../../components/Pagination';
import { ModalForm } from '../../components/ModalForm';
import { VoucherForm } from './form/VoucherForm';
import { TableContent } from '../../components/TableContent';
import { ModalAlert } from '../../components/ModalAlert';
import {
  addVoucher, editVoucher, getVoucher, deleteVoucher,
} from '../../services/voucher-service';
import { startLoading, stopLoading } from '../../redux/slice';
import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';
import { formatDate2 } from '../../utils/formatter';
import { NewFilter } from '../../components/Filter';
import {
  voucherTypeList, discountTypeList, voucherNumberList, voucherForList,
} from '../../utils/constant';
import { getCSRF } from '../../services/request';

export function Voucher() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({ searchWord: '' });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    summary: 0,
  });
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [deleteData, setDeleteData] = useState('');
  const refresh = useSelector((state) => state.app).refreshPage;
  const [filter, setFilter] = useState({});
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const [voucherList, setVoucherList] = useState([]);
  const filterList = [
    {
      label: 'Jenis',
      element: {
        label: 'Filter Jenis',
        list: [
          {
            label: 'Jenis Voucher', type: 'dropdown', key: 'voucherType', list: voucherTypeList,
          },
          {
            label: 'Jenis Potongan', type: 'dropdown', key: 'discountType', list: discountTypeList,
          },
        ],
      },
    },
    {
      label: 'Jumlah',
      element: {
        label: 'Filter Jumlah',
        list: [
          {
            label: 'Jumlah Voucher', type: 'dropdown', key: 'voucherNumber', list: voucherNumberList,
          },
        ],
      },
    },
    {
      label: 'Date',
      element: {
        label: 'Date Waktu',
        list: [
          { label: 'Start Date', type: 'date', key: 'startDate' },
          { label: 'Expired Date', type: 'date', key: 'expiredDate' },
        ],
      },
    },
    {
      label: 'Referal',
      element: {
        label: 'Filter Referal',
        list: [
          {
            label: 'Voucher For', type: 'dropdown', key: 'voucherFor', list: voucherForList,
          },
        ],
      },
    },
  ];

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      voucherId: '',
      voucherName: '',
      voucherType: '',
      discountType: '',
      fixedDiscount: '',
      percentageDiscount: '',
      maximumDiscount: '',
      voucherNumber: '',
      voucherNumberType: 'unlimited',
      startDate: '',
      expiredDate: '',
      minimumTransaction: '',
      isForReferal: false,
      voucherDescription: '',
    },
    onSubmit: async (values) => {
      const voucherNumber = values.voucherNumberType === 'unlimited' ? '' : values.voucherNumber;
      const dataValues = { ...values, voucherNumber };

      dispatch(startLoading());
      let response;
      if (modalUpdate) response = await editVoucher(dataValues);
      else if (deleteData) response = await deleteVoucher({ voucherId: values.voucherId });
      else response = await addVoucher(dataValues);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        setModalFormVisibility(false);
        setModalUpdate(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setResponseMessage('');
        setDeleteData('');
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdate) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdate]);

  useEffect(() => {
    const getVoucherData = async () => {
      dispatch(startLoading());
      const response = await getVoucher({
        searchWord: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        sortBy: sortColumn.sortBy,
        sortValue: sortColumn.sortOrder,
        voucherType: filter.voucherType,
        discountType: filter.discountType,
        voucherNumber: filter.voucherNumber,
        startDate: filter.startDate,
        expiredDate: filter.expiredDate,
        voucherFor: filter.voucherFor,
      });
      dispatch(stopLoading());
      setVoucherList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getVoucherData();
  }, [search, pagination.page, refresh, pagination.limit, filter]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    if (selectedData) {
      const voucherNumber = Number.isNaN(parseInt(selectedData.voucherNumber, 10)) ? '' : selectedData.voucherNumber;
      const startDate = selectedData.startDate && selectedData.startDate !== 'unlimited' ? formatDate2(selectedData.startDate) : '';
      const expiredDate = selectedData.expiredDate && selectedData.expiredDate !== 'unlimited' ? formatDate2(selectedData.expiredDate) : '';
      formik.setFieldValue('voucherId', selectedData.voucherId || '');
      formik.setFieldValue('voucherName', selectedData.voucherName || '');
      formik.setFieldValue('voucherType', selectedData.voucherType || '');
      formik.setFieldValue('discountType', selectedData.discountType || '');
      formik.setFieldValue('fixedDiscount', selectedData.fixedDiscount || '');
      formik.setFieldValue('percentageDiscount', selectedData.percentageDiscount || '');
      formik.setFieldValue('maximumDiscount', selectedData.maximumDiscount || '');
      formik.setFieldValue('voucherNumber', voucherNumber);
      formik.setFieldValue('voucherNumberType', voucherNumber ? 'limited' : 'unlimited');
      formik.setFieldValue('startDate', startDate);
      formik.setFieldValue('expiredDate', expiredDate);
      formik.setFieldValue('minimumTransaction', selectedData.minimumTransaction || 0);
      formik.setFieldValue('isForReferal', selectedData.isForReferal || false);
      formik.setFieldValue('voucherDescription', selectedData.voucherDescription || '');
    }
  }, [selectedData]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('voucherId', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Apakah anda ingin menghapus data ini ?' });
    }
  }, [deleteData]);

  return (
    <div className="h-full">
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setModalAlert((data) => ({ ...data, visibility: false }));
          setDeleteData('');
          formik.resetForm();
        }}
        handleConfirm={formik.handleSubmit}
      />
      <ModalForm
        visibility={modalFormVisibility || modalUpdate}
        handleVisibility={() => { setModalFormVisibility(false); setModalUpdate(false); formik.resetForm(); setResponseMessage(''); }}
        buttonText={modalUpdate ? 'Edit Voucher' : 'Buat Voucher'}
        onSubmit={formik.handleSubmit}
        message={responseMessage}
        className="sm:max-w-[800px] overflow-visible flex flex-col gap-4"
        height="300px"
        fromCMS
      >
        <VoucherForm formik={formik} />
      </ModalForm>
      <BoxContent>
        <Searchbar
          className="mb-6"
          onChange={setSearch}
          withButton
          buttonName="Tambah Voucher"
          onClick={() => { formik.resetForm(); setModalFormVisibility(true); }}
        />
        <NewFilter
          onChange={setFilter}
          filterList={filterList}
        />
        {pagination.summary > 0
          ? (
            <>
              <TableContent
                type="voucher"
                dataList={voucherList}
                modalUpdate={setModalUpdate}
                selectedData={setSelectedData}
                deleteData={setDeleteData}
                setSortColumn={setSortColumn}
              />
              <Pagination
                pagination={pagination}
                setPagination={(e) => setPagination(e)}
              />
            </>
          )
          : <NoDataView />}
        <div className="flex mt-6">
          <div className="text-[#F28C20]">*</div>
          Voucher For Referral
        </div>
      </BoxContent>
    </div>
  );
}

import { getData, postData } from './request';

export const getReportList = async ({
  searchWord, limit, page, order,
}) => {
  const response = await getData({
    path: '/admin/verify-report'
      + `?q=${searchWord}`
      + `&limit=${limit}`
      + `&page=${page}`
      + `&order=${order}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const getReportDetailList = async ({ phoneNumber }) => {
  const response = await getData({
    path: `/admin/verify-report/${phoneNumber}`,
    withToken: true,
    pathParams: [phoneNumber],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const acceptReport = async ({ phoneNumberReportId }) => {
  const response = await postData({
    path: `/admin/verify-report/accept/${phoneNumberReportId}`,
    withToken: true,
    pathParams: [phoneNumberReportId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const rejectReport = async ({ phoneNumberReportId }) => {
  const response = await postData({
    path: `/admin/verify-report/reject/${phoneNumberReportId}`,
    withToken: true,
    pathParams: [phoneNumberReportId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

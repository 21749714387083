import React from 'react';
import {
  ImageFile, Input, DateInput, SimpleOption, TextArea,
} from '../../../components/Oripay';

export function NotificationForm({
  formik, isDetail, selectedFile, handleFile, notifTypeList, editMode, modalImg,
}) {
  if (isDetail && !editMode) {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-row flex-1 gap-4">
          <Input
            className="w-1/2"
            type="text"
            label="ID Notifikasi"
            styling="form"
            value={formik.values.notifId}
            disabled
          />
          <Input
            className="w-1/2"
            type="text"
            label="Jenis Notifikasi"
            styling="form"
            value={formik.values.jenisNotif}
            disabled
          />
        </div>
        <div className="flex flex-1">
          <Input
            type="text"
            label="Judul Notifikasi"
            styling="form"
            value={formik.values.judul}
            disabled
          />
        </div>
        <div className="flex flex-row flex-1 gap-4">
          <div className="flex flex-col flex-1 gap-2">
            <span className="text-black text-sm font-dmsans font-bold ">
              Tanggal Mulai :
            </span>
            <Input
              type="text"
              styling="form"
              value={
                new Date(formik.values.tanggalMulai).toLocaleString('id-ID', { year: 'numeric', month: 'short', day: 'numeric' })
              }
              disabled
            />
          </div>
          <div className="flex flex-col flex-1 gap-2">
            <span className="text-black text-sm font-dmsans font-bold ">
              Tanggal Selesai :
            </span>
            <Input
              type="text"
              styling="form"
              value={
                new Date(formik.values.tanggalSelesai).toLocaleString('id-ID', { year: 'numeric', month: 'short', day: 'numeric' })
              }
              disabled
            />
          </div>
        </div>
        <div className="flex flex-1">
          <div className="flex flex-1 justify-end">
            <div className="flex flex-col gap-2 w-full">
              <span className="text-black  text-sm font-dmsans font-bold">
                Gambar Notifikasi
              </span>
              <div className="relative">
                {
                  formik.values.imageLink
                    ? (
                      <div
                        className="flex flex-1 cursor-pointer justify-center border border-[#eaeaea] rounded-md h-32 overflow-hidden"
                        onClick={() => {
                          modalImg({ visibility: true, src: formik.values.imageLink });
                        }}
                      >
                        <img
                          src={formik.values.imageLink}
                          className="w-full h-32 object-fill"
                          alt=""
                        />
                      </div>
                    )
                    : (
                      <div className="flex justify-center border
                        border-[#eaeaea] bg-[#E5E5E5] rounded-md py-8"
                      >
                        No image
                      </div>
                    )
                }
              </div>

            </div>
          </div>
        </div>
        <div className="flex flex-1">
          <Input
            type="text"
            label="Pesan Notifikasi"
            styling="form"
            value={formik.values.pesan}
            disabled
          />
        </div>
        <div className="flex flex-1">
          <Input
            type="text"
            label="URL Webview"
            styling="form"
            value={formik.values.urlWebview}
            disabled
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row flex-1 gap-4">
        <Input
          className={`${editMode ? '' : 'hidden'} w-1/2`}
          type="text"
          label="ID Notifikasi"
          styling="form"
          placeholder="Masukkan ID Notifikasi"
          name="notifId"
          onChange={formik.handleChange}
          value={formik.values.notifId}
          disabled
        />
        <div className="flex-1">
          <SimpleOption
            label="Jenis Notifikasi"
            list={notifTypeList}
            value={formik.values.jenisNotif}
            placeholder="Pilih Jenis"
            name="jenisNotif"
            onSelect={formik.handleChange}
          />
        </div>
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="Judul"
          styling="form"
          placeholder="Masukkan Judul"
          name="judul"
          onChange={formik.handleChange}
          value={formik.values.judul}
        />
      </div>
      <div className="flex flex-row flex-1 gap-4">
        <div className="flex flex-col flex-1 gap-2">
          <span className="text-black text-sm font-dmsans font-bold ">
            Tanggal Mulai :
          </span>
          <DateInput
            placeholder="Masukkan Tanggal Mulai"
            name="tanggalMulai"
            onChange={formik.handleChange}
            value={formik.values.tanggalMulai}
          />
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <span className="text-black text-sm font-dmsans font-bold ">
            Tanggal Selesai :
          </span>
          <DateInput
            placeholder="Masukkan Tanggal Selesai"
            name="tanggalSelesai"
            onChange={formik.handleChange}
            value={formik.values.tanggalSelesai}
          />
        </div>
      </div>
      <div className="flex flex-1">
        <ImageFile
          handleFile={handleFile}
          name="notificationImg"
          label="Gambar Notifikasi"
          styling="form"
          selectedFile={selectedFile}
          imageUrl={formik.values.imageLink}
          noWarning
        />
      </div>
      <div className="flex flex-1">
        <TextArea
          type="text"
          label="Pesan Notifikasi"
          styling="form"
          placeholder="Masukkan Pesan Notifikasi"
          name="pesan"
          onChange={formik.handleChange}
          value={formik.values.pesan}
        />
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="URL Webview"
          styling="form"
          placeholder="Masukkan Judul"
          name="urlWebview"
          onChange={formik.handleChange}
          value={formik.values.urlWebview}
        />
      </div>
    </div>
  );
}

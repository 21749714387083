export const voucherTypeList = [
  { label: 'Biaya Admin', value: 'biaya_admin' },
  { label: 'Total Transaksi', value: 'total_transaksi' },
];

export const discountTypeList = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Percentage', value: 'percentage' },
];

export const voucherNumberList = [
  { label: 'Terbatas', value: 'limited' },
  { label: 'Tidak Terbatas', value: 'unlimited' },
];

export const voucherForList = [
  { label: 'Referal', value: 'referal' },
  { label: 'Not Referal', value: 'not_referal' },
];

export const statusList = [
  { label: 'Active', value: 'active' },
  { label: 'Non-Active', value: 'non_active' },
];

export const err = { error: 'Can\'t Connect To Server' };

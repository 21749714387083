import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import { ModalForm } from '../../components/ModalForm';
import { DestinationForm } from './form/DestinationForm';
import { TableContent } from '../../components/TableContent';
import { ModalAlert } from '../../components/ModalAlert';
import {
  addDestination, editDestination, deleteDestination, readDestination,
} from '../../services/destination-service';
import { refreshPage, getCategory, dispatchSetCSRF } from '../../redux/dispatch';
import { startLoading, stopLoading } from '../../redux/slice';
import { getCSRF } from '../../services/request';

export function Destination() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({ searchWord: '' });
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [deleteData, setDeleteData] = useState('');
  const categoryList = useSelector((state) => state.app).category;
  const refresh = useSelector((state) => state.app).refreshPage;
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const [destinationList, setDestinationList] = useState([]);

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      destinationId: '',
      destinationCategory: '',
      destinationName: '',
      paymentImgLink: '',
      minTrx: '',
      maxTrx: '',
      availabilityQR: false,
      availabilityManual: false,
      disbursementAgent: '',
      disbursementProductCode: '',
      messageQR: '',
      messageManual: '',
      laba: '',
      fee: '',
      tax: '',
    },
    onSubmit: async (values) => {
      const availability = values.availability === '' ? '' : values.availability === 'true';
      const dataValue = {
        ...values,
        messageQR: values.messageQR === '' ? null : values.messageQR,
        messageManual: values.messageManual === '' ? null : values.messageManual,
        availability,
      };

      dispatch(startLoading());
      let response;
      if (modalUpdate) response = await editDestination(dataValue);
      else if (deleteData) response = await deleteDestination(dataValue);
      else response = await addDestination(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        getCategory();
        setModalFormVisibility(false);
        setModalUpdate(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setDeleteData('');
        setResponseMessage('');
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdate) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdate]);

  useEffect(() => {
    const getDestinationData = async () => {
      dispatch(startLoading());
      const response = await readDestination({
        word: search.searchWord,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      dispatch(stopLoading());
      setDestinationList(response?.message?.list || []);
    };
    getDestinationData();
  }, [search, refresh, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    if (selectedData) {
      formik.setFieldValue('destinationId', selectedData.id_destination);
      formik.setFieldValue('destinationCategory', categoryList.find((data) => data.name === selectedData.kategori_payment).value);
      formik.setFieldValue('destinationName', selectedData.nama_payment);
      formik.setFieldValue('paymentImgLink', selectedData.url_logo);
      formik.setFieldValue('minTrx', selectedData.min_trx);
      formik.setFieldValue('maxTrx', selectedData.max_trx);
      formik.setFieldValue('availabilityQR', selectedData.status_payment_qr);
      formik.setFieldValue('availabilityManual', selectedData.status_payment_manual);
      formik.setFieldValue('disbursementAgent', selectedData.disbursement_agent);
      formik.setFieldValue('disbursementProductCode', selectedData.disbursement_product_code);
      formik.setFieldValue('messageQR', selectedData.message_qr);
      formik.setFieldValue('messageManual', selectedData.message_manual);
      formik.setFieldValue('laba', selectedData.laba);
      formik.setFieldValue('fee', selectedData.fee);
      formik.setFieldValue('tax', selectedData.tax);
    }
  }, [selectedData]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('destinationId', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Apakah anda yakin ingin menghapus data ini?' });
    }
  }, [deleteData]);

  return (
    <div className="h-full">
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setModalAlert((data) => ({ ...data, visibility: false }));
          setDeleteData('');
          formik.resetForm();
        }}
        handleConfirm={formik.handleSubmit}
      />
      <ModalForm
        visibility={modalFormVisibility || modalUpdate}
        handleVisibility={() => { setModalFormVisibility(false); setModalUpdate(false); formik.resetForm(); setResponseMessage(''); }}
        buttonText={modalUpdate ? 'Edit Destinasi' : 'Buat Destinasi'}
        onSubmit={formik.handleSubmit}
        message={responseMessage}
        className="sm:max-w-[600px]"
        height="300px"
      >
        <DestinationForm
          formik={formik}
          isUpdate={modalUpdate}
        />
      </ModalForm>
      <BoxContent>
        <Searchbar
          className="mb-6"
          onChange={setSearch}
          withButton
          buttonName="Buat Destinasi"
          onClick={() => { setModalFormVisibility(true); }}
        />
        {destinationList.length > 0
          ? (
            <TableContent
              type="destination"
              dataList={destinationList}
              modalUpdate={setModalUpdate}
              selectedData={setSelectedData}
              deleteData={setDeleteData}
              setSortColumn={setSortColumn}
            />
          )
          : <NoDataView />}

      </BoxContent>
    </div>
  );
}

import React from 'react';
import {
  voucherTypeList, discountTypeList, voucherNumberList,
} from '../../../utils/constant';
import {
  Input, RadioInput, DateInput,
} from '../../../components/Oripay';

export function VoucherForm({ formik }) {
  return (
    <div className="w-full flex flex-col gap-4 pb-4">
      <div className="flex gap-4 flex-col sm:flex-row w-full">
        <div className="flex flex-col gap-4 flex-1">
          <div className="w-full">
            <Input
              type="text"
              label="Nama Voucher"
              styling="form"
              placeholder="Nama Voucher"
              name="voucherName"
              onChange={formik.handleChange}
              value={formik.values.voucherName}
            />
          </div>
          <div className="w-full">
            <RadioInput
              label="Jenis Voucher"
              name="voucherType"
              list={voucherTypeList}
              onChange={formik.handleChange}
              value={formik.values.voucherType}
            />
          </div>
          <div className="w-full">
            <RadioInput
              label="Jenis Potongan"
              name="discountType"
              list={discountTypeList}
              onChange={formik.handleChange}
              value={formik.values.discountType}
            />
          </div>
          <div className={`w-full ${!formik.values.discountType ? 'hidden' : ''}`}>
            <Input
              type={formik.values.discountType === 'fixed' ? 'money' : 'percentage'}
              label="Jumlah Potongan"
              styling="form"
              placeholder="Masukkan Jumlah Potongan"
              name={formik.values.discountType === 'fixed' ? 'fixedDiscount' : 'percentageDiscount'}
              onChange={formik.handleChange}
              value={
                formik.values.discountType === 'fixed'
                  ? formik.values.fixedDiscount : formik.values.percentageDiscount
              }
            />
          </div>
          <div className={`w-full 
            ${formik.values.discountType === 'fixed' || !formik.values.discountType ? 'hidden' : ''}
          `}
          >
            <Input
              type="money"
              label="Maksimal Potongan"
              styling="form"
              placeholder="Masukkan Maksimal Potongan"
              name="maximumDiscount"
              onChange={formik.handleChange}
              value={formik.values.maximumDiscount}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <div className="w-full flex flex-col gap-2">
            <RadioInput
              label="Jumlah Voucher"
              name="voucherNumberType"
              list={voucherNumberList}
              onChange={formik.handleChange}
              value={formik.values.voucherNumberType}
            />
            <div className={`w-full ${formik.values.voucherNumberType === 'unlimited' || !formik.values.voucherNumberType ? 'hidden' : ''}`}>
              <Input
                type="number"
                label=""
                styling="form"
                placeholder="Jumlah Voucher"
                name="voucherNumber"
                onChange={formik.handleChange}
                value={formik.values.voucherNumber}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <span className="text-black text-sm font-dmsans font-bold">Batas Waktu</span>
            <div className="w-full flex gap-4">
              <div className="flex-1">
                <DateInput
                  placeholder="Tanggal Mulai"
                  name="startDate"
                  onChange={formik.handleChange}
                  value={formik.values.startDate}
                />
              </div>
              <div className="flex-1">
                <DateInput
                  placeholder="Tanggal Selesai"
                  name="expiredDate"
                  onChange={formik.handleChange}
                  value={formik.values.expiredDate}
                />
              </div>
            </div>
            <span className="text-black text-sm font-dmsans italic">
              Jika tanggal mulai tidak diisi, voucher akan berlaku sekarang,
              jika tanggal selesai tidak diisi, voucher tidak memiliki batas waktu
            </span>
          </div>
          <div className="w-full">
            <Input
              type="money"
              styling="form"
              label="Minimal Transaksi"
              placeholder="Minimal Transaksi"
              name="minimumTransaction"
              onChange={formik.handleChange}
              value={formik.values.minimumTransaction}
            />
          </div>
          <div className="w-full flex gap-2">
            <input
              type="checkbox"
              name="isForReferal"
              checked={formik.values.isForReferal}
              onChange={formik.handleChange}
            />
            <span className="font-dmsans">For Referal</span>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-2">
        <span className="text-black text-sm font-dmsans font-bold">S&K Voucher</span>
        <textarea
          className="border-[1px] pl-4 py-3 rounded-md h-[100px] font-normal text-base leading-[21px]"
          name="voucherDescription"
          value={formik.values.voucherDescription}
          onChange={formik.handleChange}
          placeholder="S&K Voucher"
        />
      </div>
    </div>
  );
}

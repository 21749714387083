import {
  deleteData, getData, postData, putData,
} from './request';

export const addPayment = async ({
  paymentId, paymentCategory, paymentName, paymentImgLink, adminName,
  adminPhoneNumber, adminFee, adminFeePercentage, availability,
}) => {
  const response = await postData({
    path: '/admin/metode-bayar',
    data: {
      paymentId,
      paymentCategory,
      paymentName,
      paymentImgLink,
      adminName,
      adminPhoneNumber,
      adminFee: adminFee || 0,
      adminFeePercentage: adminFeePercentage || 0,
      availability,
    },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const readPayment = async ({ word, order }) => {
  const response = await getData({
    path: `/admin/metode-bayar?word=${word}&order=${order}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const readSimplePayment = async () => {
  const response = await getData({
    path: '/admin/metode-bayar/simple-list',
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editPayment = async ({
  paymentId, paymentCategory, paymentName, paymentImgLink, adminName,
  adminPhoneNumber, adminFee, adminFeePercentage, availability, messageAvailability,
}) => {
  const response = await putData({
    path: `/admin/metode-bayar/${paymentId}`,
    data: {
      paymentId,
      paymentCategory,
      paymentName,
      paymentImgLink,
      adminName,
      adminPhoneNumber,
      adminFee: adminFee || 0,
      adminFeePercentage: adminFeePercentage || 0,
      availability,
      messageAvailability,
    },
    withToken: true,
    pathParams: [paymentId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const deletePayment = async ({ paymentId }) => {
  const response = await deleteData({
    path: `/admin/metode-bayar/${paymentId}`,
    withToken: true,
    pathParams: [paymentId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import { getData } from './request';
import { err } from '../utils/constant';

export const getDepositBalance = async () => {
  try {
    const response = await getData({
      path: '/admin/summary/deposit-balance',
      withToken: true,
    });
    if (response.error) return err;
    return response.data;
  } catch (error) {
    return err;
  }
};

export const getSummary = async ({ from, to }) => {
  try {
    const response = await getData({
      path: `/admin/summary${from && to ? `?fromDate=${from}&toDate=${to}` : ''}`,
      withToken: true,
    });
    if (response.error) return err;
    return response.data;
  } catch (error) {
    return err;
  }
};

export const getGraphSummary = async ({ from, to }) => {
  try {
    const response = await getData({
      path: `/admin/summary/transaction-graph${from && to ? `?fromDate=${from}&toDate=${to}` : ''}`,
      withToken: true,
    });
    const result = response?.data?.message?.list;
    if (response.error || !Array.isArray(result)) return err;

    const dateGraphArray = [];
    const nominalGraphArray = [];
    const totalGraphArray = [];
    const userGraphArray = [];

    let startDate = new Date();
    let endDate = startDate;

    if (from && to) {
      startDate = new Date(from);
      endDate = new Date(to);

      startDate.setHours(0);
      startDate.setDate(startDate.getDate() + 1);
      endDate.setHours(0);
    }

    for (let current = startDate; current <= endDate; current.setDate(current.getDate() + 1)) {
      dateGraphArray.push(current.toISOString());
      const index = result.findIndex((data) => data.tgl === current.toISOString());

      if (index > -1) {
        nominalGraphArray.push(result[index].sum_nominal);
        totalGraphArray.push(result[index].count_trx);
        userGraphArray.push(result[index].count_user);
      } else {
        nominalGraphArray.push(0);
        totalGraphArray.push(0);
        userGraphArray.push(0);
      }
    }

    return [dateGraphArray, nominalGraphArray, totalGraphArray, userGraphArray];
  } catch (error) {
    return { error: 'Can\'t Connect To Server' };
  }
};

import React from 'react';
import { startLoading, stopLoading } from '../../redux/slice';
import { acceptReport, rejectReport } from '../../services/verify-report-service';
import { refreshPage } from '../../redux/dispatch';

export function showAcceptConfirm(setRejectData, setModalAlert, acceptData, getTokenCSRF) {
  getTokenCSRF();
  setRejectData({});
  setModalAlert({
    visibility: true,
    type: 'confirm',
    message: (
      <span>
        Apakah anda ingin menambahkan
        {' '}
        <span className="text-[#FF0000]">{acceptData.number}</span>
        {' '}
        <br />
        {' '}
        ke daftar blacklist?
      </span>
    ),
  });
}

export async function handleAccept(dispatch, acceptData, setModalAlert, getTokenCSRF) {
  dispatch(startLoading());
  const response = await acceptReport({ phoneNumberReportId: acceptData.id });
  dispatch(stopLoading());

  if (response.error) {
    setModalAlert({
      visibility: true,
      type: 'failed',
      message: response.error,
    });
  } else if (response.code !== 200) {
    setModalAlert({
      visibility: true,
      type: 'failed',
      message: response.errors[Object.keys(response.errors)[0]],
    });
    getTokenCSRF();
  } else {
    setModalAlert({
      visibility: true,
      type: 'success',
      message: (
        <span>
          Berhasil menambahkan
          {' '}
          <span className="text-[#FF0000]">{acceptData.number}</span>
          {' '}
          <br />
          {' '}
          ke daftar blacklist
        </span>
      ),
    });
    refreshPage();
  }
}

export function showRejectConfirm(setAcceptData, setModalAlert, rejectData, getTokenCSRF) {
  getTokenCSRF();
  setAcceptData({});
  setModalAlert({
    visibility: true,
    type: 'confirm',
    message: (
      <span>
        Apakah anda ingin menghapus
        {' '}
        <span className="text-[#FF0000]">{rejectData.number}</span>
        {' '}
        <br />
        {' '}
        dari daftar report?
      </span>
    ),
  });
}

export async function handleReject(dispatch, rejectData, setModalAlert, getTokenCSRF) {
  dispatch(startLoading());
  const response = await rejectReport({ phoneNumberReportId: rejectData.id });
  dispatch(stopLoading());

  if (response.error) {
    setModalAlert({
      visibility: true,
      type: 'failed',
      message: response.error,
    });
  } else if (response.code !== 200) {
    setModalAlert({
      visibility: true,
      type: 'failed',
      message: response.errors[Object.keys(response.errors)[0]],
    });
    getTokenCSRF();
  } else {
    setModalAlert({
      visibility: true,
      type: 'success',
      message: (
        <span>
          Berhasil menghapus
          {' '}
          <span className="text-[#FF0000]">{rejectData.number}</span>
          {' '}
          <br />
          {' '}
          dari daftar report
        </span>
      ),
    });
    refreshPage();
  }
}

import { getData } from './request';

export const readRegisteredUser = async ({
  limit, page, q, order,
}) => {
  const response = await getData({
    path: `/management/user?limit=${limit}&page=${page}&q=${q}&order=${order}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import { uploadGcp } from './gcp-service';
import {
  deleteData, getData, postData, putData,
} from './request';

export const getNotification = async ({
  page, limit, searchBy, searchQuery, orderBy, orderValue,
  notificationType, startDate, endDate,
}) => {
  const response = await getData({
    path:
      '/admin/notification/lists'
        + `?page=${page}`
        + `&limit=${limit}`
        + `&searchBy=${searchBy}`
        + `&searchQuery=${searchQuery}`
        + `&orderBy=${orderBy}`
        + `&orderValue=${orderValue}`
        + `&type=${notificationType}`
        + `&dateStart=${startDate}`
        + `&dateEnd=${endDate}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const getNotificationType = async () => {
  const response = await getData({
    path: '/admin/notification-type/lists',
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const addNotification = async ({
  jenisNotif, judul, tanggalMulai, tanggalSelesai, pesan, urlWebview, file,
}) => {
  // if no file is selected
  if (!file) {
    const response = await postData({
      path: '/admin/notification',
      data: {
        jenisNotif, judul, tanggalMulai, tanggalSelesai, pesan, urlWebview, attachment: !!file,
      },
      withToken: true,
    });
    if (response.error) return { error: response.error };
    return response.status === 200 ? { code: 200, status: 'OK', message: { message: 'Berhasil menambahkan notifikasi' } } : response.data;
  }

  // if file is selected
  const response = await postData({
    path: '/admin/notification',
    data: {
      jenisNotif, judul, tanggalMulai, tanggalSelesai, pesan, urlWebview, attachment: !!file,
    },
    withToken: true,
  }).then((responseCreate) => {
    if (responseCreate.error || responseCreate.data.errors) return responseCreate;
    return postData({
      path: `/admin/notif-upload/requestUploadLinkNotif/${responseCreate.data.message.data.notifId}`,
      data: {
        filesize: file.size,
        dataType: file.type.replaceAll('image/', ''),
      },
      withToken: true,
      pathParams: [responseCreate.data.message.data.notifId],
    });
  }).then((responseReqLink) => {
    if (responseReqLink.error || responseReqLink.data.errors) return responseReqLink;
    return uploadGcp({ url: responseReqLink.data.message.data.url, file });
  });
  if (response.error) return { errors: response.error };
  return response.status === 200 ? { code: 200, status: 'OK', message: { message: 'Berhasil menambahkan notifikasi' } } : response.data;
};

export const editNotification = async ({
  notifId, jenisNotif, judul, tanggalMulai, tanggalSelesai, pesan, urlWebview, file,
}) => {
  // if no file is selected
  if (!file) {
    const response = await putData({
      path: `/admin/notification/${notifId}`,
      data: {
        jenisNotif, judul, tanggalMulai, tanggalSelesai, pesan, urlWebview, attachment: !!file,
      },
      withToken: true,
      pathParams: [notifId],
    });
    if (response.error) return { error: response.error };
    return response.status === 200 ? { code: 200, status: 'OK', message: { message: 'Berhasil mengubah notifikasi' } } : response.data;
  }

  // if file is selected
  const response = await putData({
    path: `/admin/notification/${notifId}`,
    data: {
      jenisNotif, judul, tanggalMulai, tanggalSelesai, pesan, urlWebview, attachment: !!file,
    },
    withToken: true,
    pathParams: [notifId],
  }).then((responseCreate) => {
    if (responseCreate.error || responseCreate.data.errors) return responseCreate;
    return postData({
      path: `/admin/notif-upload/requestUploadLinkNotif/${notifId}`,
      data: {
        filesize: file.size,
        dataType: file.type.replaceAll('image/', ''),
      },
      withToken: true,
      pathParams: [notifId],
    });
  }).then((responseReqLink) => {
    if (responseReqLink.error || responseReqLink.data.errors) return responseReqLink;
    return uploadGcp({ url: responseReqLink.data.message.data.url, file });
  });
  if (response.error) return { error: response.error };
  return response.status === 200 ? { code: 200, status: 'OK', message: { message: 'Berhasil mengubah notifikasi' } } : response.data;
};

export const deleteNotification = async ({ notifId }) => {
  const response = await deleteData({
    path: `/admin/notification/${notifId}`,
    withToken: true,
    pathParams: [notifId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const getNotifImageLink = async ({ notifId }) => {
  const response = await getData({
    path: `/admin/notif-upload/getNotifLink/${notifId}`,
    withToken: true,
    pathParams: [notifId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Input, SimpleOption, TextArea } from '../../../components/Oripay';
import { status } from '../../../redux/slice';

export function PaymentForm({ formik, isUpdate }) {
  const { category } = useSelector((state) => state.app);
  const [listCategory, setListCategory] = useState([]);

  useEffect(() => {
    setListCategory(category);
  }, [category]);

  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap gap-4">
      <div className={`${isUpdate ? 'hidden' : 'flex'} flex-1 sm:min-w-[250px]`}>
        <Input
          className={`${isUpdate ? 'hidden' : ''}`}
          type="text"
          label="ID Payment"
          styling="form"
          placeholder="Masukkan ID Payment"
          name="paymentId"
          onChange={formik.handleChange}
          value={formik.values.paymentId}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <Input
          type="text"
          label="Nama Payment"
          styling="form"
          placeholder="Masukkan Nama Payment"
          name="paymentName"
          onChange={formik.handleChange}
          value={formik.values.paymentName}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <SimpleOption
          onSelect={formik.handleChange}
          name="paymentCategory"
          value={formik.values.paymentCategory}
          label="Kategori"
          placeholder="Pilih Kategori"
          list={listCategory}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <Input
          type="text"
          label="Pemilik"
          styling="form"
          placeholder="Masukkan Nama Pemilik"
          name="adminName"
          onChange={formik.handleChange}
          value={formik.values.adminName}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <Input
          type="text"
          label="Nomor Akun"
          styling="form"
          placeholder="Masukkan Nomor Akun"
          name="adminPhoneNumber"
          onChange={formik.handleChange}
          value={formik.values.adminPhoneNumber}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <Input
          type="number"
          label="Fee Admin"
          styling="form"
          placeholder="Masukkan Fee Admin"
          name="adminFee"
          onChange={formik.handleChange}
          value={formik.values.adminFee}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <Input
          type="number"
          label="Fee Admin (%)"
          styling="form"
          placeholder="Masukkan Fee Admin (%)"
          name="adminFeePercentage"
          onChange={formik.handleChange}
          value={formik.values.adminFeePercentage}
        />
      </div>
      <div className="flex flex-1 sm:min-w-[250px]">
        <Input
          type="text"
          label="URL Logo"
          styling="form"
          placeholder="Masukkan URL Logo"
          name="paymentImgLink"
          onChange={formik.handleChange}
          value={formik.values.paymentImgLink}
        />
      </div>
      <div className={`${isUpdate ? 'flex flex-row' : 'hidden'} flex-1 sm:min-w-[250px]`}>
        <SimpleOption
          onSelect={formik.handleChange}
          name="availability"
          value={formik.values.availability}
          label="Status"
          placeholder="Pilih Status"
          list={status}
        />
      </div>
      <div className={`${isUpdate && formik.values.availability === 'false' ? 'flex flex-row' : 'hidden'} flex-1 sm:min-w-[250px]`}>
        <TextArea
          type="text"
          label="Alasan Not Available"
          styling="form"
          placeholder="Masukkan Alasan Not Available"
          name="messageAvailability"
          onChange={formik.handleChange}
          value={formik.values.messageAvailability}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdBlock } from 'react-icons/md';
import { BsFillTrashFill } from 'react-icons/bs';
import { startLoading, stopLoading } from '../../redux/slice';
import { getReportList } from '../../services/verify-report-service';
import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';
import { Searchbar } from '../../components/Searchbar';
import { TableContent } from '../../components/TableContent';
import { NoDataView } from '../../components/Oripay';
import Pagination from '../../components/Pagination';
import { ModalAlert } from '../../components/ModalAlert';
import { ModalForm } from '../../components/ModalForm';
import {
  handleAccept, handleReject, showAcceptConfirm, showRejectConfirm,
} from './BlacklistReqHelper';
import { DetailForm } from './form/DetailForm';
import { ModalImg } from '../../components/ModalImg';
import { getCSRF } from '../../services/request';

export function BlacklistReq() {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.app).refreshPage;
  const [search, setSearch] = useState({ searchWord: '' });
  const [pagination, setPagination] = useState({ page: 1, limit: 10, summary: 0 });

  const [reportList, setReportList] = useState([]);
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [acceptData, setAcceptData] = useState({});
  const [rejectData, setRejectData] = useState({});
  const [modalDetail, setModalDetail] = useState({ visibility: false, message: '' });
  const [modalDetailData, setModalDetailData] = useState({});
  const [modalImg, setModalImg] = useState({ visibility: false, src: '' });
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const handleConfirm = async () => {
    if (acceptData.id && acceptData.number) {
      handleAccept(dispatch, acceptData, setModalAlert, getTokenCSRF);
    }
    if (rejectData.id && rejectData.number) {
      handleReject(dispatch, rejectData, setModalAlert, getTokenCSRF);
    }
  };

  useEffect(() => {
    if (acceptData.id && acceptData.number) {
      showAcceptConfirm(setRejectData, setModalAlert, acceptData, getTokenCSRF);
    }
  }, [acceptData]);

  useEffect(() => {
    if (rejectData.id && rejectData.number) {
      showRejectConfirm(setAcceptData, setModalAlert, rejectData, getTokenCSRF);
    }
  }, [rejectData]);

  useEffect(() => {
    const getData = async () => {
      dispatch(startLoading());
      const response = await getReportList({
        searchWord: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      dispatch(stopLoading());
      setReportList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getData();
  }, [pagination.page, refresh]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  return (
    <div className="flex flex-col w-full border-[#ececec] p-8 rounded-2xl shadow-box border-[1px] gap-4">
      <ModalImg
        visibility={modalImg.visibility}
        url={modalImg.src}
        handleVisibility={() => { setModalImg({ visibility: false, src: '' }); }}
        alt=""
      />
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setAcceptData({});
          setRejectData({});
          setModalAlert((prev) => ({ ...prev, visibility: false }));
        }}
        handleConfirm={() => {
          handleConfirm();
        }}
      />
      <ModalForm
        visibility={modalDetail.visibility}
        message={modalDetail.message}
        buttonColor="black"
        handleVisibility={() => {
          setModalDetail({ visibility: false, message: '' });
        }}
        onSubmit={() => {
          setModalDetail({ visibility: false, message: '' });
          setAcceptData({
            id: modalDetailData?.phoneNumberReportId,
            number: modalDetailData?.phoneNumber,
          });
        }}
        cancelButtonOnClick={() => {
          setModalDetail({ visibility: false, message: '' });
          setRejectData({
            id: modalDetailData?.phoneNumberReportId,
            number: modalDetailData?.phoneNumber,
          });
        }}
        className="sm:max-w-[600px] md:max-w-[1000px] flex flex-col"
        buttonText={(
          <div className="flex flex-row gap-2 text-white justify-center items-center">
            <MdBlock />
            Blacklist
          </div>
        )}
        cancelButtonText={(
          <div className="flex flex-row gap-2 text-white justify-center items-center">
            <BsFillTrashFill />
            Delete
          </div>
        )}
        enableCancelButton
        specialType
      >
        <DetailForm
          detail={modalDetailData}
          modalImg={setModalImg}
        />
      </ModalForm>
      <Searchbar
        onChange={setSearch}
        noNominal
      />
      {pagination.summary > 0
        ? (
          <>
            <TableContent
              type="reportList"
              dataList={reportList}
              customFunc={{
                setAcceptData,
                setRejectData,
                setModalDetailVisibility: () => {
                  setModalDetail((prev) => ({ ...prev, visibility: true }));
                },
              }}
              selectedData={setModalDetailData}
              setSortColumn={setSortColumn}
            />
            <Pagination
              pagination={pagination}
              setPagination={(e) => setPagination(e)}
            />
          </>
        )
        : <NoDataView />}
    </div>
  );
}

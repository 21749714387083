import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { Loading } from './components/Oripay';
import { navigationList } from './redux/navigation.slice';
import { routeFormatter } from './utils/formatter-new';
import { generateClientKeyPairThunk, getServerPublicKeyThunk } from './redux/slice';

function App() {
  const dispatch = useDispatch();
  const routeList = routeFormatter(navigationList);

  useEffect(() => {
    dispatch(getServerPublicKeyThunk());
    dispatch(generateClientKeyPairThunk());
  }, []);

  return (
    <Router>
      <Loading />
      <div className="App wrapper bg-white min-h-screen">
        <Routes>
          {routeList.map((item, index) => (
            <Route
              key={index}
              element={item.element}
              path={item.path}
            />
          ))}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

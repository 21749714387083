import { deleteData, postData, putData } from './request';

export const addPemeliharaan = async ({
  pemeliharaanTitle,
  pemeliharaanDesc,
  pemeliharaanWebview,
}) => {
  const response = await postData({
    path: '/maintenance/message',
    data: {
      warningMessage: pemeliharaanTitle,
      warningDescription: pemeliharaanDesc,
      webviewUrl: pemeliharaanWebview,
    },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editPemeliharaan = async ({
  pemeliharaanId,
  pemeliharaanTitle,
  pemeliharaanDesc,
  pemeliharaanWebview,
}) => {
  const response = await putData({
    path: `/maintenance/message/${pemeliharaanId}`,
    data: {
      warningMessage: pemeliharaanTitle,
      warningDescription: pemeliharaanDesc,
      webviewUrl: pemeliharaanWebview,
    },
    withToken: true,
    pathParams: [pemeliharaanId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const changeStatusPemeliharaan = async ({
  pemeliharaanId,
  pemeliharaanStatus,
}) => {
  const response = await putData({
    path: `/maintenance/message/activation/${pemeliharaanId}`,
    data: {
      activeStatus: pemeliharaanStatus,
    },
    withToken: true,
    pathParams: [pemeliharaanId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const deletePemeliharaan = async ({
  pemeliharaanId,
}) => {
  const response = await deleteData({
    path: `/maintenance/message/${pemeliharaanId}`,
    withToken: true,
    pathParams: [pemeliharaanId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import React, { useState } from 'react';
import { Button, BoxWallet, TrxDetail } from './Oripay';
import { formatFilename, formatModalData } from '../utils/formatter';

export function UploadForm({
  trx, isUkt, handleUpload, type, modalImg,
}) {
  const {
    originLogo, originName, destinationLogo, destinationName, detailList,
  } = formatModalData(trx, isUkt);

  const [proofFileLink, setProofFileLink] = useState(null);
  const [proofFileName, setProofFileName] = useState('');

  const uploadProof = (e) => {
    if (e.target.files[0]) {
      const filename = formatFilename(e.target.files[0].name);
      setProofFileName(filename);
      setProofFileLink(URL.createObjectURL(e.target.files[0]));
      handleUpload(e.target.files[0]);
    }
  };

  return (
    <div className="flex gap-8 overflow-x-auto mt-4">
      <div
        onClick={() => { if (proofFileLink) modalImg({ visibility: true, src: proofFileLink }); }}
        className={`${proofFileLink ? 'cursor-pointer' : ''} 
          h-[461px] w-[288px] min-w-[288px] border-[1px] border-[#dedede] bg-[#dedede] rounded-lg flex 
            justify-center items-center`}
      >
        <img
          alt="unp-img"
          src={proofFileLink}
          className={`${proofFileLink ? '' : 'hidden'} h-full`}
        />
        <label
          htmlFor="proof-file"
          className={`text-white font-bold font-dmsans 
            text-6xl cursor-pointer text-center ${proofFileLink ? 'hidden' : 'flex'} flex-col gap-8`}
        >
          <div>Upload</div>
          <div>File</div>
        </label>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col flex-1 gap-8">
          <BoxWallet
            type={type}
            destinationLogo={destinationLogo}
            destinationName={destinationName}
            originLogo={originLogo}
            originName={originName}
            destinationNumber={trx.ke_no_telepon}
            isUkt={isUkt}
          />
          <TrxDetail
            detailList={detailList}
            isUkt={isUkt}
          />
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 p-[8px] h-[58px] bg-white border-[1px] border-[rgba(0, 0, 0, 0.2)]
                rounded-[8px]"
            >
              <input
                id="proof-file"
                onChange={uploadProof}
                type="file"
                className="opacity-0 w-[0.1px] h-[0.1px] absolute"
              />
              <label
                htmlFor="proof-file"
                className="box-border w-[115px] bg-[#D9D9D9] rounded-[8px]
                font-dmsans font-bold text-base text-black py-2 px-7 cursor-pointer"
              >
                Browse
              </label>
              <div className="font-dmsans font-normal text-base text-black bg-white self-center">
                {proofFileName}
              </div>
            </div>
            <Button
              type="submit"
              className="w-full"
            >
              Upload Bukti
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { RequireAuth, RequireNotAuth } from '../components/Auth';
import { Main } from '../components/Main';
import { Content } from '../components/Oripay';

export const routeFormatter = (rawList) => {
  const routeList = [];
  rawList.forEach((item) => {
    if (!item.list) {
      let element = (
        <Content title={item.title}>
          {item.element}
        </Content>
      );
      if (!item.noSidebar) {
        element = (
          <Main>
            {element}
          </Main>
        );
      }
      if (item.noAuth) {
        element = (
          <RequireNotAuth>
            {element}
          </RequireNotAuth>
        );
      } else {
        element = (
          <RequireAuth>
            {element}
          </RequireAuth>
        );
      }
      routeList.push({ element, path: item.path });
    } else {
      item.list.forEach((childItem) => {
        const element = (
          <RequireAuth>
            <Main>
              <Content title={childItem.title}>{childItem.element}</Content>
            </Main>
          </RequireAuth>
        );
        routeList.push({ element, path: childItem.path });
      });
    }
  });
  return routeList;
};

/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { err } from '../utils/constant';
import { store } from '../redux/store';
import { dispatchAdminLogin, dispatchAdminLogout } from '../redux/dispatch';
import { decryptResponse } from './request';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getToken = async () => {
  try {
    const { clientPublicKey } = store.getState().app;
    const response = await axios.get(`${BASE_URL}/auth/admin/token`, {
      headers: {
        'x-public-key': JSON.stringify(clientPublicKey),
      },
    });
    response.data = await decryptResponse(response.data);

    return response;
  } catch (error) {
    if (error.response) {
      error.response.data = await decryptResponse(error.response.data);
      return error.response;
    }
    return err;
  }
};

export const getServerPublicKey = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/jwe/admin/public-key`);
    return response;
  } catch (error) {
    if (error.response) return error.response;
    return err;
  }
};

async function createInstance(withToken, contentType) {
  const { clientPublicKey } = store.getState().app;
  if (withToken) {
    const { accessToken, csrfToken } = store.getState().app;

    return axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-csrf-token': csrfToken,
        'x-public-key': JSON.stringify(clientPublicKey),
        'Content-Type': contentType || 'text/plain',
      },
    });
  }

  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': contentType || 'text/plain',
      'x-public-key': JSON.stringify(clientPublicKey),
    },
  });
}

export async function createAxiosInstance(withToken, contentType) {
  const axiosInstance = await createInstance(withToken, contentType);

  axiosInstance.interceptors.request.use(
    (config) => Promise.resolve(config),
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    async (error) => {
      const errorResponseData = await decryptResponse(error.response.data);

      if (
        errorResponseData.code === 401
          && errorResponseData.errors.message.toLowerCase().includes('access')
      ) {
        const originalRequest = error.config;
        originalRequest._retry = false;
        const response = await getToken();

        if (response.data.code === 200) {
          dispatchAdminLogin(response.data.message.data.accessToken);
          originalRequest.headers.Authorization = `Bearer ${response.data.message.data.accessToken}`;
          return Promise.resolve(axiosInstance(originalRequest));
        }
        dispatchAdminLogout();
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}

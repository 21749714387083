import React from 'react';
import {
  ImageFile, Input, DateInput, SimpleOption,
} from '../../../components/Oripay';

export function BannerForm({
  formik, isUpdate, bannerTypeList, selectedFile, handleFile,
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row flex-1 gap-4">
        <div className={`flex-1 ${!isUpdate ? 'hidden' : ''}`}>
          <Input
            type="text"
            label="ID Banner"
            styling="form"
            placeholder="Masukkan ID Banner"
            name="idBanner"
            onChange={formik.handleChange}
            value={formik.values.idBanner}
            disabled
          />
        </div>
        <div className="flex-1">
          <SimpleOption
            label="Jenis Banner"
            list={bannerTypeList}
            value={formik.values.bannerType}
            placeholder="Pilih Jenis"
            name="bannerType"
            onSelect={formik.handleChange}
          />
        </div>
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="Judul"
          styling="form"
          placeholder="Masukkan Judul"
          name="bannerName"
          onChange={formik.handleChange}
          value={formik.values.bannerName}
        />
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="Pengiklan"
          styling="form"
          placeholder="Masukkan Nama Pengiklan"
          name="adsSupplier"
          onChange={formik.handleChange}
          value={formik.values.adsSupplier}
        />
      </div>
      <div className="flex sm:flex-row flex-col flex-1 gap-4">
        <div className="flex flex-col flex-1 gap-2">
          <span className="text-black text-sm font-dmsans font-bold ">
            Tanggal Mulai :
          </span>
          <DateInput
            placeholder="Masukkan Tanggal Mulai"
            name="startDate"
            onChange={formik.handleChange}
            value={formik.values.startDate}
          />
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <span className="text-black text-sm font-dmsans font-bold ">
            Tanggal Selesai :
          </span>
          <DateInput
            placeholder="Masukkan Tanggal Selesai"
            name="endDate"
            onChange={formik.handleChange}
            value={formik.values.endDate}
          />
        </div>
      </div>
      <div className="flex flex-1">
        <ImageFile
          bannerType={formik.values.bannerType}
          handleFile={handleFile}
          name="bannerImage"
          label="Gambar Banner"
          styling="form"
          selectedFile={selectedFile}
          imageUrl={formik.values.bannerImage}
        />
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="URL Webview"
          styling="form"
          placeholder="Masukkan URL Webview"
          name="webviewUrl"
          onChange={formik.handleChange}
          value={formik.values.webviewUrl}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import {
  FaRegEye,
  FaCalendarAlt,
  FaArrowRight,
  FaSearch,
} from 'react-icons/fa';
import { BsCalendarWeek } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import oripayLogo from '../img/oripay-bg.png';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate2 } from '../utils/formatter';

export function Input({
  onChange,
  className,
  name,
  type,
  placeholder,
  label,
  isError,
  styling,
  value,
  disabled,
}) {
  const [showPassword, setShowPassword] = useState(false);
  let inputType;

  const searchStyle = `h-[53px] pl-4 py-3 my-2 sm:my-0 border-[#eaeaea] sm:border-0 sm:py-0 
    rounded-l-md sm:rounded-l-none rounded-r-md`;
  const defaultStyle = `h-[53px] font-normal text-base leading-[21px] 
    box-border p-4 h-[53px] bg-white rounded-[7px]`;
  const disabledStyle = `h-[53px] font-normal text-base leading-[21px] 
    box-border p-4 h-[53px] bg-black/10 rounded-[7px]`;
  const filterStyle = 'h-[43px] font-normal text-sm p-2 w-0 sm:min-w-[100px] rounded-md border-[#ececec]';

  const toggleShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const getStyle = () => {
    if (styling === 'search') {
      return searchStyle;
    }
    if (styling === 'filter') {
      return filterStyle;
    }
    if (disabled) {
      return disabledStyle;
    }
    return defaultStyle;
  };

  if (type === 'money' || type === 'percentage') {
    inputType = 'number';
  } else {
    inputType = type;
  }

  return (
    <div className={`flex flex-1 justify-end ${className}`}>
      <FaRegEye
        className={`
                ${inputType !== 'password' ? 'hidden' : ''} 
                w-4 h-4 text-black absolute mt-[49px] mr-[20px] cursor-pointer
            `}
        onClick={() => toggleShowPassword()}
      />
      <div className="flex flex-col gap-2 w-full">
        <span
          className={`
                    text-black ${
                      styling === 'form' ? 'text-sm' : 'text-md'
                    } font-dmsans 
                    font-bold ${label ? '' : 'hidden'}
                `}
        >
          {label}
        </span>
        <div className="flex w-full">
          <div className={`${type === 'money' ? 'flex' : 'hidden'} 
            justify-center items-center rounded-l-md border-[1px] px-3
          `}
          >
            Rp
          </div>
          <div className="flex-1">
            <input
              placeholder={placeholder}
              value={value}
              type={inputType === 'password' && showPassword ? 'text' : inputType}
              name={name}
              className={`font-dmsans w-full text-black border-[1px]
                ${isError ? 'border-[#DC3545]' : ''} ${getStyle()}
                ${type === 'money' ? 'border-l-0 rounded-l-none' : ''}
                ${type === 'percentage' ? 'border-r-0 rounded-r-none' : ''}
              `}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
          <div className={`${type === 'percentage' ? 'flex' : 'hidden'}
            justify-center items-center rounded-r-md border-[1px] px-3
          `}
          >
            %
          </div>
        </div>
      </div>
    </div>
  );
}

export function RadioInput({
  list, label, name, onChange, value,
}) {
  const [selectedData, setSelectedData] = useState(value);

  const handleChange = (e) => {
    setSelectedData(e.target.value);
    onChange(e);
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <span
        className={`
          text-black text-sm font-dmsans font-bold ${label ? '' : 'hidden'}
        `}
      >
        {label}
      </span>
      <div className="w-full flex flex-wrap gap-4">
        {list.map((item, index) => (
          <div
            key={index}
            className="flex gap-2"
          >
            <input
              type="radio"
              name={name}
              checked={selectedData === item.value}
              value={item.value}
              onChange={handleChange}
            />
            <span className="font-dmsans">{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function DateInput({
  label, name, onChange, value, placeholder,
}) {
  return (
    <div className="flex flex-col gap-2 font-dmsans w-full">
      <div className={`${label ? '' : 'hidden'}`}>{label}</div>
      <div className="w-full flex items-center border-[1px] rounded-md justify-between">
        <DatePicker
          selected={value ? new Date(value) : ''}
          name={name}
          onChange={(date) => {
            onChange({
              target: {
                value: formatDate2(date), name,
              },
            });
          }}
          className={`w-full cursor-pointer rounded-md
            focus:outline-none pl-4 h-[51px] bg-transparent
          `}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          popperPlacement="bottom-end"
          autoComplete="off"
        />
        <div className="text-gray-400 px-4">
          <BsCalendarWeek size={18} />
        </div>
      </div>
    </div>
  );
}

export function Button({
  children,
  onClick,
  className,
  color,
  type,
  styling,
  noNominal,
  autoFill,
  failed,
}) {
  const filterStyle = `min-w-[100px] w-full ${noNominal ? 'rf:w-auto' : ''} 
    ${
  autoFill ? '' : 'md:w-auto'
} text-white p-3 rounded-md font-dmsans font-bold text-sm`;
  const dataStyle = `p-2 font-dmsans rounded-[7px] cursor-pointer 
    ${failed ? 'min-w-[100px]' : 'min-w-[25px]'}`;
  const defaultStyle = `font-dmsans font-bold text-base leading-[21px] p-4 w-full 
    rounded-[7px] cursor-pointer ${
  color === 'danger-outline'
    ? 'border-[1px] text-[#FF2F2F] border-[#FF3333]'
    : 'border-0 text-white'
}`;

  const getStyle = () => {
    if (styling === 'filter') {
      return filterStyle;
    }
    if (styling === 'data') {
      return dataStyle;
    }
    return defaultStyle;
  };

  const getColor = () => {
    switch (color) {
      case 'danger':
        return 'bg-[#FF3333]';
      case 'info':
        return 'bg-[#4A86E8]';
      case 'danger-outline':
        return 'bg-white';
      case 'light-blue':
        return 'bg-[#4A86E8]';
      case 'orange':
        return 'bg-[#F28C20]';
      case 'black':
        return 'bg-black';
      default:
        return 'bg-blue';
    }
  };

  return (
    <div className={className}>
      <button
        className={`${getStyle()} active:scale-y-110 transition
                    ${getColor()} `}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

export function Loading() {
  const { loading } = useSelector((state) => state.app);

  return (
    <div
      className={`${
        !loading ? 'hidden' : ''
      } z-[1000] flex items-center justify-center fixed inset-0 
    bg-[rgba(0,0,0,0.3)]`}
    >
      <div className="w-40 h-40 border-t-4 border-b-4 border-blue rounded-full animate-spin" />
    </div>
  );
}

export function Message({ children, className }) {
  return (
    <div className={`${className}`}>
      <span className="font-dmsans font-normal text-sm text-[#DC3545] leading-4">
        {children}
      </span>
    </div>
  );
}

export function OripayImg({ className }) {
  return (
    <img
      alt="oripay-img"
      className={`${className}`}
      src={oripayLogo}
    />
  );
}

export function Dropdown({
  children, className, childClass, list,
}) {
  const [showList, setShowList] = useState(false);

  const toggleShowList = () => {
    if (showList) {
      setShowList(false);
    } else {
      setShowList(true);
    }
  };

  return (
    <div>
      <div
        onClick={() => toggleShowList()}
        className={`${className}`}
      >
        {children}
      </div>
      <div
        className={`${!showList ? 'hidden' : ''} ${childClass || ''}
                fixed mt-[1px] rounded-lg text-black border-[1px] z-[10]`}
      >
        {list.map((item, index) => (
          <div
            onClick={item.action}
            className="p-2 hover:bg-[#e7e7e7] cursor-pointer rounded-sm"
            key={index}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export function Content({ className, children, title }) {
  document.title = title;
  return (
    <div
      className={`${
        className || ''
      } flex-col overflow-y-auto flex-1 px-4 sm:px-6`}
    >
      {children}
    </div>
  );
}

export function InputDate({
  className, onChange, name, value,
}) {
  const [date, setDate] = useState(false);

  return (
    <div
      className={`${className} text-black flex flex-1 p-2 gap-2 border-[1px] border-[#dedede] rounded-md`}
    >
      <div className="text-orange">
        <FaCalendarAlt className="w-6 h-6" />
      </div>
      <input
        className="w-0 min-w-30 flex-1 sm:min-w-[84px] cursor-pointer font-dmsans font-normal text-sm"
        type={date ? 'date' : 'text'}
        onChange={onChange}
        name={name}
        placeholder="Choose Date"
        value={value}
        onBlur={() => {
          setDate(false);
        }}
        onFocus={() => {
          setDate(true);
        }}
      />
    </div>
  );
}

export function InputDate2({
  className, onChange, name, value,
}) {
  const [date, setDate] = useState(false);

  return (
    <div
      className={`${className} text-black flex flex-1 p-2 gap-2 border-[1px] border-[#dedede] rounded-md`}
    >
      <div className="text-orange">
        <FaCalendarAlt className="w-6 h-6" />
      </div>
      <input
        className="w-0 min-w-30 flex-1 sm:min-w-[84px] cursor-pointer font-dmsans font-normal text-sm"
        type={date ? 'date' : 'text'}
        onChange={onChange}
        name={name}
        placeholder="Choose Date"
        value={date ? value : new Date(value).toLocaleString('id-ID', { year: 'numeric', month: 'short', day: 'numeric' })}
        onBlur={() => {
          setDate(false);
        }}
        onFocus={() => {
          setDate(true);
        }}
      />
    </div>
  );
}

export function SimpleOption({
  list,
  onSelect,
  name,
  className,
  value,
  placeholder,
  styling,
  label,
}) {
  const filterStyle = 'w-0 min-w-30 sm:min-w-[130px] flex-1 font-dmsans font-normal text-sm px-2 rounded-md';
  const defaultStyle = 'h-[53px] font-normal text-base leading-[21px] box-border px-4 h-[53px] bg-white rounded-[7px]';

  return (
    <div
      className={`flex flex-col gap-2 w-full ${
        styling === 'filter' ? 'h-[43px]' : ''
      }`}
    >
      <span
        className={`
                text-black text-sm font-dmsans font-bold ${
                  label ? '' : 'hidden'
                }
            `}
      >
        {label}
      </span>
      <div
        className={`flex w-full border-[1px] 
      ${className} ${styling === 'filter' ? filterStyle : defaultStyle} 
      cursor-pointer border-[#dedede] bg-white`}
      >
        <select
          value={value}
          name={name}
          onChange={onSelect}
          className={` w-full
                ${
                  value ? 'text-black' : 'text-[#949494]'
                } cursor-pointer bg-white
                `}
        >
          <option
            value=""
            className="text-gray-400"
          >
            {placeholder}
          </option>
          {list.map((item) => (
            <option
              key={item.value}
              value={item.value}
              className="text-black"
            >
              {item.name || item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export function BoxContent({ className, children, padding }) {
  return (
    <div
      className={`${className}
            flex flex-col w-full border-[#ececec] ${
              padding || 'p-6'
            } rounded-2xl shadow-box border-[1px]
        `}
    >
      {children}
    </div>
  );
}

export function BoxWallet({
  originName,
  originLogo,
  destinationName,
  destinationLogo,
  isUkt,
  destinationNumber,
  type,
}) {
  return (
    <div className="flex flex-row gap-4 w-full">
      <div
        className={`${
          type === 'refund' ? 'hidden' : 'flex'
        } flex-row min-w-[185px] 
      border-[#ececec] px-4 pt-4 pb-2 rounded-[8px] gap-2 shadow-card border-[1px]`}
      >
        <div
          className={`${
            isUkt ? 'hidden' : 'flex'
          } w-full justify-evenly flex-row h-full 
        gap-2 items-center overflow-x-auto pb-2`}
        >
          <img
            alt="origin-logo"
            className="h-[16px]"
            src={originLogo}
          />
          <div className="text-center font-dmsans font-normal text-base">
            {originName}
          </div>
        </div>
        <div
          className={`${
            isUkt ? 'flex' : 'hidden'
          } flex-col flex-1 gap-2 overflow-x-auto pb-2`}
        >
          <div className="flex flex-row gap-2 justify-center">
            <img
              alt="destination-logo"
              className="h-[16px] self-center"
              src={originLogo}
            />
          </div>
          <hr />
          <div className="text-center font-dmsans font-normal text-base">
            {originName}
          </div>
        </div>
      </div>
      <div
        className={`${
          type === 'refund' ? 'hidden' : ''
        } self-center max-w-[21px]`}
      >
        <FaArrowRight className="w-[21px] h-[21px]" />
      </div>
      <div
        className={`flex flex-col ${
          type === 'refund'
            ? 'min-w-[423px] max-w-[423px]'
            : 'min-w-[185px] max-w-[185px]'
        } border-[#ececec] px-4 pt-4 pb-2 
      rounded-[8px] gap-2 shadow-card border-[1px]`}
      >
        <div className="flex flex-col flex-1 gap-2 overflow-x-auto pb-2">
          <div className="flex flex-row gap-2 justify-evenly">
            <img
              alt="destination-logo"
              className="h-[16px] self-center"
              src={destinationLogo}
            />
            <div
              className={`text-center font-dmsans font-normal text-base 
            ${isUkt ? 'hidden' : ''}`}
            >
              {destinationName}
            </div>
          </div>
          <hr />
          <div className="text-center font-dmsans font-normal text-base">
            {isUkt ? destinationName : destinationNumber}
          </div>
        </div>
      </div>
    </div>
  );
}

export function TrxDetail({ detailList, isUkt }) {
  return (
    <div className="flex flex-row flex-wrap gap-4 gap-x-14">
      {detailList.map((item, index) => (
        <div
          key={index}
          className={`${!isUkt && item.isUkt ? 'hidden' : 'flex'} 
          flex-col flex-1 min-w-[150px]`}
        >
          <div className="font-dmsans font-bold text-base">{item.title}</div>
          <div className="font-dmsans font-normal text-base break-words">{item.body}</div>
        </div>
      ))}
    </div>
  );
}

export function NoDataView() {
  return (
    <div
      className="w-full flex flex-col justify-center text-center gap-8
      border-[1px] border-[rgba(0,0,0,0.2)] p-8 rounded-xl"
    >
      <FaSearch className="w-[50px] h-[50px] text-gray-400 self-center" />
      <div className="font-dmsans text-gray-700 text-2xl">
        No Data Available
      </div>
    </div>
  );
}

export function TextArea({
  onChange,
  className,
  name,
  placeholder,
  label,
  isError,
  styling,
  value,
}) {
  const defaultStyle = `h-[53px] font-normal text-base leading-[21px] 
    box-border p-4 h-[53px] bg-white rounded-[7px]`;

  return (
    <div className={`flex flex-1 justify-end ${className}`}>
      <div className="flex flex-col gap-2 w-full">
        <span
          className={`
                    text-black ${
                      styling === 'form' ? 'text-sm' : 'text-md'
                    } font-dmsans 
                    font-bold ${label ? '' : 'hidden'}
                `}
        >
          {label}
        </span>
        <textarea
          placeholder={placeholder}
          value={value || ''}
          name={name}
          className={`font-dmsans w-full border-[1px] text-black 
            ${isError ? 'border-[#DC3545]' : ''} ${defaultStyle}`}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export function ImageFile({
  bannerType, className, styling, label, name, handleFile, selectedFile, imageUrl,
  noWarning = false,
}) {
  const [preview, setPreview] = useState();
  const [resolutionText, setResolutionText] = useState();
  const [resolutionWarning, setResolutionWarning] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    handleFile(fileUploaded);
  };

  useEffect(() => {
    if (!selectedFile && !imageUrl) {
      setPreview(undefined);
      return;
    }
    const objectUrl = selectedFile ? URL.createObjectURL(selectedFile) : imageUrl;
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (bannerType === 'carousel' && (height / width) <= (1 / 3) && (height / width) >= (1 / 5)) {
        setResolutionWarning(false);
      } else if (bannerType === 'popup' && (height / width) >= 1) {
        setResolutionWarning(false);
      } else {
        setResolutionWarning(true);
      }
    };
    setPreview(objectUrl);
  }, [selectedFile, bannerType]);

  useEffect(() => {
    switch (bannerType) {
      case 'carousel':
        setResolutionText('Pastikan gunakan gambar dengan skala (height:width) = 1:3 - 1:5');
        break;
      case 'popup':
        setResolutionText('Pastikan gunakan gambar dengan skala (height:width) > 1:1');
        break;
      default:
        setResolutionText('');
        break;
    }
  }, [bannerType]);

  return (
    <div className={`flex flex-1 justify-end ${className}`}>
      <div className="flex flex-col gap-2 w-full">
        <span
          className={`
                    text-black ${
                      styling === 'form' ? 'text-sm' : 'text-md'
                    } font-dmsans 
                    font-bold ${label ? '' : 'hidden'}
                `}
        >
          {label}
        </span>

        {preview
          ? (
            <div className="relative">
              <div className="absolute z-10 right-0 p-2">
                <MdClose
                  className="text-white opacity-50 text-2xl cursor-pointer hover:opacity-100 bg-black"
                  onClick={() => {
                    handleFile('');
                  }}
                />
              </div>
              <div
                className="flex flex-1 cursor-pointer justify-center border border-[#eaeaea] rounded-md h-32 overflow-hidden"
                onClick={handleClick}
              >
                <img
                  src={preview}
                  className={`w-full h-32 ${bannerType === 'carousel' ? 'object-fill' : 'object-contain'}`}
                  alt=""
                />
              </div>
              {resolutionWarning && !noWarning
                ? (
                  <div className="text-red-600">
                    Skala gambar tidak sesuai, jika gambar digunakan akan di strecth
                  </div>
                )
                : '' }
            </div>
          )
          : (
            <>
              <div
                className="flex cursor-pointer justify-center border border-[#eaeaea] rounded-md py-8"
                onClick={handleClick}
              >
                Upload a file
              </div>
              <div className="text-gray-600">
                {resolutionText}
              </div>
            </>
          ) }

        <input
          type="file"
          name={name}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept=".png,.jpeg,.jpg, image/png, image/jpeg, image/jpg"
        />
      </div>
    </div>
  );
}

export function ImageFiles({
  className, styling, label, name, handleFile, selectedFiles,
}) {
  const [previews, setPreviews] = useState([]);
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files;
    handleFile([...fileUploaded]);
  };

  useEffect(() => {
    if (!selectedFiles) {
      setPreviews(undefined);
      return;
    }
    const previewsArr = selectedFiles.map((item) => {
      const objectUrl = URL.createObjectURL(item);
      return objectUrl;
    });
    setPreviews(previewsArr);
  }, [selectedFiles]);

  return (
    <div className={`flex flex-1 justify-end ${className}`}>
      <div className="flex flex-col gap-2 w-full">
        <span
          className={`
                    text-black ${
                      styling === 'form' ? 'text-sm' : 'text-md'
                    } font-dmsans 
                    font-bold ${label ? '' : 'hidden'}
                `}
        >
          {label}
        </span>
        <div
          className="flex cursor-pointer justify-center border border-[#eaeaea] rounded-md py-8 "
          onClick={handleClick}
        >
          {previews ? 'Change Upload Files' : 'Upload files' }
        </div>
        <div className="flex flex-row">
          {previews.length > 0
            ? previews.map((item, index) => (
              <div
                className="relative"
                key={index}
              >
                <div className="absolute z-10 right-0 p-2">
                  <MdClose
                    className="text-white opacity-50 text-2xl cursor-pointer hover:opacity-100 bg-black rounded-sm"
                    onClick={() => {
                      let newArr = [];
                      newArr = selectedFiles;
                      newArr.splice(index, 1);
                      handleFile([...newArr]);
                    }}
                  />
                </div>
                <div
                  className="flex flex-1 cursor-pointer justify-center border border-[#eaeaea] rounded-md h-32 overflow-hidden"
                  onClick={handleClick}
                >
                  <img
                    src={item}
                    className="w-full h-32 object-fill"
                    alt=""
                  />
                </div>
              </div>
            ))
            : ''}
        </div>
        <input
          type="file"
          name={name}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept=".png,.jpeg,.jpg, image/png, image/jpeg, image/jpg"
          multiple
        />
      </div>
    </div>
  );
}

export function Toggle({
  label, name, onChange, value,
}) {
  return (
    <label className="w-full flex items-center justify-between">
      <div className={`text-black text-sm font-dmsans font-bold ${label ? '' : 'hidden'}`}>
        {label}
      </div>
      <div className="relative cursor-pointer">
        <input
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          className="sr-only"
          checked={value}
        />
        <div className="block bg-white w-14 h-8 rounded-full border" />
        <div className="dot absolute left-1 top-1 bg-gray-300 w-6 h-6 rounded-full transition" />
      </div>
    </label>
  );
}

import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Transition } from '@headlessui/react';
import { OripayImg } from './Oripay';
import { navigationList } from '../redux/navigation.slice';

export function Sidebar({ className, setSidebarToggle, sidebarToggle }) {
  const navigate = useNavigate();
  const localtion = useLocation();

  return (
    <Transition
      show={sidebarToggle}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
      className={`${className} z-[10] flex flex-col sm:w-[325px] bg-blue py-6 pl-6 pr-3`}
    >
      <div className="flex sm:hidden text-white justify-end">
        <span
          className="cursor-pointer font-dmsans font-bold text-lg leading-5"
          onClick={() => { setSidebarToggle(false); }}
        >
          <FaTimes />
        </span>
      </div>
      <div className="flex py-10">
        <OripayImg className="w-[195px] h-[60px]" />
      </div>
      <div className="flex flex-col flex-1 h-0 overflow-y-auto pr-3 gap-4">
        {navigationList.map((item, index) => {
          if (!item.noSidebar && !item.hide) {
            if (!item.list) {
              return (
                <div
                  key={index}
                  onClick={() => { navigate(item.path); }}
                  className={`transition active:scale-105 duration-150 rounded-md flex cursor-pointer 
                              ${item.path === localtion.pathname ? 'bg-white' : ''} p-4`}
                >
                  <div className={`self-center transition duration-150 
                  ${item.path === localtion.pathname ? 'text-orange' : 'text-white'}`}
                  >
                    {item.icon}
                  </div>
                  <div className={`pl-4 font-dmsans font-bold text-base leading-5 
                  ${item.path === localtion.pathname ? 'text-blue' : 'text-white'}`}
                  >
                    {item.name}
                  </div>
                </div>
              );
            }

            return (
              <SidebarOption
                key={index}
                icon={item.icon}
                text={item.name}
                list={item.list}
              />
            );
          }
          return null;
        })}
      </div>
    </Transition>
  );
}

function SidebarOption({
  list, text, className, icon,
}) {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const check = list.find((item) => item.path === location.pathname);
    if (check) {
      setSelected(true);
      setShowList(true);
    } else {
      setSelected(false);
    }
  }, [location, list]);

  const toggleShowList = () => {
    if (showList) {
      setShowList(false);
    } else {
      setShowList(true);
    }
  };

  return (
    <div className={`${className} flex flex-col gap-2`}>
      <div
        onClick={() => toggleShowList()}
        className={`flex cursor-pointer p-4 rounded-md 
        ${selected ? 'bg-white' : 'bg-blue'} transition active:scale-105 duration-150`}
      >
        <div className={`self-center ${selected ? 'text-orange' : 'text-white'}`}>{icon}</div>
        <div className={`pl-4 font-dmsans font-bold text-base leading-5 flex-1 
          ${selected ? 'text-blue' : 'text-white'}`}
        >
          {text}
        </div>
        <div className={`self-center sm:ml-0 ml-2 ${selected ? 'text-blue' : 'text-white'}`}>
          {
            showList ? (
              <FiChevronUp className="w-4 h-4" />
            ) : (
              <FiChevronDown className="w-4 h-4" />
            )
          }
        </div>
      </div>
      <Transition
        show={showList}
        className="flex flex-col"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-y-6"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-150 transform"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-6"
      >
        {list.map((item, index) => (
          <div
            onClick={() => { navigate(item.path); }}
            key={index}
            className="cursor-pointer"
          >
            <div className={`transition-all active:scale-105 duration-150 p-4 ml-10 
            rounded-md text-white font-dmsans font-bold text-base leading-5 
            ${item.path === location.pathname ? 'border-[1px]' : ''}`}
            >
              {item.name}
            </div>
          </div>
        ))}
      </Transition>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { readRegisteredUser } from '../../services/registered-user-service';
import { Searchbar } from '../../components/Searchbar';
import { TableContent } from '../../components/TableContent';
import Pagination from '../../components/Pagination';
import { NoDataView } from '../../components/Oripay';
import { refreshPage } from '../../redux/dispatch';
import { startLoading, stopLoading } from '../../redux/slice';

export function RegisteredUser() {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.app).refreshPage;
  const [registeredUserList, setRegisteredUserList] = useState([]);
  const [search, setSearch] = useState({ searchWord: '' });
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });
  const [pagination, setPagination] = useState({ page: 1, limit: 10, summary: 0 });

  useEffect(() => {
    const getRegisteredUser = async () => {
      dispatch(startLoading());
      const response = await readRegisteredUser({
        limit: pagination.limit,
        page: pagination.page,
        q: search.searchWord,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      dispatch(stopLoading());
      setRegisteredUserList(
        response?.message?.list.map((item, index) => ({
          ...item, no: ((pagination.page - 1) * pagination.limit + index + 1).toString(),
        })) || [],
      );
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getRegisteredUser();
  }, [pagination.page, refresh]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  return (
    <div className="flex flex-col w-full border-[#ececec] p-8 rounded-2xl shadow-box border-[1px] gap-4">
      <Searchbar
        onChange={setSearch}
        noNominal
      />
      {pagination.summary > 0
        ? (
          <>
            <TableContent
              type="registeredUserList"
              dataList={registeredUserList}
              setSortColumn={setSortColumn}
            />
            <Pagination
              pagination={pagination}
              setPagination={(e) => setPagination(e)}
            />
          </>
        )
        : <NoDataView />}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import { ModalForm } from '../../components/ModalForm';
import { CategoryForm } from './form/CategoryForm';
import { TableContent } from '../../components/TableContent';
import { ModalAlert } from '../../components/ModalAlert';
import {
  addCategory, deleteCategory, editCategory, readCategory,
} from '../../services/category-service';
import { refreshPage, getCategory, dispatchSetCSRF } from '../../redux/dispatch';
import { startLoading, stopLoading } from '../../redux/slice';
import { getCSRF } from '../../services/request';

export function Category() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({ searchWord: '' });
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [deleteData, setDeleteData] = useState('');
  const refresh = useSelector((state) => state.app).refreshPage;
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const [categoryList, setCategoryList] = useState([]);

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { categoryId: '', categoryName: '', categoryStatus: '' },
    onSubmit: async (values) => {
      const categoryStatus = values.categoryStatus === '' ? '' : values.categoryStatus === 'true';
      const dataValue = { ...values, categoryStatus };

      dispatch(startLoading());
      let response;
      if (modalUpdate) response = await editCategory(dataValue);
      else if (deleteData) response = await deleteCategory(dataValue);
      else response = await addCategory(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        getCategory();
        setModalFormVisibility(false);
        setModalUpdate(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setDeleteData('');
        setResponseMessage('');
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdate) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdate]);

  useEffect(() => {
    const getCategoryData = async () => {
      dispatch(startLoading());
      const response = await readCategory({
        word: search.searchWord,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      setCategoryList(response?.message?.list || []);
      dispatch(stopLoading());
    };
    getCategoryData();
  }, [search, refresh, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    if (selectedData) {
      formik.setFieldValue('categoryId', selectedData.id);
      formik.setFieldValue('categoryName', selectedData.data.nama_category);
      formik.setFieldValue('categoryStatus', selectedData.data.status === 'Active' ? 'true' : 'false');
    }
  }, [selectedData]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('categoryId', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Are You Sure?' });
    }
  }, [deleteData]);

  return (
    <div className="h-full">
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setModalAlert((data) => ({ ...data, visibility: false }));
          setDeleteData('');
          formik.resetForm();
        }}
        handleConfirm={formik.handleSubmit}
      />
      <ModalForm
        visibility={modalFormVisibility || modalUpdate}
        handleVisibility={() => {
          setModalFormVisibility(false); setModalUpdate(false); formik.resetForm();
          setResponseMessage('');
        }}
        buttonText={modalUpdate ? 'Edit Tipe Transaksi' : 'Buat Tipe Transaksi'}
        onSubmit={formik.handleSubmit}
        message={responseMessage}
        className="sm:max-w-[500px]"
      >
        <CategoryForm
          formik={formik}
          isUpdate={modalUpdate}
        />
      </ModalForm>
      <BoxContent>
        <Searchbar
          className="mb-6"
          onChange={setSearch}
          withButton
          buttonName="Buat Tipe"
          onClick={() => { setModalFormVisibility(true); }}
        />
        {
          categoryList.length > 0
            ? (
              <TableContent
                type="category"
                dataList={categoryList}
                modalUpdate={setModalUpdate}
                selectedData={setSelectedData}
                deleteData={setDeleteData}
                setSortColumn={setSortColumn}
              />
            )
            : <NoDataView />
        }
      </BoxContent>
    </div>
  );
}

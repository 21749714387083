import { getData } from './request';

export const getListTransaksi = async ({
  type, fromDate, toDate, fromWallet, toWallet,
  fromNominal, toNominal, searchWord, limit, page, order,
}) => {
  const response = await getData({
    path:
      `/admin/transaksi/${type}`
        + `?fromDate=${fromDate}`
        + `&toDate=${toDate}`
        + `&fromMethod=${fromWallet}`
        + `&toMethod=${toWallet}`
        + `&fromNominal=${fromNominal}`
        + `&toNominal=${toNominal}`
        + `&searchWord=${searchWord}`
        + `&limit=${limit}`
        + `&page=${page}`
        + `&order=${order}`,
    withToken: true,
    pathParams: [type],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const getProofAdmin = async ({ idTrx }) => {
  const response = await getData({
    path: `/admin/bukti-transaksi/${idTrx}`,
    withToken: true,
    pathParams: [idTrx],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const getProofUser = async ({ idTrx }) => {
  const response = await getData({
    path: `/user/bukti-transaksi/${idTrx}`,
    withToken: true,
    pathParams: [idTrx],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

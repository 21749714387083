import { store } from './store';
import {
  getWalletData,
  startLoading,
  stopLoading,
  setRefreshPage,
  getCategoryData,
  adminLogin,
  adminLogout,
  setCsrfToken,
  unsetCsrfToken,
} from './slice';

export const loading = (state) => {
  if (state) store.dispatch(startLoading());
  else store.dispatch(stopLoading());
};

export const getWallet = () => {
  store.dispatch(getWalletData());
};

export const getCategory = () => {
  store.dispatch(getCategoryData());
};

export const dispatchAdminLogin = (accessToken) => {
  store.dispatch(adminLogin(accessToken));
};

export const dispatchAdminLogout = () => {
  store.dispatch(adminLogout());
};

export const dispatchSetCSRF = (csrfToken) => {
  store.dispatch(setCsrfToken(csrfToken));
};

export const dispatchUnsetCSRF = () => {
  store.dispatch(unsetCsrfToken());
};

export const refreshPage = () => {
  const refresh = store.getState().app.refreshPage;
  if (refresh) store.dispatch(setRefreshPage(false));
  else store.dispatch(setRefreshPage(true));
};

export const getBundleData = () => {
  getWallet();
  getCategory();
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewFilter } from '../../components/Filter';
import { BoxContent, NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import { FailedModal } from './form/FailedModal';
import { ModalForm } from '../../components/ModalForm';
import { TableContent } from '../../components/TableContent';
import Pagination from '../../components/Pagination';
import { startLoading, stopLoading } from '../../redux/slice';
import { getListTransaksi } from '../../services/transaction-service';
import { ModalImg } from '../../components/ModalImg';

export function FailedTopUp() {
  const dispatch = useDispatch();
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalImg, setModalImg] = useState({ visibility: false, src: '' });
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const [filter, setFilter] = useState({
    fromDate: '', toDate: '', fromWallet: '', toWallet: '', fromNominal: '', toNominal: '',
  });
  const [search, setSearch] = useState({ searchWord: '' });
  const [pagination, setPagination] = useState({
    page: 1, limit: 10, summary: 0,
  });
  const refresh = useSelector((state) => state.app).refreshPage;

  const [failedList, setFailedList] = useState([]);

  useEffect(() => {
    const getFailedData = async () => {
      dispatch(startLoading());
      const response = await getListTransaksi({
        type: 'failed',
        fromDate: filter.fromDate || '',
        toDate: filter.toDate || '',
        fromWallet: filter.fromWallet || '',
        toWallet: filter.toWallet || '',
        fromNominal: filter.fromNominal || '',
        toNominal: filter.toNominal || '',
        searchWord: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      dispatch(stopLoading());
      setFailedList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getFailedData();
  }, [search, filter, pagination.limit, pagination.page, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    setFilter({
      fromDate: '', toDate: '', fromWallet: '', toWallet: '', fromNominal: '', toNominal: '',
    });
    setSearch({
      sort: 'date_desc', searchWord: '',
    });
    setPagination({
      page: 1, limit: 10, summary: 0,
    });
  }, [refresh]);

  return (
    <div className="h-full">
      <BoxContent className="mt-6">
        <Searchbar
          className="mb-6"
          onChange={setSearch}
        />
        <NewFilter onChange={setFilter} />
        <ModalImg
          visibility={modalImg.visibility}
          url={modalImg.src}
          handleVisibility={() => { setModalImg({ visibility: false, src: '' }); }}
          alt=""
        />
        <ModalForm
          buttonText="Submit"
          visibility={modalFormVisibility}
          handleVisibility={() => {
            setModalFormVisibility(false);
          }}
          className="sm:w-[840px]"
          noButton
          disableMaxWidth
        >
          <FailedModal
            modalImg={setModalImg}
            trx={modalData}
          />
        </ModalForm>
        {pagination.summary > 0
          ? (
            <div className="flex flex-col">
              <TableContent
                type="failed"
                selectedData={setModalData}
                dataList={failedList}
                modalUpdate={setModalFormVisibility}
                setSortColumn={setSortColumn}
              />
              <Pagination
                pagination={pagination}
                setPagination={(e) => setPagination(e)}
              />
            </div>
          )
          : <NoDataView />}
      </BoxContent>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import { ModalForm } from '../../components/ModalForm';
import { PaymentForm } from './form/PaymentForm';
import { TableContent } from '../../components/TableContent';
import {
  addPayment, editPayment, deletePayment, readPayment,
} from '../../services/payment-service';
import { ModalAlert } from '../../components/ModalAlert';
import { refreshPage, getCategory, dispatchSetCSRF } from '../../redux/dispatch';
import { startLoading, stopLoading } from '../../redux/slice';
import { getCSRF } from '../../services/request';

export function PaymentMethod() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({ searchWord: '' });
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [deleteData, setDeleteData] = useState('');
  const categoryList = useSelector((state) => state.app).category;
  const refresh = useSelector((state) => state.app).refreshPage;
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const [payMethodList, setPayMethodList] = useState([]);
  const [dataQR, setDataQR] = useState();
  const [changeQR, setChangeQR] = useState(false);

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentId: '',
      paymentCategory: '',
      paymentName: '',
      paymentImgLink: '',
      adminName: '',
      adminPhoneNumber: '',
      adminFee: '',
      adminFeePercentage: '',
      availability: '',
      messageAvailability: '',
    },
    onSubmit: async (values) => {
      const availability = values.availability === '' ? '' : values.availability === 'true';
      const dataValue = { ...values, availability };

      dispatch(startLoading());
      let response;
      if (modalUpdate || changeQR) response = await editPayment(dataValue);
      else if (deleteData) response = await deletePayment(dataValue);
      else response = await addPayment(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        getCategory();
        setModalFormVisibility(false);
        setModalUpdate(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setDeleteData('');
        setResponseMessage('');
        formik.resetForm();
      }
    },
  });

  const formik2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentId: '',
      paymentCategory: '',
      paymentName: '',
      paymentImgLink: '',
      adminName: '',
      adminPhoneNumber: '',
      adminFee: '',
      adminFeePercentage: '',
      availability: '',
      messageAvailability: '',
    },
    onSubmit: async (values) => {
      const availability = values.availability === '' ? '' : values.availability === 'true';
      const dataValue = {
        ...values,
        availability,
        adminFee: parseFloat(values.adminFee) || 0,
        adminFeePercentage: parseFloat(values.adminFeePercentage) || 0,
      };

      dispatch(startLoading());
      const response = await editPayment(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        getCategory();
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        formik2.resetForm();
      }
    },
  });

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdate || changeQR) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdate, changeQR]);

  useEffect(() => {
    if (dataQR) {
      formik2.setFieldValue('paymentId', dataQR.id_payment);
      formik2.setFieldValue('paymentCategory', categoryList.find(
        (data) => data.name === dataQR.kategori_payment,
      ).value);
      formik2.setFieldValue('paymentName', dataQR.nama_payment);
      formik2.setFieldValue('paymentImgLink', dataQR.url_logo);
      formik2.setFieldValue('adminName', dataQR.nama_admin);
      formik2.setFieldValue('adminPhoneNumber', dataQR.nomor_admin);
      formik2.setFieldValue('adminFee', dataQR.fee_admin);
      formik2.setFieldValue('adminFeePercentage', dataQR.fee_percentage_admin);
      formik2.setFieldValue('availability', dataQR.status_payment ? 'true' : 'false');
      formik.setFieldValue('messageAvailability', selectedData.message_availability);
    }
  }, [dataQR]);

  useEffect(() => {
    const getPayMethodData = async () => {
      dispatch(startLoading());
      const response = await readPayment({
        word: search.searchWord,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      dispatch(stopLoading());

      setPayMethodList(response?.message?.list || []);
      const QR = response.message.list.find((payment) => payment.id_payment === 'QR');
      setDataQR(QR);
    };
    getPayMethodData();
  }, [search, refresh, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    if (selectedData) {
      formik.setFieldValue('paymentId', selectedData.id_payment);
      formik.setFieldValue('paymentCategory', categoryList.find(
        (data) => data.name === selectedData.kategori_payment,
      ).value);
      formik.setFieldValue('paymentName', selectedData.nama_payment);
      formik.setFieldValue('paymentImgLink', selectedData.url_logo);
      formik.setFieldValue('adminName', selectedData.nama_admin);
      formik.setFieldValue('adminPhoneNumber', selectedData.nomor_admin);
      formik.setFieldValue('adminFee', selectedData.fee_admin);
      formik.setFieldValue('adminFeePercentage', selectedData.fee_percentage_admin);
      formik.setFieldValue('availability', selectedData.status_payment ? 'true' : 'false');
      formik.setFieldValue('messageAvailability', selectedData.message_availability);
    }
  }, [selectedData]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('paymentId', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Are You Sure?' });
    }
  }, [deleteData]);

  return (
    <div className="h-full">
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setModalAlert((data) => ({ ...data, visibility: false }));
          setDeleteData('');
          formik.resetForm();
        }}
        handleConfirm={formik.handleSubmit}
      />
      <ModalForm
        visibility={modalFormVisibility || modalUpdate}
        handleVisibility={() => { setModalFormVisibility(false); setModalUpdate(false); formik.resetForm(); setResponseMessage(''); }}
        buttonText={modalUpdate ? 'Edit Payment' : 'Buat Payment'}
        onSubmit={formik.handleSubmit}
        message={responseMessage}
        className="sm:max-w-[600px]"
        height="300px"
      >
        <PaymentForm
          formik={formik}
          isUpdate={modalUpdate}
        />
      </ModalForm>
      <BoxContent>
        {dataQR
        && (
        <div className="flex mb-5">
          <div className="mb-2 p-8 flex flex-col gap-4 shadow-[0_2px_8px_rgba(0,0,0,0.25)] rounded-2xl">
            <div className="text-[#10397C] text-base font-bold">Persentase QR</div>
            <div className="flex sm:flex-row flex-col gap-2">
              <div className="join w-full">
                <input
                  type="text"
                  className={`join-item input input-bordered w-full sm:w-24 border-[#00000033]
                    disabled:bg-[#00000033] disabled:border-[#00000033] font-bold`}
                  disabled={!changeQR}
                  name="adminFeePercentage"
                  value={formik2.values.adminFeePercentage}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!value) formik2.setFieldValue('adminFeePercentage', value);
                    const regex = /^(\d+[.,]?[\d]*)$/;
                    if (regex.test(value.toString())) {
                      formik2.setFieldValue('adminFeePercentage', value);
                    }
                  }}
                />
                <button
                  type="button"
                  className={`join-item btn pointer-events-none border-[#00000033] border
                    disabled:bg-[#00000033] disabled:border-l-0 text-black disabled:text-black`}
                  disabled={!changeQR}
                >
                  %
                </button>
              </div>
              <button
                type="button"
                className="btn btn-primary sm:w-auto w-full bg-[#4A86E8] border-none text-white hover:bg-[#4A86E8]"
                onClick={() => {
                  if (changeQR) formik2.handleSubmit();
                  setChangeQR((prev) => !prev);
                }}
              >
                {changeQR ? 'Save Setting' : 'Change Setting'}
              </button>
              {changeQR
              && (
              <button
                type="button"
                className="btn btn-primary bg-[#FF3333] border-none text-white hover:bg-[#FF3333]"
                onClick={() => {
                  setChangeQR((prev) => !prev);
                }}
              >
                Cancel
              </button>
              )}
            </div>
          </div>
        </div>
        )}
        <Searchbar
          className="mb-6"
          onChange={setSearch}
          withButton
          buttonName="Buat Payment"
          onClick={() => { setModalFormVisibility(true); }}
        />
        {
          payMethodList.length > 0
            ? (
              <TableContent
                type="paymentMethod"
                dataList={payMethodList}
                modalUpdate={setModalUpdate}
                selectedData={setSelectedData}
                deleteData={setDeleteData}
                setSortColumn={setSortColumn}
              />
            )
            : <NoDataView />
        }
      </BoxContent>
    </div>
  );
}

import {
  getData, postData, deleteData, putData,
} from './request';

export const getVoucher = async ({
  searchWord, searchBy, limit, page, sortBy, sortValue, voucherType, discountType, voucherNumber,
  startDate, expiredDate, voucherFor,
}) => {
  const response = await getData({
    path:
      '/admin/voucher'
        + `?searchQuery=${searchWord || ''}`
        + `&searchBy=${searchBy || ''}`
        + `&limit=${limit || ''}`
        + `&page=${page || ''}`
        + `&orderBy=${sortBy || ''}`
        + `&orderValue=${sortValue || ''}`
        + `&voucherType=${voucherType || ''}`
        + `&discountType=${discountType || ''}`
        + `&voucherNumber=${voucherNumber || ''}`
        + `&startDate=${startDate || ''}`
        + `&expiredDate=${expiredDate || ''}`
        + `&voucherFor=${voucherFor || ''}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const addVoucher = async ({
  voucherName, voucherType, discountType, fixedDiscount, percentageDiscount, maximumDiscount,
  voucherNumber, startDate, expiredDate, minimumTransaction, isForReferal, voucherDescription,
}) => {
  const response = await postData({
    path: '/admin/voucher',
    data: {
      voucherName,
      voucherType,
      discountType,
      fixedDiscount,
      percentageDiscount,
      maximumDiscount,
      voucherNumber,
      startDate,
      expiredDate,
      minimumTransaction,
      isForReferal,
      voucherDescription,
    },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editVoucher = async ({
  voucherId, voucherName, voucherType, discountType, fixedDiscount, percentageDiscount,
  maximumDiscount, voucherNumber, startDate, expiredDate, minimumTransaction, isForReferal,
  voucherDescription,
}) => {
  const response = await putData({
    path: '/admin/voucher',
    data: {
      voucherId,
      voucherName,
      voucherType,
      discountType,
      fixedDiscount,
      percentageDiscount,
      maximumDiscount,
      voucherNumber,
      startDate,
      expiredDate,
      minimumTransaction,
      isForReferal,
      voucherDescription,
    },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const deleteVoucher = async ({ voucherId }) => {
  const response = await deleteData({
    path: `/admin/voucher/${voucherId}`,
    withToken: true,
    pathParams: [voucherId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import {
  FaClipboardList, FaTimesCircle,
  FaCheckCircle, FaDatabase, FaCog,
} from 'react-icons/fa';
import { MdSecurityUpdateWarning } from 'react-icons/md';
import { Login } from '../feature/login/Login';

import { Dashboard } from '../feature/dashboard/Dashboard';

import { UnverifiedTopUp } from '../feature/unverified/UnverifiedTopUp';

import { UnpaidTopUp } from '../feature/unpaid/UnpaidTopUp';

import { SuccessTopUp } from '../feature/success/SuccessTopUp';

import { FailedTopUp } from '../feature/failed/FailedTopUp';

import { RefundProcess } from '../feature/refund/RefundProcess';
import { RefundSuccess } from '../feature/refund/RefundSuccess';
import { RefundFailed } from '../feature/refund/RefundFailed';

import { Destination } from '../feature/master-data/Destination';
import { PaymentMethod } from '../feature/master-data/PaymentMethod';
import { Category } from '../feature/master-data/Category';
import { Pemeliharaan } from '../feature/pemeliharaaan/Pemeliharaan';
import { BlacklistReq } from '../feature/blacklist/BlacklistReq';
import { BlacklistList } from '../feature/blacklist/BlacklistList';
import { Notification } from '../feature/notification/Notification';
import { Banner } from '../feature/banner/Banner';
import { Voucher } from '../feature/voucher/Voucher';
import { RegisteredUser } from '../feature/master-data/RegisteredUser';
import { DashboardNFT } from '../feature/dashboard/Dashboard-NFT';

export const navigationList = [
  {
    noSidebar: true,
    path: '/',
    element: <Login />,
    noAuth: true,
    title: 'Login',
  },
  {
    name: 'Dashboard',
    icon: <AiFillHome className="w-4 h-4" />,
    path: '/dashboard',
    element: <Dashboard />,
    title: 'Dashboard Admin',
  },
  {
    name: 'Dashboard',
    icon: <AiFillHome className="w-4 h-4" />,
    path: '/mash-jawir',
    element: <DashboardNFT />,
    title: 'Dashboard Admin',
    hide: true,
  },
  {
    name: 'Top Up Manual',
    icon: <FaClipboardList className="w-4 h-4" />,
    list: [
      {
        name: 'Unverified Transaction',
        path: '/unverified',
        element: <UnverifiedTopUp />,
        title: 'Unverified Transaction - Top Up',
      },
      {
        name: 'Unpaid Transaction',
        path: '/unpaid',
        element: <UnpaidTopUp />,
        title: 'Unpaid Transaction - Top Up',
      },
      {
        name: 'Refund Transaction',
        path: '/refund',
        element: <RefundProcess />,
        title: 'Refund Transaction',
      },
    ],
  },
  {
    name: 'Success Transaction',
    icon: <FaCheckCircle className="w-4 h-4" />,
    list: [
      {
        path: '/success',
        name: 'Top Up',
        element: <SuccessTopUp />,
        title: 'Success Transaction - Top Up',
      },
      {
        path: '/refund-success',
        name: 'Refund',
        element: <RefundSuccess />,
        title: 'Success Transaction - Refund',
      },
    ],
  },
  {
    name: 'Failed Transaction',
    icon: <FaTimesCircle className="w-4 h-4" />,
    list: [
      {
        path: '/failed',
        name: 'Top Up',
        element: <FailedTopUp />,
        title: 'Failed Transaction - Top Up',
      },
      {
        path: '/refund-failed',
        name: 'Refund',
        element: <RefundFailed />,
        title: 'Failed Transaction - Refund',
      },
    ],
  },
  {
    name: 'Master Data',
    icon: <FaDatabase className="w-4 h-4" />,
    list: [
      {
        path: '/master-data/destination',
        name: 'Destination',
        element: <Destination />,
        title: 'Master Data - Destination',
      },
      {
        path: '/master-data/category',
        name: 'Category',
        element: <Category />,
        title: 'Master Data - Category',
      },
      {
        path: '/master-data/payment-method',
        name: 'Payment Method',
        element: <PaymentMethod />,
        title: 'Master Data - Payment Method',
      },
      {
        path: '/master-data/registered-user',
        name: 'Registered User',
        element: <RegisteredUser />,
        title: 'Master Data - Registered User',
      },
    ],
  },
  {
    name: 'Blacklist Number',
    icon: <MdSecurityUpdateWarning className="w-4 h-4" />,
    list: [
      {
        path: '/blacklist/request',
        name: 'Blacklist Request',
        element: <BlacklistReq />,
        title: 'Blacklist - Request',
      },
      {
        path: '/blacklist/list',
        name: 'Blacklist List',
        element: <BlacklistList />,
        title: 'Blacklist - List',
      },
    ],
  },
  {
    name: 'CMS Mobile Application',
    icon: <FaCog className="w-4 h-4" />,
    list: [
      {
        name: 'Pemeliharaan',
        path: '/pemeliharaan',
        element: <Pemeliharaan />,
        title: 'Pemeliharaan',
      },
      {
        name: 'Notifikasi',
        path: '/notifikasi',
        element: <Notification />,
        title: 'Notifikasi',
      },
      {
        name: 'Banner',
        path: '/banner',
        element: <Banner />,
        title: 'Banner ',
      },
      {
        name: 'Voucher User',
        path: '/voucher',
        element: <Voucher />,
        title: 'Voucher User ',
      },
    ],
  },
];

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from '../../../components/Carousel';
import { startLoading, stopLoading } from '../../../redux/slice';
import { getReportDetailList } from '../../../services/verify-report-service';

export function FormatReportName({ phoneNumberName, reportedName }) {
  const undefinedName = <span className="text-gray-400">[undefined]</span>;
  return (
    <span>
      {phoneNumberName || undefinedName}
    </span>
  );
}

export function DetailForm({ detail, fromList, modalImg }) {
  const dispatch = useDispatch();
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      dispatch(startLoading());
      const response = await getReportDetailList({ phoneNumber: detail?.phoneNumber });
      dispatch(stopLoading());
      setReportList(response?.message?.list || []);
    };
    getData();
  }, [detail?.phoneNumber]);

  return (
    <div className="h-full flex gap-2 overflow-x-auto">
      <Carousel
        className="sm:!w-1/3 min-w-[200px] sm:min-w-0 flex"
        fromDetail
        imageUrls={detail.linkProof.map((link) => link.signed)}
        modalImg={modalImg}
      />
      <div className="flex-1 flex flex-col gap-2 min-w-[300px] sm:min-w-0">
        <div className="flex flex-col gap-2">
          <div className="font-bold leading-4">
            Nama
          </div>
          <div className="bg-[#D9D9D9] p-2 rounded-lg leading-4">
            <FormatReportName
              phoneNumberName={detail?.phoneNumberName}
              reportedName={detail?.reportedName}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-bold leading-4">
            Nomor
          </div>
          <div className="bg-[#D9D9D9] p-2 rounded-lg leading-4">
            {detail?.phoneNumber ? detail?.phoneNumber : 'Reported by Admin'}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-bold leading-4">
            Alasan
          </div>
          <div className="bg-[#D9D9D9] p-2 rounded-lg leading-4">
            {detail?.blockReason}
          </div>
        </div>
        {!fromList
            && (
            <div className="flex flex-col gap-2 flex-1 overflow-y-auto">
              <div className="font-bold leading-4">
                Laporan Lainnya pada Nomor ini
              </div>
              <div className="overflow-y-auto flex flex-col gap-2">
                {reportList.map((report, index) => (
                  <div
                    key={index}
                    className="p-2 pb-4 leading-4 border-b-2"
                  >
                    {report}
                  </div>
                ))}
              </div>
            </div>
            )}
      </div>
    </div>
  );
}

import { uploadGcp } from './gcp-service';
import { postData, putData } from './request';

export const updateTrx = async ({ idTrx, statusCode, messageAdmin }) => {
  const response = await putData({
    path: '/admin/transaksi',
    data: { idTrx, statusCode, messageAdmin },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const uploadProof = async ({ idTrx, file }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('idTrx', idTrx);
  const response = await postData({
    path: '/admin/bukti-transaksi',
    data: formData,
    withToken: true,
    contentType: 'multipart/form-data',
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const unpaidRequest = async ({ idTrx, file }) => {
  const response = await postData({
    path: `/admin/bukti-transaksi/${idTrx}/requestUpload`,
    data: {
      filesize: file?.size || '',
      dataType: file?.type?.replaceAll('image/', '') || '',
    },
    withToken: true,
    pathParams: [idTrx],
  }).then((responseReqLink) => {
    if (responseReqLink.error || responseReqLink.data.errors) return responseReqLink;
    return uploadGcp({ url: responseReqLink.data.message.data.url, file });
  }).then((responseGcp) => {
    if (responseGcp.error) return { error: responseGcp.error };
    return postData({
      path: `/admin/bukti-transaksi/${idTrx}/uploaded`,
      withToken: true,
      pathParams: [idTrx],
    });
  });
  if (response.error) return { error: response.error };
  return response.data;
};

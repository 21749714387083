import React, { useEffect, useState } from 'react';
import { FaListUl, FaCalendarAlt, FaUserCircle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { navigationList } from '../redux/navigation.slice';
import { Dropdown, Loading } from './Oripay';
import { getData } from '../services/request';
import { ModalAlert } from './ModalAlert';
import { dispatchAdminLogout, refreshPage } from '../redux/dispatch';
import { startLoading, stopLoading } from '../redux/slice';
import { formatDate } from '../utils/formatter';

export function Topbar({ className, sidebarToggle, setSidebarToggle }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [topbarTitle, setTopbarTitle] = useState('Dashboard');
  const [topbarDetail, setTopbarDetail] = useState('');
  const [date, setDate] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    navigationList.forEach((item) => {
      if (!item.list) {
        if (location.pathname === item.path) {
          setTopbarTitle(item.name);
          setTopbarDetail('');
        }
      } else {
        item.list.forEach((child) => {
          if (location.pathname === child.path) {
            setTopbarTitle(item.name);
            setTopbarDetail({ parent: item.name, child: child.name });
          }
        });
      }
    });

    const todaysDate = () => {
      const newDate = new Date();
      setDate(`${formatDate(newDate)}`);
    };

    todaysDate();
  }, [location]);

  const logout = async () => {
    try {
      dispatch(startLoading());
      const response = await getData({
        path: '/auth/admin/logout',
      });
      dispatch(stopLoading());

      if (response.data.code === 200) {
        navigate('/');
        dispatchAdminLogout();
      } else {
        setMessage('Request Timeout');
        setModalVisibility(true);
      }
    } catch (error) {
      setMessage('Can\'t connect to server');
      setModalVisibility(true);
    }
  };

  const toggleModalVisibility = () => {
    if (modalVisibility) {
      setModalVisibility(false);
    } else {
      setModalVisibility(true);
    }
  };

  const toggleSidebar = (e) => {
    if (sidebarToggle) {
      setSidebarToggle(false);
    } else {
      setSidebarToggle(true); e.stopPropagation();
    }
  };

  return (
    <div className="flex flex-col">
      <Loading />
      <div className={`${className || ''} p-4 sm:p-10 flex`}>
        <ModalAlert
          modalVisibility={modalVisibility}
          type="failed"
          message={message}
          handleVisibility={() => toggleModalVisibility()}
        />
        <div className="flex flex-col flex-1 gap-2">
          <div className="flex gap-4">
            <div
              onClick={(e) => toggleSidebar(e)}
              className="text-orange cursor-pointer rounded-lg sm:rounded-none bg-[#ececec] p-3 sm:p-0
              transition active:scale-105 sm:active:scale-100 sm:bg-white sm:cursor-default"
            >
              <FaListUl className="w-6 h-6" />

            </div>
            <div className="flex flex-col gap-2 justify-center">
              <div
                onClick={() => { refreshPage(); }}
                className="cursor-pointer font-dmsans font-bold
              text-xl text-blue flex"
              >
                {topbarTitle}
              </div>
              <div className={`${!topbarDetail ? 'hidden' : 'flex sm:hidden'} gap-2 font-normal text-xs 
              leading-4 text-blue`}
              >
                <span>{`${topbarDetail.parent}`}</span>
                <span>{'>'}</span>
                <strong>{topbarDetail.child}</strong>
              </div>
            </div>
          </div>
          <div className={`${!topbarDetail ? 'hidden' : 'hidden sm:flex'} gap-2 font-normal 
            text-xs leading-4 text-blue`}
          >
            <span>{`${topbarDetail.parent}`}</span>
            <span>{'>'}</span>
            <strong>{topbarDetail.child}</strong>
          </div>
        </div>
        <div className="flex">
          <div className="hidden gap-4 sm:flex pr-4 border-r-[1px] py-3">
            <div className="text-orange"><FaCalendarAlt className="w-6 h-6" /></div>
            <div className="font-dmsans text-black font-normal text-xl leading-6">{date}</div>
          </div>
          <div className="pl-4">
            <Dropdown
              list={[
                { name: 'Logout', action: logout },
              ]}
              childClass="w-[158px] bg-white ml-[-110px] sm:ml-0 p-1 font-dmsans text-black
              font-normal text-base leading-5"
              className="bg-[#ececec] cursor-pointer transition active:scale-105 flex gap-4
              rounded-lg p-3 font-dmsans text-black font-normal text-xl leading-6"
            >
              <div className="text-orange"><FaUserCircle className="w-6 h-6" /></div>
              <div className="sm:flex hidden">admin</div>
              <div className="pt-1 hidden sm:flex"><FiChevronDown /></div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

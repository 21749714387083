import React, { useEffect, useState } from 'react';
import { Button, BoxWallet, TrxDetail } from '../../../components/Oripay';
import { formatModalData } from '../../../utils/formatter';
import { getProofUser } from '../../../services/transaction-service';

export function UnverifiedForm({
  handleCancel, trx, formik, isUkt, modalImg,
}) {
  const {
    originLogo, originName, destinationLogo, destinationName, detailList,
  } = formatModalData(trx, isUkt);

  const [imageSourceUser, setImageSourceUser] = useState('');

  useEffect(() => {
    async function getUserProof() {
      const response = await getProofUser({ idTrx: trx.id_trx });
      setImageSourceUser(response?.message?.message || '');
    }
    if (trx.id_trx) getUserProof();
  }, [trx.id_trx]);

  return (
    <div className="flex gap-8 overflow-x-auto mt-4">
      <div
        onClick={() => { modalImg({ visibility: true, src: `${imageSourceUser}` }); }}
        className="cursor-pointer h-[461px] w-[288px] min-w-[288px]
          border-[1px] border-[#dedede] bg-[#dedede] rounded-lg flex justify-center items-center"
      >
        <img
          alt="unv-img"
          src={`${imageSourceUser}`}
          className="h-full"
        />
      </div>
      <div className="flex items-center">
        <div className="flex flex-col flex-1 gap-14">
          <BoxWallet
            destinationLogo={destinationLogo}
            destinationName={destinationName}
            originLogo={originLogo}
            originName={originName}
            destinationNumber={trx.ke_no_telepon}
            isUkt={isUkt}
          />
          <TrxDetail
            detailList={detailList}
            isUkt={isUkt}
          />
          <div className="flex flex-row gap-4">
            <Button
              type="button"
              onClick={() => { handleCancel(true); }}
              color="danger-outline"
              className="w-full"
            >
              Cancel Transaksi
            </Button>
            <Button
              type="submit"
              onClick={() => { formik.setFieldValue('statusCode', 'PC'); }}
              className="w-full"
            >
              Verifikasi
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Input, SimpleOption } from '../../../components/Oripay';
import { status } from '../../../redux/slice';

export function CategoryForm({ formik, isUpdate }) {
  return (
    <>
      <Input
        className={`${isUpdate ? 'hidden' : ''}`}
        type="text"
        label="ID Kategori"
        styling="form"
        placeholder="Masukkan ID Kategori"
        name="categoryId"
        onChange={formik.handleChange}
        value={formik.values.categoryId}
      />
      <Input
        type="text"
        label="Nama Kategori"
        styling="form"
        placeholder="Masukkan Nama Kategori"
        name="categoryName"
        onChange={formik.handleChange}
        value={formik.values.categoryName}
      />
      <SimpleOption
        onSelect={formik.handleChange}
        name="categoryStatus"
        value={formik.values.categoryStatus}
        label="Status"
        placeholder="Pilih Status"
        list={status}
      />
    </>
  );
}

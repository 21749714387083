import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Transition } from '@headlessui/react';
import { Button } from './Oripay';

export function ModalForm({
  visibility,
  handleVisibility,
  children,
  buttonText,
  onSubmit,
  buttonColor,
  className,
  enableCancelButton,
  noButton,
  zIndex,
  message,
  cancelButtonText,
  cancelButtonOnClick,
  specialType,
  cancelButtonColor,
  enableButton = true,
  disableMaxWidth = false,
  fromCMS = false,
}) {
  let midSectionClass;
  if (fromCMS) {
    midSectionClass = 'overflow-y-auto';
  } else if (noButton) {
    midSectionClass = 'max-h-[520px]';
  } else {
    midSectionClass = 'py-4 pb-6 max-h-[420px]';
  }

  return (
    <Transition
      show={visibility}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`flex items-center justify-center ${zIndex || 'z-[995]'} fixed inset-0`}
      onClick={handleVisibility}
    >
      <div
        className="h-screen w-screen bg-black bg-opacity-25 fixed"
        onClick={handleVisibility}
      />
      <Transition.Child
        enter="transition ease-in-out duration-500 transform"
        enterFrom="-translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-full"
        className="flex justify-center w-full px-4"
      >
        <form
          className={`bg-white ${disableMaxWidth ? '' : 'modal-box'} relative pl-8 py-8 pr-6 w-full sm:min-w-[500px] rounded-2xl 
            ${className}`}
          onClick={(e) => e.stopPropagation()}
          onSubmit={(e) => { e.preventDefault(); onSubmit(e); }}
        >
          <div className="flex text-black justify-end w-full">
            <FaTimes
              className="cursor-pointer"
              onClick={handleVisibility}
            />
          </div>
          <div className={`${midSectionClass} flex flex-col ${specialType && 'h-fit'}`}>
            <div className={`flex flex-col flex-1 gap-4 ${specialType ? 'h-full' : 'overflow-y-auto'}`}>
              <div className={`flex flex-col gap-4 pr-2 ${specialType && 'h-full'}`}>
                {children}
              </div>
            </div>
          </div>
          <div className={`${message ? 'flex' : 'hidden'} font-dmsans font-normal text-base text-[#DC3545] pr-2 mb-4`}>{message}</div>
          <div className={`flex gap-4 pr-2 ${noButton ? 'hidden' : ''}`}>
            <Button
              type="submit"
              color={buttonColor}
              className={`${enableButton ? '' : 'hidden'} w-full`}
            >
              {buttonText}
            </Button>
            <Button
              className={`${enableCancelButton ? '' : 'hidden'} w-full`}
              color={cancelButtonColor || 'danger'}
              onClick={cancelButtonOnClick}
            >
              {cancelButtonText || 'Cancel'}
            </Button>
          </div>
        </form>
      </Transition.Child>
    </Transition>
  );
}

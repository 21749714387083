import React, { useEffect, useState } from 'react';
import { AiFillFileImage } from 'react-icons/ai';
import { BsPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { MdBlock, MdCheck } from 'react-icons/md';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import {
  formatDate, formatMoney, isActive, getLabel,
} from '../utils/formatter';
import { Button } from './Oripay';
import { FormatReportName } from '../feature/blacklist/form/DetailForm';
import { voucherTypeList, voucherNumberList } from '../utils/constant';

function truncate(str, n) {
  return (str.length > n) ? `${str.slice(0, n - 1)}...` : str;
}

function TableHeader({
  headerRow, sortData, setSortColumn,
}) {
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    setSortColumn({ sortBy, sortOrder });
  }, [sortBy, sortOrder]);

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  return (
    <thead className="bg-[#F2F2F2] border-[1px] rounded-t-xl mx-1">
      {headerRow.map((rowData, index) => (
        <tr
          key={index}
          className="items-center"
        >
          {rowData.map((data, idx) => (
            <th
              key={idx}
              className={`
                  py-2 text-[#10397C] min-w-[100px] px-2 
                  ${sortData[index][idx] && 'cursor-pointer hover:bg-[#87cfeb64]'}
                `}
              onClick={() => {
                if (sortData[index][idx]) {
                  if (sortBy !== sortData[index][idx]) {
                    setSortOrder('asc');
                    setSortBy(sortData[index][idx]);
                  } else if (sortOrder === 'desc') {
                    setSortOrder('');
                    setSortBy('');
                  } else {
                    setSortOrder('desc');
                    setSortBy(sortData[index][idx]);
                  }
                } else setSortBy('');
              }}
            >
              <div className="flex flex-row justify-around items-center">
                <div>{data}</div>
                {
                    sortData[index][idx]
                    && iff(
                      sortBy === sortData[index][idx] && sortOrder,
                      sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />,
                      <FaSort />,
                    )
                  }
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

function TableBody({
  dataList,
  selectedData,
  modalUpdate,
  deleteData,
  customFunc,
}) {
  const getDataComponent = (rowData, data, index) => {
    if (data === 'fileIcon') {
      return (
        <td
          className="py-1"
          key={index}
        >
          <AiFillFileImage
            onClick={() => { selectedData(rowData.rawData); modalUpdate(true); }}
            color="#F28C20"
            className="m-auto cursor-pointer"
          />
        </td>
      );
    }
    if (data === 'editAndDeleteButton') {
      return (
        <td
          className="py-1"
          key={index}
        >
          <div className="flex flex-row justify-center gap-2">
            <Button
              onClick={() => { modalUpdate(true); selectedData(rowData); }}
              styling="data"
              color="orange"
            >
              <div className="flex flex-row gap-2 text-white">
                <BsPencilFill
                  className="m-auto"
                />
                Edit
              </div>
            </Button>
            <Button
              onClick={() => { deleteData(rowData.id || rowData.voucherId); }}
              styling="data"
              color="danger"
            >
              <div className="flex flex-row gap-2 text-white">
                <BsFillTrashFill
                  className="m-auto"
                />
                Delete
              </div>
            </Button>
          </div>
        </td>
      );
    }
    if (React.isValidElement(data)) {
      return (
        <td
          className="text-center py-1"
          key={index}
        >
          {data}
        </td>
      );
    }
    if (data === 'blacklistAndDeleteButton') {
      return (
        <td
          className="py-1"
          key={index}
        >
          <div className="flex flex-row justify-center gap-2">
            <Button
              onClick={() => {
                customFunc.setAcceptData({
                  id: rowData.phoneNumberReportId,
                  number: rowData.phoneNumber,
                });
              }}
              styling="data"
              color="black"
            >
              <div className="flex flex-row gap-2 text-white">
                <MdBlock
                  className="m-auto"
                />
                Blacklist
              </div>
            </Button>
            <Button
              onClick={() => {
                customFunc.setRejectData({
                  id: rowData.phoneNumberReportId,
                  number: rowData.phoneNumber,
                });
              }}
              styling="data"
              color="danger"
            >
              <div className="flex flex-row gap-2 text-white">
                <BsFillTrashFill
                  className="m-auto"
                />
                Delete
              </div>
            </Button>
          </div>
        </td>
      );
    }
    if (data === 'activeButton') {
      return (
        <td
          key={index}
          className="py-4"
        >
          <div className="flex justify-center">
            {!rowData.ACTIVE
              ? (
                <button
                  type="button"
                  className="p-2 bg-white border-[#1B692D] border-2 rounded-md text-[#1B692D]"
                  onClick={() => {
                    customFunc.setChangeStatusData(rowData);
                  }}
                >
                  Set Active
                </button>
              )
              : (
                <button
                  type="button"
                  className="p-2 bg-[#9CE7AD] border-[#1B692D] border-2 rounded-md text-[#1B692D] min-w-[94px]"
                  onClick={() => {
                    customFunc.setChangeStatusData(rowData);
                  }}
                >
                  Active
                </button>
              )}
          </div>
        </td>
      );
    }
    if (data?.includes('button')) {
      return (
        <td
          className="py-2"
          key={index}
        >
          <Button
            failed
            onClick={() => { selectedData(rowData.rawData); modalUpdate(true); }}
            styling="data"
            color="orange"
          >
            {data.split(';;').at(1)}
          </Button>
        </td>
      );
    }
    if (data === 'webViewLink') {
      return (
        <td
          className="text-center py-1"
          key={index}
        >
          <a
            href={rowData.WEBVIEW_URL}
            target="_blank"
            rel="noreferrer"
            className="text-[#4A86E8] underline"
          >
            {rowData.WEBVIEW_URL}
          </a>
        </td>
      );
    }
    if (data === 'viewDetail') {
      return (
        <td
          className="text-center py-4"
          key={index}
        >
          <Button
            onClick={() => { customFunc.setModalDetailVisibility(true); selectedData(rowData); }}
            styling="data"
            className="min-w-[100px]"
          >
            <div className="flex flex-row gap-2 text-white">
              Lihat Detail
            </div>
          </Button>
        </td>
      );
    }
    if (data === 'unbanButton') {
      return (
        <td
          className="text-center py-4"
          key={index}
        >
          <Button
            onClick={() => { deleteData(rowData.phoneNumber); }}
            styling="data"
            color="danger"
          >
            <div className="flex flex-row gap-2 text-white">
              <BsFillTrashFill
                className="m-auto"
              />
              Unban
            </div>
          </Button>
        </td>
      );
    }
    return (
      <td
        className="text-center py-1"
        key={index}
      >
        {data}
      </td>
    );
  };

  return (
    <tbody className="mx-1">
      {
        dataList.map((rowData, indexData) => (
          <tr
            key={indexData}
            className="items-center border-b-[1px] border-b-[#00000025]"
          >
            {Object.values(rowData.data).map((data, index) => (
              getDataComponent(rowData, data, index)))}
          </tr>
        ))
      }
    </tbody>
  );
}

export function TableContent({
  type,
  dataList,
  selectedData,
  modalUpdate = () => null,
  deleteData = () => null,
  customFunc = () => null,
  setSortColumn = () => null,
}) {
  let headerRow = [[]];
  let sortData = [[]];
  let newDataList = [{}];

  switch (type) {
    case 'success':
      headerRow = [
        ['TRX ID', 'NAMA', 'TANGGAL', 'FROM', 'TO', 'NOMOR', 'NOMINAL', 'REASON', 'BUKTI'],
      ];
      sortData = [
        ['idBase64', 'nama', 'date', 'dariNamaPayment', 'keNamaPayment', 'keNoTelepon', 'nominal', 'status', ''],
      ];
      newDataList = dataList.map((rawData) => ({
        rawData,
        data: {
          id_trx: truncate(rawData?.id_trx_base64, 20),
          dari_nama: rawData?.dari_nama,
          tgl_trx: formatDate(rawData?.tgl_trx),
          dari_nama_payment: rawData?.dari_nama_payment,
          ke_nama_payment: rawData?.ke_nama_payment,
          ke_no_telepon: rawData?.ke_no_telepon,
          nominal: formatMoney(rawData?.nominal),
          status: rawData?.status,
          extra: 'fileIcon',
        },
      }));
      break;
    case 'failed':
      headerRow = [
        ['TRX ID', 'NAMA', 'TANGGAL', 'FROM', 'TO', 'NOMOR', 'NOMINAL', 'REASON', 'DETAIL'],
      ];
      sortData = [
        ['idBase64', 'nama', 'date', 'dariNamaPayment', 'keNamaPayment', 'keNoTelepon', 'nominal', 'status', ''],
      ];
      newDataList = dataList.map((rawData) => ({
        rawData,
        data: {
          id_trx: truncate(rawData?.id_trx_base64, 20),
          dari_nama: rawData?.dari_nama,
          tgl_trx: formatDate(rawData?.tgl_trx),
          dari_nama_payment: rawData?.dari_nama_payment,
          ke_nama_payment: rawData?.ke_nama_payment,
          ke_no_telepon: rawData?.ke_no_telepon,
          nominal: formatMoney(rawData?.nominal),
          status: rawData?.status,
          extra: 'button;;Lihat Detail',
        },
      }));
      break;
    case 'success-ukt':
      headerRow = [
        ['TRX ID', 'NIM', 'NAMA MAHASISWA', 'TANGGAL', 'JURUSAN', 'PRODI', 'NOMINAL', 'REASON', 'BUKTI'],
      ];
      newDataList = dataList.map((rawData) => ({
        rawData,
        data: {
          id_trx: truncate(rawData?.id_trx_base64, 20),
          ukt_nim: rawData?.ukt_nim,
          ukt_nama: rawData?.ukt_nama,
          tgl_trx: formatDate(rawData?.tgl_trx),
          ukt_jurusan: rawData?.ukt_jurusan,
          ukt_prodi: rawData?.ukt_prodi,
          nominal: formatMoney(rawData?.nominal),
          status: rawData?.status,
          extra: 'fileIcon',
        },
      }));
      break;
    case 'failed-ukt':
      headerRow = [
        ['TRX ID', 'NIM', 'NAMA MAHASISWA', 'TANGGAL', 'JURUSAN', 'PRODI', 'NOMINAL', 'REASON', 'DETAIL'],
      ];
      newDataList = dataList.map((rawData) => ({
        rawData,
        data: {
          id_trx: truncate(rawData?.id_trx_base64, 20),
          ukt_nim: rawData?.ukt_nim,
          ukt_nama: rawData?.ukt_nama,
          tgl_trx: formatDate(rawData?.tgl_trx),
          ukt_jurusan: rawData?.ukt_jurusan,
          ukt_prodi: rawData?.ukt_prodi,
          nominal: formatMoney(rawData?.nominal),
          status: rawData?.status,
          extra: 'button;;Lihat Detail',
        },
      }));
      break;
    case 'destination':
      headerRow = [[
        'STATUS QR', 'STATUS MANUAL', 'NAMA',
        'MIN. TRANSAKSI', 'MAX. TRANSAKSI', 'Disbursement Agent', 'Disbursement Fee', 'PROSES',
      ]];
      sortData = [[
        'statusPaymentQR', 'statusPaymentManual', 'namaPayment',
        'minTrx', 'maxTrx', 'disbursementAgentName', 'sellPrice', '',
      ]];
      newDataList = dataList.map((rawData) => ({
        id: rawData?.id_destination,
        ...rawData,
        data: {
          status_qr: rawData?.status_payment_qr
            ? (
              <div className="flex w-full justify-center">
                <MdCheck />
              </div>
            )
            : '-',
          status_manual: rawData?.status_payment_manual ? (
            <div className="flex w-full justify-center">
              <MdCheck />
            </div>
          ) : '-',
          nama_payment: rawData?.nama_payment,
          min_trx: formatMoney(rawData?.min_trx),
          max_trx: formatMoney(rawData?.max_trx),
          disbursement_agent_name: rawData?.disbursement_agent_name || '-',
          disbursement_agent_fee: rawData?.sell_price ? formatMoney(rawData?.sell_price) : '-',
          extra: 'editAndDeleteButton',
        },
      }));
      break;
    case 'category':
      headerRow = [
        ['NAMA', 'STATUS', 'PROSES'],
      ];
      sortData = [
        ['namaCategory', 'status', ''],
      ];
      newDataList = dataList.map((rawData) => ({
        id: rawData?.id_category,
        data: {
          nama_category: rawData?.nama_category,
          status: isActive(rawData?.status),
          extra: 'editAndDeleteButton',
        },
      }));
      break;
    case 'paymentMethod':
      headerRow = [
        ['NAMA', 'NOMOR', 'PEMILIK', 'STATUS', 'PROSES'],
      ];
      sortData = [
        ['namaPayment', 'nomorAdmin', 'namaAdmin', 'statusPayment', ''],
      ];
      newDataList = dataList.map((rawData) => ({
        ...rawData,
        id: rawData?.id_payment,
        data: {
          nama_payment: rawData?.nama_payment,
          nomor_admin: rawData?.nomor_admin,
          nama_admin: rawData?.nama_admin,
          status: isActive(rawData?.status_payment),
          extra: 'editAndDeleteButton',
        },
      }));
      break;
    case 'pemeliharaan':
      headerRow = [
        ['JUDUL', 'PESAN PEMELIHARAAN', 'URL WEBVIEW', 'STATUS', 'PROSES'],
      ];
      sortData = [['message', 'description', 'url', 'status', '']];
      newDataList = dataList.map((rawData) => ({
        id: rawData?.WARNING_ID,
        WARNING_ID: rawData?.WARNING_ID,
        MESSAGE: rawData?.MESSAGE,
        DESCRIPTION: rawData?.DESCRIPTION,
        WEBVIEW_URL: rawData?.WEBVIEW_URL,
        ACTIVE: rawData?.ACTIVE,
        data: {
          pemeliharaanTitle: rawData?.MESSAGE,
          pemeliharaanDesc: rawData?.DESCRIPTION,
          pemeliharaanWebview: 'webViewLink',
          pemeliharaanStatus: 'activeButton',
          extra: 'editAndDeleteButton',
        },
      }));
      break;
    case 'blacklistList':
      headerRow = [
        ['NOMOR', 'NAMA', 'DETAIL', 'PROSES'],
      ];
      sortData = [['number', 'name', '', '']];
      newDataList = dataList.map((rawData) => ({
        ...rawData,
        data: {
          phoneNumber: rawData?.phoneNumber,
          phoneNumberNameAgg: <FormatReportName
            phoneNumberName={rawData?.phoneNumberNameAgg}
            reportedName={rawData?.reportedName}
          />,
          detail: 'viewDetail',
          extra: 'unbanButton',
        },
      }));
      break;
    case 'reportList':
      headerRow = [
        ['NOMOR', 'NAMA', 'DETAIL', 'PROSES'],
      ];
      sortData = [['number', 'name', '', '']];
      newDataList = dataList.map((rawData) => ({
        ...rawData,
        data: {
          phoneNumber: rawData?.phoneNumber,
          phoneNumberName: <FormatReportName
            phoneNumberName={rawData?.phoneNumberName}
            reportedName={rawData?.reportedName}
          />,
          detail: 'viewDetail',
          extra: 'blacklistAndDeleteButton',
        },
      }));
      break;
    case 'notification':
      headerRow = [
        ['ID', 'JUDUL', 'JENIS', 'TANGGAL MULAI', 'TANGGAL SELESAI', 'DETAIL'],
      ];
      sortData = [
        ['notifId', 'judul', 'jenis', 'tanggalMulai', 'tanggalSelesai', ''],
      ];
      newDataList = dataList.map((rawData) => ({
        ...rawData,
        data: {
          notificationId: rawData?.notifId,
          notificationTitle: rawData?.judul,
          notificationType: rawData?.jenisNotif,
          notificationStartDate: formatDate(rawData?.tanggalMulai),
          notificationEndDate: formatDate(rawData?.tanggalSelesai),
          detail: 'viewDetail',
        },
      }));
      break;
    case 'voucher':
      headerRow = [
        ['NAMA', 'JENIS', 'POTONGAN (%)', 'POTONGAN (Rp)', 'JUMLAH', 'BATAS WAKTU', 'MIN TRANSAKSI', 'ACTION'],
      ];
      sortData = [[
        'voucherName', 'voucherType', 'percentageDiscount', 'fixedDiscount',
        'voucherNumber', 'expiredDate', 'minimumTransaction', '',
      ]];
      newDataList = dataList.map((rawData) => ({
        ...rawData,
        data: {
          voucherName: (
            <div className={`${rawData?.isForReferal ? 'font-bold' : ''} flex justify-center`}>
              {rawData?.voucherName}
              <div className={`${rawData?.isForReferal ? '' : 'hidden'} text-[#F28C20]`}>*</div>
            </div>
          ),
          voucherType: getLabel(rawData?.voucherType, voucherTypeList),
          voucherDiscountPercentage: rawData?.discountType === 'percentage'
            ? `${`${rawData?.percentageDiscount}%`}` : '-',
          voucherDiscountFixed: rawData?.discountType === 'fixed'
            ? `${formatMoney(rawData?.fixedDiscount)}` : '-',
          voucherNumber: rawData?.voucherNumber !== 'unlimited'
            ? `${rawData?.voucherNumber}`
            : getLabel(rawData?.voucherNumber, voucherNumberList),
          expiredDate: rawData?.expiredDate !== 'unlimited'
            ? formatDate(rawData?.expiredDate)
            : getLabel(rawData?.expiredDate, voucherNumberList),
          minimumTransaction: `${formatMoney(rawData?.minimumTransaction)}`,
          extra: 'editAndDeleteButton',
        },
      }));
      break;
    case 'registeredUserList':
      headerRow = [['No', 'Nama', 'No HP', 'Email', 'Tanggal Signup', 'Referal Code']];
      sortData = [['', 'nama', 'telepon', 'email', 'signup', 'referal']];
      newDataList = dataList.map((rawData) => ({
        ...rawData,
        data: {
          no: rawData?.no,
          nama: rawData?.nama,
          telepon: rawData?.telepon,
          email: rawData?.email,
          signupTime: formatDate(rawData?.signupTime),
          referalCode: rawData?.referalCode,
        },
      }));
      break;
    default:
      headerRow = [];
      newDataList = [];
      break;
  }

  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col w-0 flex-1 h-full overflow-x-auto">
        <table className="table-auto">
          <TableHeader
            headerRow={headerRow}
            sortData={sortData}
            setSortColumn={setSortColumn}
          />
          <TableBody
            deleteData={deleteData}
            selectedData={selectedData}
            modalUpdate={modalUpdate}
            dataList={newDataList}
            customFunc={customFunc}
          />
        </table>
      </div>
    </div>
  );
}

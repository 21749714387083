/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import * as uuid from 'uuid';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import Pagination from '../../components/Pagination';
import { ModalForm } from '../../components/ModalForm';
import { CardList } from '../../components/Card';
import { startLoading, stopLoading } from '../../redux/slice';

import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';
import { BannerForm } from './form/BannerForm';
import { ModalAlert } from '../../components/ModalAlert';
import { ModalImg } from '../../components/ModalImg';
import {
  addBanner, deleteBanner, editBanner, editStatusBanner, getBanner,
} from '../../services/banner-service';
import { NewFilter } from '../../components/Filter';
import { statusList } from '../../utils/constant';
import { getCSRF } from '../../services/request';

export function Banner() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    searchWord: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    summary: 0,
  });
  const [bannerList, setBannerList] = useState([]);
  const refresh = useSelector((state) => state.app).refreshPage;
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdateVisibility, setModalUpdateVisibility] = useState(false);
  const [modalData, setModalData] = useState();
  const [deleteData, setDeleteData] = useState('');
  const [changeStatusData, setChangeStatusData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const bannerTypeList = [{ name: 'Carousel', value: 'carousel' }, { name: 'Popup', value: 'popup' }];
  const [selectedFile, setSelectedFile] = useState('');
  const handleFile = (e) => { setSelectedFile(e); };
  const [modalImg, setModalImg] = useState({
    visibility: false, src: '',
  });
  const [filter, setFilter] = useState({});
  const filterList = [
    {
      label: 'Jenis',
      element: {
        label: 'Filter Jenis',
        list: [
          {
            label: 'Jenis Banner', type: 'dropdown', key: 'bannerType', list: bannerTypeList,
          },
        ],
      },
    },
    {
      label: 'Date',
      element: {
        label: 'Date Waktu',
        list: [
          { label: 'Start Date', type: 'date', key: 'startDate' },
          { label: 'End Date', type: 'date', key: 'endDate' },
        ],
      },
    },
    {
      label: 'Status',
      element: {
        label: 'Filter Status',
        list: [
          {
            label: 'Banner Status', type: 'dropdown', key: 'status', list: statusList,
          },
        ],
      },
    },
  ];

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idBanner: '',
      bannerName: '',
      adsSupplier: '',
      startDate: '',
      endDate: '',
      webviewUrl: '',
      bannerImage: '',
      bannerImageRaw: '',
      bannerType: '',
      active: false,
    },
    onSubmit: async (values) => {
      let dataValue = { ...values };
      if (!deleteData) {
        dataValue = {
          ...values,
          startDate: values.startDate,
          endDate: values.endDate,
          file: selectedFile,
          bannerImage: {
            size: selectedFile.size,
            type: selectedFile.type,
            path: values.bannerImageRaw || `banner-image/${uuid.v4()}-${selectedFile.name}`,
          },
        };
      }

      dispatch(startLoading());
      let response;
      if (modalUpdateVisibility) response = await editBanner(dataValue);
      else if (deleteData) response = await deleteBanner(dataValue);
      else if (changeStatusData) response = await editStatusBanner(dataValue);
      else response = await addBanner(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        setModalFormVisibility(false);
        setModalUpdateVisibility(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setResponseMessage('');
        setSelectedFile('');
        setDeleteData('');
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    const getBannerData = async () => {
      dispatch(startLoading());
      const response = await getBanner({
        searchWord: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        sort: search.sort,
        bannerType: filter.bannerType,
        startDate: filter.startDate,
        endDate: filter.endDate,
        status: filter.status,
      });
      dispatch(stopLoading());
      setBannerList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getBannerData();
  }, [search, pagination.page, refresh, pagination.limit, filter]);

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdateVisibility || changeStatusData) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdateVisibility, changeStatusData]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search]);

  useEffect(() => {
    if (modalData) {
      const selectedType = bannerTypeList.find((data) => data.value === modalData.bannerType);
      formik.setFieldValue('idBanner', modalData.idBanner);
      formik.setFieldValue('bannerName', modalData.bannerName);
      formik.setFieldValue('bannerImage', modalData.bannerImage);
      formik.setFieldValue('bannerImageRaw', modalData.bannerImageRaw);
      formik.setFieldValue('webviewUrl', modalData.webviewUrl);
      formik.setFieldValue('active', modalData.active);
      formik.setFieldValue('bannerType', selectedType ? selectedType.value : '');
      formik.setFieldValue('startDate', modalData.startDate);
      formik.setFieldValue('endDate', modalData.endDate);
      formik.setFieldValue('adsSupplier', modalData.adsSupplier);
    }
  }, [modalData, modalUpdateVisibility]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('idBanner', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Apakah anda ingin menghapus data ini ?' });
    }
  }, [deleteData]);

  useEffect(() => {
    if (changeStatusData) {
      formik.setFieldValue('idBanner', changeStatusData.idBanner);
      formik.setFieldValue('active', !changeStatusData.active);
      setModalAlert({ visibility: true, type: 'confirm', message: changeStatusData.active ? 'Apakah anda ingin merubah ini menjadi tidak aktif ?' : 'Apakah anda ingin merubah ini menjadi aktif ?' });
    }
  }, [changeStatusData]);

  return (
    <div className={`flex flex-col w-full gap-4
          `}
    >
      <div className="flex flex-col w-full border-[#ececec] p-8 rounded-2xl shadow-box border-[1px] gap-4">
        <ModalImg
          url={modalImg.src}
          visibility={modalImg.visibility}
          handleVisibility={() => { setModalImg({ visibility: false, src: '' }); }}
          alt=""
        />
        <ModalAlert
          visibility={modalAlert.visibility}
          type={modalAlert.type}
          message={modalAlert.message}
          handleVisibility={() => {
            setModalAlert((data) => ({ ...data, visibility: false }));
            setDeleteData('');
            setChangeStatusData('');
            formik.resetForm();
          }}
          handleConfirm={formik.handleSubmit}
        />
        <ModalForm
          visibility={modalFormVisibility || modalUpdateVisibility}
          handleVisibility={() => {
            setModalFormVisibility(false);
            setModalUpdateVisibility(false);
            setSelectedFile('');
            formik.resetForm();
            setResponseMessage('');
          }}
          buttonText={modalUpdateVisibility ? 'Ubah Banner' : 'Buat Banner'}
          onSubmit={formik.handleSubmit}
          className="sm:max-w-[600px] overflow-visible flex flex-col gap-4"
          height="300px"
          message={responseMessage}
          fromCMS
        >
          <BannerForm
            formik={formik}
            isUpdate={modalUpdateVisibility}
            bannerTypeList={bannerTypeList}
            selectedFile={selectedFile}
            handleFile={handleFile}
          />
        </ModalForm>
        <Searchbar
          onChange={setSearch}
          withButton
          buttonName="Add Banner"
          noNominal
          onClick={() => { setModalFormVisibility(true); formik.resetForm(); }}
        />
        <NewFilter
          onChange={setFilter}
          filterList={filterList}
        />
        {pagination.summary > 0
          ? (
            <>
              <CardList
                type="banner"
                modalData={setModalData}
                dataList={bannerList}
                modalVisibility={setModalUpdateVisibility}
                customFunc={{ setDeleteData, setChangeStatusData }}
                imgModal={setModalImg}
              />
              <Pagination
                pagination={pagination}
                setPagination={(e) => setPagination(e)}
              />
            </>
          )
          : <NoDataView />}
      </div>
    </div>
  );
}

import { uploadGcpMultiple } from './gcp-service';
import {
  getData, postData, deleteData,
} from './request';

export const getBlacklistList = async ({
  searchWord, limit, page, order,
}) => {
  const response = await getData({
    path:
      '/admin/blocked-number'
        + `?q=${searchWord}`
        + `&limit=${limit}`
        + `&page=${page}`
        + `&order=${order}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const addBlacklist = async ({
  phoneNumber,
  phoneNumberName,
  blockReason,
  adminNote,
  files,
}) => uploadGcpMultiple({ files })
  .then((response) => {
    if (response.code === 400) {
      return { data: { errors: response.errors } };
    }
    return postData({
      path: '/admin/blocked-number',
      data: {
        phoneNumber,
        phoneNumberName,
        blockReason,
        linkProof: response,
        adminNote,
      },
      withToken: true,
    });
  }).then((postResponse) => {
    if (postResponse.errors) return { errors: postResponse.data.errors };
    return postResponse.data;
  });

export const editBlacklist = async ({
  blacklistId,
  blacklistNumber,
  blacklistDesc,
  blacklistImage,
}) => ({
  blacklistId, blacklistDesc, blacklistNumber, blacklistImage,
});

export const deleteBlacklist = async ({
  phoneNumber,
}) => {
  const response = await deleteData({
    path: `/admin/blocked-number/${phoneNumber}`,
    withToken: true,
    pathParams: [phoneNumber],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export function Carousel({ imageUrls, className, modalImg }) {
  return (
    <div className={`carousel w-full h-full ${className}`}>
      {
        imageUrls.map((imageUrl, index) => (
          <div
            key={index}
            id={`slide${index}`}
            className="carousel-item relative w-full h-full"
          >
            <div
              className="flex justify-center w-full p-5 bg-[#D9D9D9] cursor-pointer"
              onClick={() => { modalImg({ visibility: true, src: imageUrl }); }}
            >
              <img
                src={imageUrl}
                className="h-full object-contain"
                alt={`gambar-${index}`}
              />
            </div>
            {imageUrls.length !== 1
                && (
                <div className="absolute flex justify-between transform -translate-y-1/2 left-3 right-3 top-1/2">
                  <a
                    href={`#slide${index === 0 ? imageUrls.length - 1 : index - 1}`}
                    className="btn btn-circle bg-opacity-70"
                  >
                    <FaChevronLeft />
                  </a>
                  <a
                    href={`#slide${index === imageUrls.length - 1 ? 0 : index + 1}`}
                    className="btn btn-circle bg-opacity-70"
                  >
                    <FaChevronRight />
                  </a>
                </div>
                )}
          </div>
        ))
      }
    </div>
  );
}

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getToken } from '../services/axios-instance';
import { dispatchAdminLogin, getBundleData } from '../redux/dispatch';
import { startLoading, stopLoading } from '../redux/slice';

export function RequireAuth({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const { clientPublicKey } = useSelector((state) => state.app);

  useEffect(() => {
    const result = async () => {
      dispatch(startLoading());
      const response = await getToken();
      dispatch(stopLoading());

      if (response.error || response.data.code !== 200) {
        navigate('/');
      } else {
        dispatch(startLoading());
        dispatchAdminLogin(response.data.message.data.accessToken);
        getBundleData();
        dispatch(stopLoading());
        setAuth(true);
      }
    };
    if (clientPublicKey) result();
  }, [clientPublicKey]);

  if (auth) return children;
}

export function RequireNotAuth({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, setAuth] = useState(true);
  const { clientPublicKey } = useSelector((state) => state.app);

  useEffect(() => {
    const result = async () => {
      dispatch(startLoading());
      const response = await getToken();
      dispatch(stopLoading());

      if (response.error || response.data.code !== 200) {
        setAuth(false);
      } else {
        navigate('/dashboard');
        dispatchAdminLogin(response.data.message.data.accessToken);
      }
    };
    if (clientPublicKey) result();
  }, [clientPublicKey]);

  if (!auth) return children;
}

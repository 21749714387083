import * as jose from 'jose';
import { createAxiosInstance } from './axios-instance';
import { err } from '../utils/constant';
import { store } from '../redux/store';

export const decryptResponse = async (encryptedResponseData) => {
  const { clientPrivateKey, alg } = store.getState().app;
  const privateKey = await jose.importPKCS8(clientPrivateKey, alg);

  const { plaintext } = await jose.compactDecrypt(encryptedResponseData, privateKey);
  const decryptedResponseData = JSON.parse(new TextDecoder().decode(plaintext));

  return decryptedResponseData;
};

async function encryptRequest(body, path, contentType, pathParams) {
  const { alg, enc, serverPublicKey } = store.getState().app;
  const publicKey = await jose.importSPKI(serverPublicKey, alg);

  const encoder = new TextEncoder();

  let encryptedData = body;
  let encryptedPath = path;

  // encrypt body
  if (encryptedData && (!contentType || contentType === 'application/json')) {
    encryptedData = await new jose.CompactEncrypt(encoder.encode(JSON.stringify(body)))
      .setProtectedHeader({ alg, enc })
      .encrypt(publicKey);
  }

  // encrypt query params
  const splittedPath = path.split('?');
  if (splittedPath.length > 1 && splittedPath[1] !== '') {
    const stringToEncrypt = encoder.encode(JSON.stringify(splittedPath[1]));
    const encryptedQueryParam = await new jose.CompactEncrypt(stringToEncrypt)
      .setProtectedHeader({ alg, enc })
      .encrypt(publicKey);
    encryptedPath = `${splittedPath[0]}?d=${encryptedQueryParam}`;
  }

  // encrypt path params
  if (pathParams && pathParams.length > 0) {
    await Promise.all(pathParams.map(async (pathParam) => {
      const stringToEncrypt = encoder.encode(JSON.stringify(pathParam));
      const encryptedPathParam = await new jose.CompactEncrypt(stringToEncrypt)
        .setProtectedHeader({ alg, enc })
        .encrypt(publicKey);
      encryptedPath = encryptedPath.replace(pathParam, encryptedPathParam);
    }));
  }

  return { encryptedPath, encryptedData };
}

export const postData = async ({
  path, data, withToken, contentType, pathParams,
}) => {
  try {
    const { encryptedPath, encryptedData } = await encryptRequest(
      data,
      path,
      contentType,
      pathParams,
    );

    const axiosInstance = await createAxiosInstance(withToken, contentType);
    const response = await axiosInstance.post(encryptedPath, encryptedData);
    response.data = await decryptResponse(response.data);

    return response;
  } catch (error) {
    if (error.response) {
      error.response.data = await decryptResponse(error.response.data);
      return error.response;
    }
    return err;
  }
};

export const getData = async ({ path, withToken, pathParams }) => {
  try {
    const { encryptedPath } = await encryptRequest(null, path, null, pathParams);

    const axiosInstance = await createAxiosInstance(withToken);
    const response = await axiosInstance.get(encryptedPath);
    response.data = await decryptResponse(response.data);

    return response;
  } catch (error) {
    if (error.response) {
      error.response.data = await decryptResponse(error.response.data);
      return error.response;
    }
    return err;
  }
};

export const putData = async ({
  path, data, withToken, pathParams,
}) => {
  try {
    const { encryptedPath, encryptedData } = await encryptRequest(data, path, null, pathParams);
    const axiosInstance = await createAxiosInstance(withToken);
    const response = await axiosInstance.put(encryptedPath, encryptedData);
    response.data = await decryptResponse(response.data);

    return response;
  } catch (error) {
    if (error.response) {
      error.response.data = await decryptResponse(error.response.data);
      return error.response;
    }
    return err;
  }
};

export const deleteData = async ({ path, withToken, pathParams }) => {
  try {
    const { encryptedPath } = await encryptRequest(null, path, null, pathParams);
    const axiosInstance = await createAxiosInstance(withToken);
    const response = await axiosInstance.delete(encryptedPath);
    response.data = await decryptResponse(response.data);

    return response;
  } catch (error) {
    if (error.response) {
      error.response.data = await decryptResponse(error.response.data);
      return error.response;
    }
    return err;
  }
};

export const getCSRF = async () => {
  const response = await getData({
    path: '/auth/admin/csrf',
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ModalAlert } from '../../components/ModalAlert';
import {
  Input, Button, Message, OripayImg,
} from '../../components/Oripay';
import { postData } from '../../services/request';
import { startLoading, stopLoading } from '../../redux/slice';
import { dispatchAdminLogin } from '../../redux/dispatch';

export function Login() {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { username: '', password: '' },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const response = await postData({
        path: '/auth/admin/login',
        data: values,
      });
      dispatch(stopLoading());

      if (response.error) {
        setVisibility(true);
      } else {
        if (response.data.code === 200) {
          dispatchAdminLogin(response.data.message.data.accessToken);
          return navigate('/dashboard');
        }
        setMessage(response.data.errors[Object.keys(response.data.errors)[0]]);
      }
      return 0;
    },
  });

  return (
    <div className="bg-white flex items-center justify-center h-screen">
      <ModalAlert
        visibility={visibility}
        type="failed"
        message="Can't connect to server"
        handleVisibility={() => {
          if (visibility) setVisibility(false);
          else setVisibility(true);
        }}
      />
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col px-6 sm:p-4 gap-4 sm:min-w-[384px] min-w-full"
      >
        <OripayImg className="w-[220px] h-[61px] ml-[-22px]" />
        <div>
          <Input
            isError={message}
            className="w-full"
            placeholder="Masukkan Username"
            type="text"
            name="username"
            label="Username"
            onChange={formik.handleChange}
            value={formik.values.username}
          />
        </div>
        <div>
          <Input
            isError={message}
            className="w-full"
            placeholder="Masukkan Password"
            type="password"
            name="password"
            label="Password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </div>
        <Message>{message}</Message>
        <Button type="submit">Login</Button>
      </form>
    </div>
  );
}

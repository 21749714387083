import React from 'react';
import { Transition } from '@headlessui/react';

export function ModalImg({
  url,
  visibility,
  handleVisibility,
  alt,
}) {
  return (
    <Transition
      onClick={handleVisibility}
      className="flex bg-[rgba(0,0,0,0.3)] justify-center items-center z-[998] fixed inset-0 p-4"
      show={visibility}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <img
        alt={alt}
        className="max-w-full max-h-full"
        src={url}
      />
    </Transition>
  );
}

import React, { useEffect, useState } from 'react';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

export function Main({ children }) {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setSidebarToggle(false);
    } else {
      setSidebarToggle(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 720) setSidebarToggle(true);
    window.addEventListener('resize', handleResize);
  });

  return (
    <div className="flex flex-row h-screen w-screen">
      <Sidebar
        setSidebarToggle={setSidebarToggle}
        sidebarToggle={sidebarToggle}
        className="fixed top-0 left-0 bottom-0 sm:relative sm:flex"
      />
      <div
        onClick={() => { setSidebarToggle(!(window.innerWidth < 720)); }}
        className="flex flex-col flex-1"
      >
        <Topbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        {children}
      </div>
    </div>
  );
}

import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaTimesCircle } from 'react-icons/fa';
import { Transition } from '@headlessui/react';
import { Button } from './Oripay';

export function ModalAlert({
  type,
  message,
  handleConfirm,
  visibility,
  handleVisibility,
}) {
  const getIconType = (alertType) => {
    switch (alertType) {
      case 'success':
        return <BsFillCheckCircleFill className="text-[#28A745] h-32 w-32" />;
      case 'failed':
        return <FaTimesCircle className="text-[#DC3545] h-32 w-32" />;
      case 'confirm':
        return <AiFillWarning className="text-[#FF7A00] h-32 w-32" />;
      default:
        return '';
    }
  };

  return (
    <Transition
      show={!!visibility}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="flex items-center px-4 justify-center z-[999] fixed inset-0"
      onClick={handleVisibility}
    >
      <div
        className="h-screen w-screen bg-[rgba(0,0,0,0.3)] fixed"
        onClick={handleVisibility}
      />
      <Transition.Child
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        className="bg-white modal-box min-w-[100%] relative sm:min-w-[400px] p-8 rounded-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center">
          {getIconType(type)}
        </div>
        <p className="text-black py-4 text-xl text-center">{message}</p>
        {type !== 'confirm'
          ? <Button onClick={handleVisibility}>Close</Button>
          : (
            <div className="flex gap-4 w-full">
              <Button
                className="flex-1"
                color="danger"
                onClick={handleVisibility}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="flex-1"
                onClick={(e) => { handleConfirm(e); e.stopPropagation(); }}
              >
                Confirm
              </Button>
            </div>
          )}
      </Transition.Child>
    </Transition>
  );
}

import logoPolban from '../img/logo-polban.png';

export const walletDataFormatter = (data) => {
  const payment = [];
  const destination = [];
  data.forEach((item) => {
    if (item.dari_payment) {
      payment.push({ name: item.dari_payment, value: item.dari_id });
    }
    if (item.ke_payment) {
      destination.push({ name: item.ke_payment, value: item.ke_id });
    }
  });
  return [payment, destination];
};

const currencyFormat = (money) => money.toLocaleString('id-ID', {
  style: 'currency',
  currency: 'IDR',
});

export const summaryFormatter = (totalTrx, totalBayarTrx, userCount) => [
  { name: 'Total Transaksi', count: totalTrx?.totalTrx || 0, nominal: currencyFormat(totalBayarTrx?.total || 0) },
  { name: 'Transaksi Success', count: totalTrx?.successTotalTrx || 0, nominal: currencyFormat(totalBayarTrx?.success || 0) },
  { name: 'Transaksi Failed', count: totalTrx?.failedTotalTrx || 0, nominal: currencyFormat(totalBayarTrx?.failed || 0) },
  { name: 'Jumlah User', count: userCount || 0 },
];

export const categoryFormatter = (data) => {
  const category = [];
  data.forEach((item) => {
    category.push({ value: item.id_category, name: item.nama_category });
  });
  return category;
};

// export function formatDate(appliedDate) {
//   const date = new Date(appliedDate);
//   const monthNames = [
//     'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
//     'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember',
//   ];
//   return (`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`);
// }

export function formatDate(appliedDate) {
  const inputDate = new Date(appliedDate);

  // Get the day, month, and year from the Date object
  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1; // Months are zero-based, so add 1
  const year = inputDate.getFullYear();

  // Pad day and month with leading zeros if necessary
  const formattedDay = day.toString().padStart(2, '0');
  const formattedMonth = month.toString().padStart(2, '0');

  // Create the formatted date string in "dd/mm/yyyy" format
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
}

export function formatDate2(date, dateOffset = 0) {
  const dateTime = new Date(date).getTime();
  const currentDate = new Date(dateTime + (1000 * 60 * 60 * 24) * dateOffset);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return date ? `${year}-${month}-${day}` : null;
}

export function formatMoney(appliedMoneyParam) {
  const appliedMoney = parseInt(appliedMoneyParam, 10);
  const result = appliedMoney.toLocaleString(
    'id-ID',
    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 },
  );
  return !Number.isNaN(appliedMoney) ? result.replace(/\s/g, '') : null;
}

export const wordFormatter = (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export function formatFilename(fileNameParam) {
  let fileName = fileNameParam;
  if (fileName.length > 30) {
    fileName = fileName.split('');
    fileName.splice(27);
    fileName = `${fileName.join('')}...`;
  }
  return fileName;
}

export const formatModalData = (trx, isUkt, isSuccess) => {
  const originLogo = (isUkt) ? logoPolban : trx.dari_payment_logo;
  const originName = (isUkt) ? 'UKT POLBAN' : wordFormatter(trx.dari_nama_payment);
  const destinationLogo = (isUkt) ? trx.dari_payment_logo : trx.ke_wallet_logo;
  const destinationName = (isUkt) ? trx.ukt_nim : wordFormatter(trx.ke_nama_payment);
  const detailList = [
    { title: 'ID Transaksi', body: trx.id_trx_base64 },
    { title: 'Tanggal', body: formatDate(trx.tgl_trx) },
    { title: 'Nama', body: (isUkt) ? trx.ukt_nama : trx.dari_nama },
    { title: 'Nominal', body: formatMoney(trx.total_trx) },
    { title: 'Prodi', body: trx.ukt_prodi, isUkt: true },
    { title: 'Jurusan', body: trx.ukt_jurusan, isUkt: true },
  ];
  if (isSuccess) {
    detailList.push(
      { title: 'Admin', body: trx.nama_admin },
      { title: 'Tanggal Transaksi', body: formatDate(trx.tgl_trx_admin) },
    );
  }
  return {
    originLogo, originName, destinationLogo, destinationName, detailList,
  };
};

export function isActive(boolVal) {
  if (boolVal) {
    return 'Active';
  }
  return 'Non-active';
}

export function getLabel(value, list) {
  return list.find((o) => o.value === value).label;
}

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { FaPlus, FaTimesCircle } from 'react-icons/fa';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import {
  Button, Input, InputDate, DateInput, SimpleOption,
} from './Oripay';

function FilterButton({ children, className }) {
  return (
    <button
      type="button"
      className={`${className || ''} text-[#4A86E8] w-full sm:w-auto border-2 border-[#4A86E8] rounded-lg px-4 py-3`}
    >
      {children}
    </button>
  );
}

function FilterBox({ list, setFilter, value }) {
  const { payment, destination } = useSelector((state) => state.app);
  const [inputList, setInputList] = useState({
    visibility: false, title: 'Add Filter', list: [],
  });
  const [appliedFilter, setAppliedFilter] = useState(value);

  return (
    <div className="flex flex-col gap-2 min-w-[200px]">
      <div className="border-b-[1px] p-2 font-bold flex gap-2 items-center">
        <div
          onClick={() => {
            setAppliedFilter(value);
            setInputList({ visibility: false, title: 'Add Filter', list: [] });
          }}
          className={`${inputList.title === 'Add Filter' ? 'hidden' : ''} cursor-pointer`}
        >
          <BiChevronLeft size={20} />
        </div>
        <div>{inputList.title}</div>
      </div>
      {!inputList.visibility && list.map((item, index) => (
        <button
          type="button"
          key={index}
          className="flex items-center p-2"
          onClick={() => {
            setInputList({
              visibility: true, title: item.element.label, list: item.element.list,
            });
          }}
        >
          <div className="flex-1 text-start">{item.label}</div>
          <div>
            <BiChevronRight size={20} />
          </div>
        </button>
      ))}
      {inputList.visibility && (
      <div className="flex flex-col gap-2">
        {inputList.list.map((item, index) => {
          if (item.type === 'date') {
            return (
              <DateInput
                key={index}
                label={item.label}
                placeholder={item.label}
                onChange={(e) => {
                  setAppliedFilter((o) => ({ ...o, [item.key]: e.target.value }));
                }}
                value={appliedFilter[item.key]}
              />
            );
          } if (item.type === 'payment' || item.type === 'destination' || item.type === 'dropdown') {
            let dropdownList = item?.list || [];
            if (item.type === 'payment') dropdownList = payment;
            else if (item.type === 'destination') dropdownList = destination;
            return (
              <SimpleOption
                key={index}
                label={item.label}
                value={appliedFilter[item.key]}
                placeholder={item.placeholder ? item.placeholder : item.label}
                onSelect={(e) => {
                  setAppliedFilter((o) => ({ ...o, [item.key]: e.target.value }));
                }}
                list={dropdownList}
              />
            );
          }
          return (
            <Input
              key={index}
              type={item.type}
              label={item.label}
              value={appliedFilter[item.key]}
              placeholder={item.label}
              onChange={(e) => {
                setAppliedFilter((o) => ({ ...o, [item.key]: e.target.value }));
              }}
            />
          );
        })}
        <Button
          type="button"
          className="py-2"
          onClick={() => { setFilter(appliedFilter); }}
        >
          Apply Filter
        </Button>
      </div>
      )}
    </div>
  );
}

export function NewFilter({
  filterList = [
    {
      label: 'Date',
      element: {
        label: 'Date Waktu',
        list: [
          { label: 'Start Date', type: 'date', key: 'fromDate' },
          { label: 'End Date', type: 'date', key: 'toDate' },
        ],
      },
    },
    {
      label: 'Metode',
      element: {
        label: 'Filter Metode',
        list: [
          {
            label: 'From Method', type: 'payment', key: 'fromWallet', placeholder: 'Choose Metode',
          },
          {
            label: 'To Method', type: 'destination', key: 'toWallet', placeholder: 'Choose Metode',
          },
        ],
      },
    },
    {
      label: 'Nominal',
      element: {
        label: 'Filter Nominal',
        list: [
          { label: 'From Nominal', type: 'number', key: 'fromNominal' },
          { label: 'To Nominal', type: 'number', key: 'toNominal' },
        ],
      },
    },
  ],
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const { payment, destination } = useSelector((state) => state.app);

  useEffect(() => {
    const handleClose = () => {
      setIsOpen(false);
    };
    document.addEventListener('click', handleClose);
    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, []);

  function getAttribute(key, attribute) {
    for (const filter of filterList) {
      for (const item of filter.element.list) {
        if (item.key === key) {
          return item[attribute];
        }
      }
    }
    return '';
  }

  function getValue(key, value) {
    const type = getAttribute(key, 'type');
    if (type === 'payment' || type === 'destination') {
      const wallet = type === 'payment' ? payment : destination;
      return wallet.find((item) => item.value === value).name;
    }
    if (type === 'dropdown') {
      const list = getAttribute(key, 'list');
      const selected = list.find((item) => item.value === value) || {};
      return selected.name || selected.label;
    }
    return value;
  }

  useEffect(() => {
    if (onChange) {
      onChange(selectedFilter);
    }
  }, [selectedFilter]);

  return (
    <div className="flex gap-2 flex-wrap mb-6">
      {Object.keys(selectedFilter).length > 0
      && Object.keys(selectedFilter).map((item, index) => (
        <FilterButton
          key={index}
          className="cursor-default"
        >
          <div className="flex gap-3 items-center">
            <div className="flex flex-1 gap-1">
              <div>{getAttribute(item, 'label')}</div>
              <div>:</div>
              <div>{getValue(item, selectedFilter[item])}</div>
            </div>
            <div
              onClick={() => {
                const data = { ...selectedFilter };
                delete data[item];
                setSelectedFilter(data);
              }}
              className="cursor-pointer"
            >
              <FaTimesCircle size={20} />
            </div>
          </div>
        </FilterButton>
      ))}
      <div
        onClick={(e) => { e.stopPropagation(); }}
        className="relative flex w-full sm:w-auto sm:max-w-[140px]"
      >
        <Button
          className="w-full"
          onClick={() => { setIsOpen(!isOpen); }}
        >
          <div className="flex gap-3 justify-center">
            <div className="self-center"><FaPlus /></div>
            <div>Add Filter</div>
          </div>
        </Button>
        {isOpen && (
          <div
            className={`absolute bg-white border-[1px] p-2 px-4 rounded-md 
            ${Object.keys(selectedFilter).length > 0
              ? 'mt-1 top-full'
              : 'sm:ml-1 sm:left-full top-full sm:top-0 mt-1 sm:mt-0'}
            `}
          >
            <FilterBox
              list={filterList}
              setFilter={setSelectedFilter}
              value={selectedFilter}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function Filter({ onSubmit, noNominal }) {
  const [paymentList, setPaymentList] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const { payment, destination } = useSelector((state) => state.app);

  useEffect(() => {
    setPaymentList(payment);
    setDestinationList(destination);
  }, [payment, destination]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: noNominal
      ? {
        fromDate: '', toDate: '', fromWallet: '', toWallet: '',
      }
      : {
        fromDate: '', toDate: '', fromWallet: '', toWallet: '', fromNominal: '', toNominal: '',
      },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col sm:flex-row w-full sm:flex-wrap gap-3 border-[1px]
      border-[#ececec] p-6 rounded-2xl shadow-box"
    >
      <div className={`flex w-full ${noNominal ? 'bd:w-auto' : ''} 
        md:w-auto self-center font-dmsans font-bold text-base leading-5 text-blue`}
      >
        Filter Table
      </div>
      <div className="flex flex-1 gap-2 text-black">
        <InputDate
          onChange={formik.handleChange}
          name="fromDate"
        />
        <div className="self-center font-dmsans font-normal text-xs">to</div>
        <InputDate
          onChange={formik.handleChange}
          name="toDate"
        />
      </div>
      <div className="border-l-[1px] border-l-[#ececec] hidden sm:flex" />
      <div className="flex flex-1 gap-2 text-black">
        <SimpleOption
          styling="filter"
          value={formik.values.fromWallet}
          placeholder="Pilih Metode"
          name="fromWallet"
          onSelect={formik.handleChange}
          list={paymentList}
        />
        <div className="self-center font-dmsans font-normal text-xs">to</div>
        <SimpleOption
          styling="filter"
          value={formik.values.toWallet}
          placeholder="Pilih Metode"
          name="toWallet"
          onSelect={formik.handleChange}
          list={destinationList}
        />
      </div>
      <div className={`border-l-[1px] border-l-[#ececec] ${noNominal ? 'hidden' : 'bd:flex hidden'}`} />
      <div className={`flex flex-1 flex-row gap-2 text-black ${noNominal ? 'hidden' : ''}`}>
        <Input
          type="number"
          styling="filter"
          placeholder="Nominal"
          name="fromNominal"
          className=""
          value={formik.values.fromNominal}
          onChange={formik.handleChange}
        />
        <div className="self-center font-dmsans font-normal text-xs">to</div>
        <Input
          type="number"
          styling="filter"
          placeholder="Nominal"
          name="toNominal"
          className=""
          value={formik.values.toNominal}
          onChange={formik.handleChange}
        />
      </div>
      <div className={`border-l-[1px] border-l-[#ececec] bd:hidden ${noNominal ? 'hidden' : ''}`} />
      <Button
        type="submit"
        styling="filter"
        className={`md:max-w-[200px] flex-1 flex 
          bd:justify-end ${noNominal ? 'rf:justify-end' : 'md:justify-end'}`}
        noNominal={noNominal}
      >
        Apply Filter
      </Button>
    </form>
  );
}

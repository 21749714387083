import React, { useEffect, useState } from 'react';
import {
  FaSearch, FaSort, FaSortUp, FaSortDown, FaPlus,
} from 'react-icons/fa';
import { Button, Input } from './Oripay';

export function Searchbar({
  withButton, withOrder, noNominal, buttonName, onChange, onClick, className,
}) {
  const [change, setChange] = useState({
    sort: 'date_desc', searchWord: '',
  });

  useEffect(() => {
    if (withOrder) onChange(change);
    else onChange({ searchWord: change.searchWord });
  }, [change, withOrder]);

  return (
    <div className={`flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 ${className}`}>
      <OrderData
        onOrder={
                (e) => { setChange((data) => ({ ...data, sort: e })); }
            }
        noNominal={noNominal}
        className={`${!withOrder ? 'hidden' : ''}`}
      />
      <div className={`border-l-[1px] border-l-[#ececec] ${withOrder ? '' : 'hidden'}`} />
      <Search
        value={change}
        onSearch={setChange}
        className="flex-1 mt-4 sm:mt-0"
      />
      <Button
        onClick={onClick}
        className={`${!withButton ? 'hidden' : ''} mt-4 sm:mt-0`}
      >
        <div className="flex gap-3 justify-center">
          <div className="self-center"><FaPlus /></div>
          <div>{buttonName}</div>
        </div>
      </Button>
    </div>
  );
}

function Search({ className, onSearch, value }) {
  return (
    <div className={`${className} flex flex-col sm:flex-row text-black sm:border-[1px] sm:border-[dedede] rounded-md`}>
      <div className="flex bg-[#eaeaea] p-2 rounded-l-md sm:border-r-[1px] flex-1 sm:min-w-[120px] sm:max-w-[120px] rounded-r-md sm:rounded-r-none sm:border-r-[#dedede]">
        <div className="text-orange p-2 self-center"><FaSearch className="w-4 h-4" /></div>
        <div className="self-center pl-2 pr-1 font-dmsans">Search</div>
      </div>
      <Input
        styling="search"
        value={value.searchWord}
        placeholder="Search Data"
        onChange={(e) => { onSearch((data) => ({ ...data, searchWord: e.target.value })); }}
      />
    </div>
  );
}

function OrderData({ className, noNominal, onOrder }) {
  const [dateSort, setDateSort] = useState(0);
  const [nominalSort, setNominalSort] = useState(0);

  useEffect(() => {
    setDateSort(0);
  }, [nominalSort]);

  useEffect(() => {
    setNominalSort(0);
  }, [dateSort]);

  useEffect(() => {
    if (dateSort === 0 && nominalSort === 1) onOrder('nominal_asc');
    else if (dateSort === 0 && nominalSort === 2) onOrder('nominal_desc');
    else if (dateSort === 1 && nominalSort === 0) onOrder('date_asc');
    else onOrder('date_desc');
  }, [nominalSort, dateSort]);

  return (
    <div className={`${className} flex flex-wrap sm:gap-4`}>
      <div className="text-blue pr-3 sm:pr-0 font-dmsans self-center font-bold text-md">
        Order Data
      </div>
      <div className="flex gap-3">
        <Sort
          value={dateSort}
          onSort={setDateSort}
          name="Date"
        />
        <Sort
          value={nominalSort}
          onSort={setNominalSort}
          className={`${noNominal ? 'hidden' : ''}`}
          name="Nominal Transaksi"
        />
      </div>
    </div>
  );
}

function Sort({
  name, className, onSort, value,
}) {
  useEffect(() => {
    onSort(value);
  }, [value]);

  const getSortIcon = () => {
    if (value === 0) {
      return <FaSort className="h-4" />;
    }
    if (value === 1) {
      return <FaSortUp className="h-4" />;
    }
    return <FaSortDown className="h-4" />;
  };

  return (
    <div className={`flex box-border py-2 ${className}`}>
      <div
        className="flex rounded-xl cursor-pointer gap-1 p-1 px-2 pr-3 border-[1px] border-[#dedede]"
        onClick={() => {
          if (value >= 0 && value < 2) {
            onSort(value + 1);
          } else {
            onSort(0);
          }
        }}
      >
        <div className="text-orange self-center">
          {getSortIcon()}
        </div>
        <div className="self-center text-black font-dmsans">
          {name}
        </div>
      </div>
    </div>
  );
}

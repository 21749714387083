import React from 'react';
import {
  ImageFiles, Input, TextArea,
} from '../../../components/Oripay';

export function ListForm({
  formik, selectedFiles, handleFile,
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-1">
        <Input
          type="text"
          label="Nomor"
          styling="form"
          placeholder="Masukkan Nomor"
          name="phoneNumber"
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
        />
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="Nama"
          styling="form"
          placeholder="Masukkan Nama"
          name="phoneNumberName"
          onChange={formik.handleChange}
          value={formik.values.phoneNumberName}
        />
      </div>
      <div className="flex flex-1">
        <ImageFiles
          handleFile={handleFile}
          name="blacklistDesc"
          label="Bukti Blacklist"
          styling="form"
          selectedFiles={selectedFiles}
        />
      </div>
      <div className="flex flex-1">
        <TextArea
          type="text"
          label="Alasan Blacklist"
          styling="form"
          placeholder="Masukkan Alasan Blacklist"
          name="blockReason"
          onChange={formik.handleChange}
          value={formik.values.blockReason}
        />
      </div>
    </div>
  );
}

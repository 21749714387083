import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  Input, SimpleOption, TextArea, Toggle,
} from '../../../components/Oripay';

export function DestinationForm({ formik, isUpdate }) {
  const { category } = useSelector((state) => state.app);
  const [listCategory, setListCategory] = useState([]);

  useEffect(() => {
    setListCategory(category);
  }, [category]);

  return (
    <>
      <Input
        disabled={isUpdate}
        type="text"
        label="ID Destination"
        styling="form"
        placeholder="Masukkan ID Destination"
        name="destinationId"
        onChange={formik.handleChange}
        value={formik.values.destinationId}
      />
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <Input
            type="text"
            label="Nama Destination"
            styling="form"
            placeholder="Masukkan Nama Destination"
            name="destinationName"
            onChange={formik.handleChange}
            value={formik.values.destinationName}
          />
        </div>
        <div className="flex flex-1">
          <SimpleOption
            onSelect={formik.handleChange}
            name="destinationCategory"
            value={formik.values.destinationCategory}
            label="Kategori"
            placeholder="Pilih Kategori"
            list={listCategory}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <SimpleOption
            onSelect={formik.handleChange}
            name="disbursementAgent"
            value={formik.values.disbursementAgent}
            label="Disbursement Agent"
            placeholder="Pilih Disbursement Agent"
            list={listCategory}
          />
        </div>
        <div className="flex flex-1">
          <Input
            type="text"
            label="Disbursement Product Code"
            styling="form"
            placeholder="Masukkan Disbursement Product Code"
            name="disbursementProductCode"
            onChange={formik.handleChange}
            value={formik.values.disbursementProductCode}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <Input
            type="number"
            label="Minimal Transaksi"
            styling="form"
            placeholder="Masukkan Minimal Transaksi"
            name="minTrx"
            onChange={formik.handleChange}
            value={formik.values.minTrx}
          />
        </div>
        <div className="flex flex-1">
          <Input
            type="number"
            label="Maksimal Transaksi"
            styling="form"
            placeholder="Masukkan Maksimal Transaksi"
            name="maxTrx"
            onChange={formik.handleChange}
            value={formik.values.maxTrx}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <Input
            type="text"
            label="URL Logo"
            styling="form"
            placeholder="Masukkan URL Logo"
            name="paymentImgLink"
            onChange={formik.handleChange}
            value={formik.values.paymentImgLink}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <Input
            type="money"
            label="Biaya Admin"
            styling="form"
            placeholder="Biaya Admin"
            name="fee"
            onChange={formik.handleChange}
            value={formik.values.fee}
          />
        </div>
        <div className="flex flex-1">
          <Input
            type="money"
            label="Laba"
            styling="form"
            placeholder="Laba"
            name="laba"
            onChange={formik.handleChange}
            value={formik.values.laba}
          />
        </div>
        <div className="flex flex-1">
          <Input
            type="percentage"
            label="Pajak"
            styling="form"
            placeholder="Pajak"
            name="tax"
            onChange={formik.handleChange}
            value={formik.values.tax}
          />
        </div>
      </div>
      <hr />
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <Toggle
            label="Availability QRIS"
            name="availabilityQR"
            onChange={formik.handleChange}
            value={formik.values.availabilityQR}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <TextArea
            type="text"
            label="Alasan QR Not Available"
            styling="form"
            placeholder="Masukkan Alasan QR Not Available"
            name="messageQR"
            onChange={formik.handleChange}
            value={formik.values.messageQR}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <Toggle
            label="Availability Manual"
            name="availabilityManual"
            onChange={formik.handleChange}
            value={formik.values.availabilityManual}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-1">
          <TextArea
            type="text"
            label="Alasan Manual Not Available"
            styling="form"
            placeholder="Masukkan Alasan Manual Not Available"
            name="messageManual"
            onChange={formik.handleChange}
            value={formik.values.messageManual}
          />
        </div>
      </div>
    </>
  );
}

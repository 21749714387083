import React, { useEffect, useState } from 'react';
import { BoxWallet, TrxDetail } from '../../../components/Oripay';
import { formatModalData } from '../../../utils/formatter';
import { getProofAdmin, getProofUser } from '../../../services/transaction-service';

export function SuccessModal({ trx, isUkt, modalImg }) {
  const {
    originLogo, originName, destinationLogo, destinationName, detailList,
  } = formatModalData(trx, isUkt, true);

  const [imageSourceUser, setImageSourceUser] = useState('');
  const [imageSourceAdmin, setImageSourceAdmin] = useState('');

  useEffect(() => {
    async function getUserProof() {
      const response = await getProofUser({ idTrx: trx.id_trx });
      setImageSourceUser(response?.message?.message || '');
    }

    async function getAdminProof() {
      const response = await getProofAdmin({ idTrx: trx.id_trx });
      setImageSourceAdmin(response?.message?.message || '');
    }

    if (trx.id_trx) getUserProof();
    if (trx.id_trx) getAdminProof();
  }, [trx.id_trx]);

  return (
    <div className="flex items-center">
      <div className="flex flex-col flex-1 gap-14">
        <BoxWallet
          destinationLogo={destinationLogo}
          destinationName={destinationName}
          originLogo={originLogo}
          originName={originName}
          destinationNumber={trx.ke_no_telepon}
          isUkt={isUkt}
        />
        <TrxDetail
          detailList={detailList}
          isUkt={isUkt}
        />
        <div className="flex flex-row gap-4 gap-x-12">
          <div className="flex flex-col gap-4 flex-1">
            <div>Bukti Transfer User</div>
            <div
              onClick={() => { modalImg({ visibility: true, src: imageSourceUser }); }}
              className="cursor-pointer h-[287px] max-w-[179px] border-[1px] border-[#dedede]
              bg-[#dedede] rounded-lg flex justify-center items-center"
            >
              <img
                alt="user-img"
                src={imageSourceUser}
                className="h-full"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 flex-1">
            <div>Bukti Transfer Admin</div>
            <div
              onClick={() => { modalImg({ visibility: true, src: imageSourceAdmin }); }}
              className="cursor-pointer h-[287px] max-w-[179px] border-[1px] border-[#dedede]
                bg-[#dedede] rounded-lg flex justify-center items-center"
            >
              <img
                alt="user-img"
                src={imageSourceAdmin}
                className="h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Input, TextArea } from '../../../components/Oripay';

export function PemeliharaanForm({ formik, isUpdate }) {
  return (

    <div className="flex flex-col gap-4">
      <Input
        className={`${!isUpdate ? 'hidden' : ''}`}
        type="text"
        label="ID Pemeliharaan"
        styling="form"
        placeholder="Masukkan ID Pemeliharaan"
        name="pemeliharaanId"
        onChange={formik.handleChange}
        value={formik.values.pemeliharaanId}
        disabled
      />
      <div className="flex flex-1">
        <Input
          type="text"
          label="Judul Pemeliharaan"
          styling="form"
          placeholder="Masukkan Judul Pemeliharaan"
          name="pemeliharaanTitle"
          onChange={formik.handleChange}
          value={formik.values.pemeliharaanTitle}
        />
      </div>

      <hr />
      <div className="flex flex-1">
        <TextArea
          type="text"
          label="Deskripsi Pemeliharaan"
          styling="form"
          placeholder="Masukkan Judul Pemeliharaan"
          name="pemeliharaanDesc"
          onChange={formik.handleChange}
          value={formik.values.pemeliharaanDesc}
        />
      </div>
      <div className="flex flex-1">
        <Input
          type="text"
          label="URL Webview"
          styling="form"
          placeholder="Masukkan URL Web View"
          name="pemeliharaanWebview"
          onChange={formik.handleChange}
          value={formik.values.pemeliharaanWebview}
        />
      </div>
    </div>
  );
}

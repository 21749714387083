import {
  deleteData, getData, postData, putData,
} from './request';

export const addDestination = async ({
  destinationId, destinationCategory, destinationName,
  paymentImgLink, minTrx, maxTrx, availabilityQR, availabilityManual,
  messageQR, messageManual, laba, fee, tax, disbursementAgent, disbursementProductCode,
}) => {
  const response = await postData({
    path: '/admin/destinasi-bayar',
    data: {
      destinationId,
      destinationCategory,
      destinationName,
      paymentImgLink,
      minTrx,
      maxTrx,
      availabilityQR,
      availabilityManual,
      messageQR,
      messageManual,
      laba,
      fee,
      tax,
      disbursementAgent,
      disbursementProductCode,
    },
    withToken: true,
  });
  return response.data;
};

export const readDestination = async ({ word, order }) => {
  const response = await getData({
    path: `/admin/destinasi-bayar?word=${word}&order=${order}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editDestination = async ({
  destinationId, destinationCategory, destinationName,
  paymentImgLink, minTrx, maxTrx, availabilityQR, availabilityManual,
  messageQR, messageManual, laba, fee, tax, disbursementAgent, disbursementProductCode,
}) => {
  const response = await putData({
    path: `/admin/destinasi-bayar/${destinationId}`,
    data: {
      destinationId,
      destinationCategory,
      destinationName,
      paymentImgLink,
      minTrx,
      maxTrx,
      availabilityQR,
      availabilityManual,
      messageQR,
      messageManual,
      laba,
      fee,
      tax,
      disbursementAgent,
      disbursementProductCode,
    },
    withToken: true,
    pathParams: [destinationId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const deleteDestination = async ({ destinationId }) => {
  const response = await deleteData({
    path: `/admin/destinasi-bayar/${destinationId}`,
    withToken: true,
    pathParams: [destinationId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

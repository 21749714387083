/* eslint-disable no-unused-vars */
import { reqGeneralUploadLink, uploadGcp } from './gcp-service';
import {
  getData, postData, deleteData, putData,
} from './request';

export const getBanner = async ({
  searchWord, sortBy, limit, page, sort, sortValue, bannerType, startDate, endDate, status,
}) => {
  const response = await getData({
    path:
      '/admin/banner'
        + `?searchQuery=${searchWord}`
        + `&searchBy=${sortBy}`
        + `&limit=${limit}`
        + `&page=${page}`
        + `&order=${sort}`
        + `&orderValue=${sortValue}`
        + `&bannerType=${bannerType || ''}`
        + `&startDate=${startDate || ''}`
        + `&endDate=${endDate || ''}`
        + `&status=${status || ''}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const addBanner = async ({
  bannerName, adsSupplier, startDate, endDate, webviewUrl, bannerType, bannerImage, file,
}) => {
  const { size, type, path } = bannerImage;
  const response = await reqGeneralUploadLink({
    fileType: type, gcsFilePath: path, fileSize: size,
  }).then((responseReqLink) => {
    if (responseReqLink.error || responseReqLink.errors) return responseReqLink;
    return uploadGcp({ url: responseReqLink.message.message, file });
  }).then((responseUpload) => {
    if (responseUpload.error) return responseUpload;
    return postData({
      path: '/admin/banner',
      data: {
        bannerName, adsSupplier, startDate, endDate, webviewUrl, bannerImage: path, bannerType,
      },
      withToken: true,
    });
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editBanner = async ({
  idBanner, bannerName, adsSupplier, startDate, endDate, webviewUrl, bannerType, bannerImage, file,
}) => {
  // if no file is selected
  if (!file) {
    const response = await putData({
      path: `/admin/banner/${idBanner}`,
      data: {
        bannerName, adsSupplier, startDate, endDate, webviewUrl, bannerType,
      },
      withToken: true,
      pathParams: [idBanner],
    });
    if (response.error) return { error: response.error };
    return response.data;
  }

  // if file is selected
  const { size, type, path } = bannerImage;
  const response = await reqGeneralUploadLink({
    fileType: type, gcsFilePath: path, fileSize: size,
  }).then((responseReqLink) => {
    if (responseReqLink.error || responseReqLink.errors) return responseReqLink;
    return uploadGcp({ url: responseReqLink.message.message, file });
  }).then((responseUpload) => {
    if (responseUpload.error) return responseUpload;
    return putData({
      path: `/admin/banner/${idBanner}`,
      data: {
        bannerName, adsSupplier, startDate, endDate, webviewUrl, bannerType,
      },
      withToken: true,
      pathParams: [idBanner],
    });
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editStatusBanner = async ({ idBanner }) => {
  const response = await postData({
    path: `/admin/banner/${idBanner}/toggle-active`,
    withToken: true,
    pathParams: [idBanner],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const deleteBanner = async ({ idBanner }) => {
  const response = await deleteData({
    path: `/admin/banner/${idBanner}`,
    withToken: true,
    pathParams: [idBanner],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/slice';
import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';

import { TableContent } from '../../components/TableContent';
import Pagination from '../../components/Pagination';
import { NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import { ModalForm } from '../../components/ModalForm';
import { ModalAlert } from '../../components/ModalAlert';
import { NotificationForm } from './form/NotificationForm';
import {
  addNotification,
  deleteNotification,
  editNotification,
  getNotifImageLink,
  getNotification,
  getNotificationType,
} from '../../services/notification-service';
import { ModalImg } from '../../components/ModalImg';
import { NewFilter } from '../../components/Filter';
import { getCSRF } from '../../services/request';

const removeHour = async (dateString) => {
  const regex = /(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/g;
  return dateString.replace(regex, '$1');
};

export function Notification() {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.app).refreshPage;

  const [search, setSearch] = useState({
    searchWord: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    summary: 0,
  });

  const [notifTypeList, setNotifTypeList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalDetailVisibility, setModalDetailVisibility] = useState(false);
  const [modalData, setModalData] = useState();
  const [deleteData, setDeleteData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [selectedFile, setSelectedFile] = useState('');
  const handleFile = (e) => { setSelectedFile(e); };
  const [modalImg, setModalImg] = useState({
    visibility: false, src: '',
  });
  const [filter, setFilter] = useState({});
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const filterList = [
    {
      label: 'Jenis',
      element: {
        label: 'Filter Jenis',
        list: [
          {
            label: 'Jenis Notifikasi', type: 'dropdown', key: 'notificationType', list: notifTypeList,
          },
        ],
      },
    },
    {
      label: 'Date',
      element: {
        label: 'Tanggal Notifikasi',
        list: [
          { label: 'Start Date', type: 'date', key: 'startDate' },
          { label: 'End Date', type: 'date', key: 'endDate' },
        ],
      },
    },
  ];

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notifId: '',
      jenisNotif: '',
      judul: '',
      tanggalMulai: '',
      tanggalSelesai: '',
      pesan: '',
      urlWebview: '',
      imageLink: '',
    },
    onSubmit: async (values) => {
      const dataValue = { ...values, file: selectedFile };

      dispatch(startLoading());
      let response;
      if (editMode) response = await editNotification(dataValue);
      else if (deleteData) response = await deleteNotification(dataValue);
      else response = await addNotification(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        setModalFormVisibility(false);
        setModalDetailVisibility(false);
        setEditMode(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setResponseMessage('');
        setSelectedFile('');
        setDeleteData('');
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalDetailVisibility) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalDetailVisibility]);

  useEffect(() => {
    const getDataType = async () => {
      dispatch(startLoading());
      const response = await getNotificationType();
      dispatch(stopLoading());

      const data = response.message.list.map((type) => ({
        name: type.TYPE,
        value: type.TYPE,
      }));
      setNotifTypeList(data);
    };
    getDataType();
  }, []);

  useEffect(() => {
    const getData = async () => {
      dispatch(startLoading());
      const response = await getNotification({
        searchQuery: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        orderBy: sortColumn.sortBy,
        orderValue: sortColumn.sortOrder,
        notificationType: filter.notificationType || '',
        startDate: filter.startDate || '',
        endDate: filter.endDate || '',
      });

      const data = await Promise.all(response.message.list.map(async (notif) => {
        const responseGetImageLink = await getNotifImageLink({ notifId: notif.NOTIF_ID });
        let imageLink = '';
        if (responseGetImageLink.code === 200) imageLink = responseGetImageLink.message.data.url;
        return {
          notifId: notif.NOTIF_ID,
          jenisNotif: notif.TYPE,
          judul: notif.JUDUL,
          tanggalMulai: await removeHour(notif.START_TIME),
          tanggalSelesai: await removeHour(notif.END_TIME),
          pesan: notif.DESCRIPTION,
          urlWebview: notif.URL_WEBVIEW,
          imageLink,
        };
      }));
      dispatch(stopLoading());
      setNotificationList(data);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getData();
  }, [search, pagination.page, refresh, pagination.limit, filter]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    if (modalData) {
      formik.setFieldValue('notifId', modalData.notifId);
      formik.setFieldValue('jenisNotif', modalData.jenisNotif);
      formik.setFieldValue('judul', modalData.judul);
      formik.setFieldValue('tanggalMulai', modalData.tanggalMulai);
      formik.setFieldValue('tanggalSelesai', modalData.tanggalSelesai);
      formik.setFieldValue('pesan', modalData.pesan);
      formik.setFieldValue('urlWebview', modalData.urlWebview);
      formik.setFieldValue('imageLink', modalData.imageLink);
    }
  }, [modalData, editMode]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('notificationId', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Apakah anda ingin menghapus data ini ?' });
    }
  }, [deleteData]);

  return (
    <div className="flex flex-col w-full border-[#ececec] p-6 rounded-2xl shadow-box border-[1px]">
      <ModalImg
        visibility={modalImg.visibility}
        url={modalImg.src}
        handleVisibility={() => { setModalImg({ visibility: false, src: '' }); }}
        alt=""
      />
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setModalAlert((data) => ({ ...data, visibility: false }));
          setDeleteData('');
        }}
        handleConfirm={formik.handleSubmit}
      />
      <ModalForm
        visibility={modalFormVisibility || modalDetailVisibility}
        handleVisibility={() => {
          setModalFormVisibility(false);
          setEditMode(false);
          setModalDetailVisibility(false);
          formik.resetForm();
          setSelectedFile('');
          setResponseMessage('');
        }}
        // eslint-disable-next-line no-nested-ternary
        buttonText={editMode ? 'Simpan Perubahan' : modalDetailVisibility ? 'Ubah Notifikasi' : 'Tambah Notifikasi'}
        onSubmit={() => {
          if (modalDetailVisibility && !editMode) setEditMode(true);
          else formik.handleSubmit();
        }}
        cancelButtonText={editMode ? 'Batalkan' : 'Hapus Notifikasi'}
        enableCancelButton={modalDetailVisibility}
        cancelButtonOnClick={() => {
          if (editMode) setEditMode(false);
          else setDeleteData(modalData.notifId);
        }}
        className="sm:max-w-[600px] overflow-visible flex flex-col gap-4"
        height="300px"
        message={responseMessage}
        fromCMS
      >
        <NotificationForm
          formik={formik}
          isDetail={modalDetailVisibility}
          editMode={editMode}
          setEditMode={setEditMode}
          selectedFile={selectedFile}
          handleFile={handleFile}
          notifTypeList={notifTypeList}
          modalImg={setModalImg}
        />
      </ModalForm>
      <Searchbar
        className="mb-6"
        onChange={setSearch}
        withButton
        buttonName="Tambah Notifikasi"
        noNominal
        onClick={() => { setModalFormVisibility(true); formik.resetForm(); }}
      />
      <NewFilter
        onChange={setFilter}
        filterList={filterList}
      />
      {pagination.summary > 0
        ? (
          <>
            <TableContent
              type="notification"
              dataList={notificationList}
              selectedData={setModalData}
              customFunc={{ setModalDetailVisibility }}
              setSortColumn={setSortColumn}
            />
            <Pagination
              pagination={pagination}
              setPagination={(e) => setPagination(e)}
            />
          </>
        )
        : <NoDataView />}
    </div>
  );
}

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelModal } from '../../components/CancelModal';
import { NewFilter } from '../../components/Filter';
import { ModalForm } from '../../components/ModalForm';
import { BoxContent, NoDataView } from '../../components/Oripay';
import Pagination from '../../components/Pagination';
import { Searchbar } from '../../components/Searchbar';
import { CardList } from '../../components/Card';
import { unpaidRequest, updateTrx } from '../../services/update-status-trx';
import { ModalAlert } from '../../components/ModalAlert';
import { UploadForm } from '../../components/UploadForm';
import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';
import { startLoading, stopLoading } from '../../redux/slice';
import { getListTransaksi } from '../../services/transaction-service';
import { ModalImg } from '../../components/ModalImg';
import { getCSRF } from '../../services/request';

export function UnpaidTopUp() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    fromDate: '', toDate: '', fromWallet: '', toWallet: '', fromNominal: '', toNominal: '',
  });
  const [search, setSearch] = useState({
    sort: 'date_desc', searchWord: '',
  });
  const [pagination, setPagination] = useState({
    page: 1, limit: 10, summary: 0,
  });
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [modalImg, setModalImg] = useState({
    visibility: false, src: '',
  });

  const [modalData, setModalData] = useState({});
  const [responseMessage, setResponseMessage] = useState('');

  const [proofFile, setProofFile] = useState('');

  const refresh = useSelector((state) => state.app).refreshPage;

  const listStatus = [
    { name: 'Destination Not Found', value: '14' },
  ];

  const [unpaidList, setUnpaidList] = useState([]);

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (cancelModalVisible) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      const errorMessage = response.errors[Object.keys(response.errors)[0]];
      if (cancelModalVisible) setResponseMessage(errorMessage);
      else setModalAlert({ visibility: true, type: 'failed', message: errorMessage });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  useEffect(() => {
    const getUnpaidData = async () => {
      dispatch(startLoading());
      const response = await getListTransaksi({
        type: 'unpaid',
        fromDate: filter.fromDate || '',
        toDate: filter.toDate || '',
        fromWallet: filter.fromWallet || '',
        toWallet: filter.toWallet || '',
        fromNominal: filter.fromNominal || '',
        toNominal: filter.toNominal || '',
        searchWord: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        sort: search.sort,
      });
      dispatch(stopLoading());
      setUnpaidList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getUnpaidData();
  }, [search, filter, pagination.limit, pagination.page]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
  }, [pagination.limit, search]);

  useEffect(() => {
    setFilter({
      fromDate: '', toDate: '', fromWallet: '', toWallet: '', fromNominal: '', toNominal: '',
    });
    setSearch({
      sort: 'date_desc', searchWord: '',
    });
    setPagination({
      page: 1, limit: 10, summary: 0,
    });
  }, [refresh]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { idTrx: '', statusCode: '', messageAdmin: '' },
    onSubmit: async (values) => {
      dispatch(startLoading());
      let response;
      if (!modalFormVisibility) {
        response = await updateTrx({
          idTrx: values.idTrx,
          statusCode: values.statusCode,
          messageAdmin: values.statusCode,
        });
      } else {
        response = await unpaidRequest({ idTrx: values.idTrx, file: proofFile });
      }
      dispatch(stopLoading());

      if (response.error) {
        setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        const errorMessage = response.errors[Object.keys(response.errors)[0]];
        if (!modalFormVisibility) setResponseMessage(errorMessage);
        else setModalAlert({ visibility: true, type: 'failed', errorMessage });
        getTokenCSRF();
      } else {
        refreshPage();
        setModalFormVisibility(false);
        setCancelModalVisible(false);
        setModalAlert({
          visibility: true,
          type: 'success',
          message: proofFile ? 'Upload Bukti Transfer Berhasil' : response.message.message,
        });
        setProofFile('');
        formik.resetForm();
      }
      formik.setFieldValue('statusCode', '');
      formik.setFieldValue('messageAdmin', '');
    },
  });

  useEffect(() => {
    if (modalFormVisibility || cancelModalVisible) {
      getTokenCSRF();
    }
  }, [modalFormVisibility, cancelModalVisible]);

  return (
    <div className="h-full">
      <ModalImg
        visibility={modalImg.visibility}
        url={modalImg.src}
        handleVisibility={() => { setModalImg({ visibility: false, src: '' }); }}
        alt=""
      />
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => { setModalAlert((data) => ({ ...data, visibility: false })); }}
      />
      <ModalForm
        handleVisibility={() => {
          setModalFormVisibility(false);
          setProofFile('');
        }}
        className="sm:w-[840px]"
        noButton
        onSubmit={formik.handleSubmit}
        visibility={modalFormVisibility}
        disableMaxWidth
      >
        <UploadForm
          formik={formik}
          trx={modalData}
          handleUpload={setProofFile}
          modalImg={setModalImg}
        />
      </ModalForm>
      <CancelModal
        listStatus={listStatus}
        visibility={cancelModalVisible}
        message={responseMessage}
        handleVisibility={() => { setCancelModalVisible(false); setResponseMessage(''); }}
        formik={formik}
      />
      <BoxContent className="mt-6">
        <Searchbar
          className="mb-6"
          onChange={setSearch}
          withOrder
        />
        <NewFilter onChange={setFilter} />
        {pagination.summary > 0
          ? (
            <div className="flex flex-col">
              <CardList
                type="unpaid"
                dataList={unpaidList}
                modalData={setModalData}
                modalVisibility={setModalFormVisibility}
                formik={formik}
                cancelModalVisibility={setCancelModalVisible}
              />
              <Pagination
                pagination={pagination}
                setPagination={(e) => setPagination(e)}
              />
            </div>
          )
          : <NoDataView />}
      </BoxContent>
    </div>
  );
}

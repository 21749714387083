import axios from 'axios';
import { postData } from './request';

export const uploadGcp = async ({ url, file }) => {
  const config = {
    method: 'PUT',
    maxBodyLength: Infinity,
    url,
    headers: {
      'Content-Type': file.type,
    },
    withCredentials: false,
    data: file,
  };
  return axios.request(config)
    .then((response) => response)
    .catch((error) => error);
};

export const uploadGcpMultiple = async ({ files }) => postData({
  path: '/admin/blocked-number/upload-link',
  data: {
    filename: files.map((item) => item.name),
  },
  withToken: true,
}).then((response) => {
  if (response.data.code === 200) {
    Promise.all(response.data.message.list.map((item, index) => (
      uploadGcp({ url: item.proofUploadLink, file: files[index] }))));
    return response.data.message.list.map((item) => item.proofDestination);
  }
  return response.data;
}).then((response) => response);

export const reqGeneralUploadLink = async ({ fileType, gcsFilePath, fileSize }) => {
  const response = await postData({
    path: '/gcs/request-signed-url',
    data: { fileType, gcsFilePath, fileSize },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import Pagination from '../../components/Pagination';
import { ModalForm } from '../../components/ModalForm';
import { CardList } from '../../components/Card';
import { startLoading, stopLoading } from '../../redux/slice';
import {
  addPemeliharaan, changeStatusPemeliharaan, deletePemeliharaan, editPemeliharaan,
} from '../../services/pemeliharaan-service';
import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';
import { PemeliharaanForm } from './form/PemeliharaanForm';
import { ModalAlert } from '../../components/ModalAlert';
import { getCSRF, getData } from '../../services/request';
import { TableContent } from '../../components/TableContent';
import { NewFilter } from '../../components/Filter';
import {
  statusList,
} from '../../utils/constant';

export function Pemeliharaan() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    searchWord: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    summary: 0,
  });
  const [pemeliharaanList, setPemeliharaanList] = useState([]);
  const [activePemeliharaan, setActivePemeliharaan] = useState([]);
  const refresh = useSelector((state) => state.app).refreshPage;
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdateVisibility, setModalUpdateVisibility] = useState(false);
  const [modalData, setModalData] = useState();
  const [deleteData, setDeleteData] = useState('');
  const [changeStatusData, setChangeStatusData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [filter, setFilter] = useState({});
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const filterList = [
    {
      label: 'Status',
      element: {
        label: 'Status',
        list: [
          {
            label: 'Pilih Status', type: 'dropdown', key: 'statusPemeliharaan', list: statusList,
          },
        ],
      },
    },
  ];

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pemeliharaanId: '',
      pemeliharaanTitle: '',
      pemeliharaanDesc: '',
      pemeliharaanWebview: '',
      pemeliharaanStatus: '',
    },
    onSubmit: async (values) => {
      const availability = values.availability === '' ? '' : values.availability === 'true';
      const dataValue = { ...values, availability };
      dispatch(startLoading());
      let response;
      if (modalUpdateVisibility) response = await editPemeliharaan(dataValue);
      else if (deleteData) response = await deletePemeliharaan(dataValue);
      else if (changeStatusData) response = await changeStatusPemeliharaan(dataValue);
      else response = await addPemeliharaan(dataValue);
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        setModalFormVisibility(false);
        setModalUpdateVisibility(false);
        if (changeStatusData || deleteData) setActivePemeliharaan([]);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setResponseMessage('');
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdateVisibility || changeStatusData) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdateVisibility, changeStatusData]);

  useEffect(() => {
    const getPemeliharaanData = async () => {
      dispatch(startLoading());
      const response = (await getData(
        {
          path: '/maintenance/messages'
          + `?keyword=${search.searchWord}`
          + `&limit=${pagination.limit}`
          + `&page=${pagination.page}`
          + `&order=${sortColumn.sortBy}_${sortColumn.sortOrder}`
          + `&activeStatus=${filter.statusPemeliharaan ? filter.statusPemeliharaan === 'active' : ''}`,
          withToken: true,
        },
      )).data;
      dispatch(stopLoading());

      if (
        response.message.list
        && response.message.list.length > 0
        && response.message.list[0].ACTIVE
      ) {
        setActivePemeliharaan([response.message.list[0]]);
      }

      setPemeliharaanList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getPemeliharaanData();
  }, [search, pagination.page, refresh, pagination.limit, filter]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    if (modalData) {
      formik.setFieldValue('pemeliharaanId', modalData.WARNING_ID);
      formik.setFieldValue('pemeliharaanTitle', modalData.MESSAGE);
      formik.setFieldValue('pemeliharaanDesc', modalData.DESCRIPTION);
      formik.setFieldValue('pemeliharaanWebview', modalData.WEBVIEW_URL);
      formik.setFieldValue('pemeliharaanStatus', modalData.ACTIVE);
    }
  }, [modalData, modalUpdateVisibility]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('pemeliharaanId', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Apakah anda ingin menghapus data ini ?' });
    }
  }, [deleteData]);

  useEffect(() => {
    if (changeStatusData) {
      formik.setFieldValue('pemeliharaanId', changeStatusData.WARNING_ID);
      formik.setFieldValue('pemeliharaanStatus', !changeStatusData.ACTIVE);
      setModalAlert({ visibility: true, type: 'confirm', message: changeStatusData.ACTIVE ? 'Apakah anda ingin merubah ini menjadi tidak aktif ?' : 'Apakah anda ingin merubah ini menjadi aktif ?' });
    }
  }, [changeStatusData]);

  return (
    <div className={`flex flex-col w-full gap-4
          `}
    >
      <div className={`flex flex-col w-full border-[#ececec] p-8 rounded-2xl shadow-box border-[1px] gap-4
          ${activePemeliharaan.length === 0 ? 'hidden' : ''}`}
      >
        <b>Pemeliharaan yang sedang aktif</b>
        <hr />
        <CardList
          type="pemeliharaan"
          modalData={setModalData}
          dataList={activePemeliharaan}
          modalVisibility={setModalUpdateVisibility}
          customFunc={{ setDeleteData, setChangeStatusData }}
        />
      </div>
      <div className="flex flex-col w-full border-[#ececec] p-8 rounded-2xl shadow-box border-[1px] gap-4">
        <ModalAlert
          visibility={modalAlert.visibility}
          type={modalAlert.type}
          message={modalAlert.message}
          handleVisibility={() => {
            setModalAlert((data) => ({ ...data, visibility: false }));
            setDeleteData('');
            setChangeStatusData('');
            formik.resetForm();
          }}
          handleConfirm={formik.handleSubmit}
        />
        <ModalForm
          visibility={modalFormVisibility || modalUpdateVisibility}
          handleVisibility={() => {
            setModalFormVisibility(false);
            setModalUpdateVisibility(false);
            formik.resetForm();
            setResponseMessage('');
          }}
          buttonText={modalUpdateVisibility ? 'Ubah Pemeliharaan' : 'Buat Pemeliharaan'}
          onSubmit={formik.handleSubmit}
          className="sm:max-w-[600px]"
          height="300px"
          message={responseMessage}
        >
          <PemeliharaanForm
            formik={formik}
            isUpdate={modalUpdateVisibility}
          />
        </ModalForm>
        <Searchbar
          onChange={setSearch}
          withButton
          buttonName="Add Pemeliharaan"
          noNominal
          onClick={() => { setModalFormVisibility(true); formik.resetForm(); }}
        />
        <NewFilter
          onChange={setFilter}
          filterList={filterList}
        />
        {pagination.summary > 0
          ? (
            <>
              <TableContent
                type="pemeliharaan"
                dataList={pemeliharaanList}
                modalUpdate={setModalUpdateVisibility}
                selectedData={setModalData}
                deleteData={setDeleteData}
                customFunc={{ setChangeStatusData }}
                setSortColumn={setSortColumn}
              />
              <Pagination
                pagination={pagination}
                setPagination={(e) => setPagination(e)}
              />
            </>
          )
          : <NoDataView />}
      </div>
    </div>
  );
}

import {
  deleteData, getData, postData, putData,
} from './request';

export const addCategory = async ({ categoryId, categoryName, categoryStatus }) => {
  const response = await postData({
    path: '/admin/category',
    data: { categoryId, categoryName, categoryStatus },
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const readCategory = async ({ word, order }) => {
  const response = await getData({
    path: `/admin/category?word=${word}&order=${order}`,
    withToken: true,
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const editCategory = async ({ categoryId, categoryName, categoryStatus }) => {
  const response = await putData({
    path: `/admin/category/${categoryId}`,
    data: { categoryName, categoryStatus },
    withToken: true,
    pathParams: [categoryId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

export const deleteCategory = async ({ categoryId }) => {
  const response = await deleteData({
    path: `/admin/category/${categoryId}`,
    withToken: true,
    pathParams: [categoryId],
  });
  if (response.error) return { error: response.error };
  return response.data;
};

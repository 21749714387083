import { FaExchangeAlt, FaChartBar } from 'react-icons/fa';
import { GiTakeMyMoney } from 'react-icons/gi';
import Chart from 'react-apexcharts';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, DateInput } from '../../components/Oripay';
import { ModalAlert } from '../../components/ModalAlert';
import { summaryFormatter } from '../../utils/formatter';
import { startLoading, stopLoading } from '../../redux/slice';

function truncate(str, n) {
  return (str.length > n) ? `${str.slice(0, n - 1)}...` : str;
}

function formatDateToDMMM(date) {
  const dateObj = new Date(date);

  // Define arrays for month names
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr',
    'May', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec',
  ];

  // Get the day and month components from the inputDate
  const day = dateObj.getUTCDate();
  const monthIndex = dateObj.getUTCMonth();

  // Format the date as "d MMM"
  const formattedDate = `${day} ${months[monthIndex]}`;

  return formattedDate;
}

function formatDateToDMMMYYYY(date) {
  const dateObj = new Date(date);

  // Define arrays for month names
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December',
  ];

  // Get the day and month and year components from the inputDate
  const day = dateObj.getUTCDate();
  const monthIndex = dateObj.getUTCMonth();
  const year = dateObj.getUTCFullYear();

  // Format the date as "d MMM YYYY"
  const formattedDate = `${day} ${months[monthIndex]} ${year}`;

  return formattedDate;
}

function formatMoney(value) {
  const dividers = [10 ** 9, 10 ** 6, 10 ** 3];
  const suffixes = ['milyar', 'juta', 'ribu'];

  const castedValue = parseInt(value, 10);
  for (let i = 0; i < dividers.length; i += 1) {
    if (castedValue >= dividers[i]) {
      return `${Math.round(((castedValue / dividers[i]) + Number.EPSILON) * 10) / 10} ${suffixes[i]}`;
    }
  }
  return castedValue;
}

const SUMMARY_VALUE = [
  {
    name: 'Total Transaksi',
    count: 0,
    nominal: 'Rp 0,00',
  },
  {
    name: 'Transaksi Success',
    count: 0,
    nominal: 'Rp 0,00',
  },
  {
    name: 'Transaksi Failed',
    count: 0,
    nominal: 'Rp 0,00',
  },
  {
    name: 'Jumlah User',
    count: 6923,
  },
];

const DEPOSIT_BALANCE_LIST = [
  {
    name: 'DANA',
    balance: 12822,
  },
  {
    name: 'BILLFAZZ',
    balance: 2211.22,
  },
  {
    name: 'IAK',
    balance: 61123,
  },
];

function generateDataNominal(from, to) {
  const timezoneOffset = new Date().getTimezoneOffset();
  const fromTimestamp = new Date(from).setHours(-timezoneOffset / 60, 0, 0, 0);
  const toTimestamp = new Date(to).setHours(-timezoneOffset / 60, 0, 0, 0);

  const categories = [];
  const data = [];

  const oneDay = 24 * 60 * 60 * 1000;
  for (let category = fromTimestamp; category <= toTimestamp; category += oneDay) {
    categories.push(new Date(category).toISOString());
    data.push(0);
  }
  return { categories, data };
}

function generateDataTotal(from, to) {
  const timezoneOffset = new Date().getTimezoneOffset();
  const fromTimestamp = new Date(from).setHours(-timezoneOffset / 60, 0, 0, 0);
  const toTimestamp = new Date(to).setHours(-timezoneOffset / 60, 0, 0, 0);

  const categories = [];
  const data = [];
  const user = [];

  const oneDay = 24 * 60 * 60 * 1000;
  for (let category = fromTimestamp; category <= toTimestamp; category += oneDay) {
    categories.push(new Date(category).toISOString());
    data.push(0);
    user.push(0);
  }
  return { categories, data, user };
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(); // Resolve without any value after sleep duration
    }, ms);
  });
}

export function Dashboard() {
  const dispatch = useDispatch();
  const [summaryValue, setSummaryValue] = useState(summaryFormatter({}, {}, 0));
  const [depositBalanceList, setDepositBalanceList] = useState([]);
  const [dateFilter, setDateFilter] = useState({ from: Date.now(), to: Date.now() });
  const [graphValueTotal, setGraphValueTotal] = useState({
    xAxis: [], yAxis: [],
  });
  const [graphValueNominal, setGraphValueNominal] = useState({
    xAxis: [], yAxis: [],
  });
  const [errorModal, setErrorModal] = useState(false);
  const { refreshPage } = useSelector((state) => state.app);

  useEffect(() => {
    const getData = async () => {
      dispatch(startLoading());
      await sleep(1500);
      dispatch(stopLoading());

      setSummaryValue(SUMMARY_VALUE);
      setDepositBalanceList(DEPOSIT_BALANCE_LIST);
      setGraphValueNominal(generateDataNominal(dateFilter.from, dateFilter.to));
      setGraphValueTotal(generateDataTotal(dateFilter.from, dateFilter.to));
    };

    getData();
  }, [refreshPage, dateFilter]);

  return (
    <>
      <ModalAlert
        visibility={errorModal}
        type="failed"
        message="Can't connect to server"
        handleVisibility={() => { setErrorModal(false); }}
      />
      <TransactionSummary
        list={summaryValue}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      <DepositBalance list={depositBalanceList} />
      <GraphContent
        nominal={graphValueNominal}
        total={graphValueTotal}
      />
    </>
  );
}

function TransactionSummary({ list, dateFilter, setDateFilter }) {
  return (
    <BoxContent
      className="gap-4"
      padding="px-6 pt-6 pb-4"
    >
      <div className="flex flex-row gap-4 w-full justify-between">
        <Title icon={<FaExchangeAlt className="w-6 h-6" />}>Log Transaksi</Title>
        <div className="flex flex-row gap-4">
          <div className="flex gap-2 items-center">
            <div className="font-bold">From :</div>
            <div className="w-[12rem]">
              <DateInput
                key="filter-date-trx-summary"
                placeholder="current date"
                onChange={(e) => {
                  setDateFilter((prev) => ({ ...prev, from: e.target.value }));
                }}
                value={dateFilter.from}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="font-bold">To :</div>
            <div className="w-[12rem]">
              <DateInput
                key="filter-date-trx-summary"
                placeholder="current date"
                onChange={(e) => {
                  setDateFilter((prev) => ({ ...prev, to: e.target.value }));
                }}
                value={dateFilter.to}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 overflow-auto gap-6">
          {list?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col text-black bg-[#F6B26B] rounded-2xl px-[20px] p-4 gap-4"
            >
              <div className="font-dmsans font-normal text-2xl">{item?.name}</div>
              <div className="font-dmsans font-bold text-6xl">{truncate(item?.count.toLocaleString('id') || '0', 14)}</div>
              {
                item?.nominal
                  ? <div className="font-dmsans font-bold text-2xl">{item?.nominal}</div>
                  : (
                    <>
                    </>
                  )
              }
            </div>
          ))}
        </div>
      </div>
    </BoxContent>
  );
}

function DepositBalance({ list }) {
  const currencyFormat = (money) => money.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });

  return (
    <BoxContent
      className="gap-4"
      padding="px-6 pt-6 pb-4 mt-4"
    >
      <div className="flex flex-row gap-4 w-full justify-between">
        <Title icon={<GiTakeMyMoney className="w-8 h-8" />}>Saldo Kas</Title>
      </div>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 overflow-auto gap-6">
          {list?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col text-black bg-[#F6B26B] rounded-2xl px-[20px] p-4 gap-4"
            >
              <div className="font-dmsans font-normal text-2xl">{item?.name}</div>
              <div className="font-dmsans font-bold text-3xl">{currencyFormat(item?.balance || 0)}</div>
            </div>
          ))}
        </div>
      </div>
    </BoxContent>
  );
}

function GraphContent({ nominal, total }) {
  return (
    <div className="flex flex-col flex-wrap mt-6 w-full border-t-[1px] broder-[#dedede] pt-6 sm:p-6 gap-6">
      <div className="flex-1 flex flex-col">
        <Title icon={<FaChartBar className="w-6 h-6" />}>Nominal Transaksi</Title>
        <GraphNominal value={nominal} />
      </div>
      <div className="flex-1 flex flex-col">
        <Title icon={<FaChartBar className="w-6 h-6" />}>Jumlah Transaksi</Title>
        <GraphTotal value={total} />
      </div>
    </div>
  );
}

function Title({ icon, children }) {
  return (
    <div className="flex gap-4 mb-4">
      <div className="text-orange self-center">{icon}</div>
      <div className="text-blue text-2xl font-dmsans font-bold">{children}</div>
    </div>
  );
}

/** @type {import('apexcharts').ApexOptions} */
const chartOptions = {
  grid: {
    show: true,
    borderColor: '#33333333',
    strokeDashArray: 1,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    },
  },
  markers: {
    size: 5,
  },
};

function GraphNominal({ value }) {
  /** @type {import('apexcharts').ApexOptions} */
  const additionalChartOptions = {
    dataLabels: {
      formatter: (val) => formatMoney(val),
    },
    xaxis: {
      categories: value?.categories || [],
      labels: {
        formatter: (val) => formatDateToDMMM(val), // format tanggal D MMM
      },
    },
    yaxis: [{
      labels: {
        formatter: (val) => formatMoney(val),
      },
    }],
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
      x: {
        formatter: (val) => (
          formatDateToDMMMYYYY(val) // format tanggal D MMM YYYY
        ),
      },
    },
  };

  /** @type {ApexAxisChartSeries} */
  const chartSeries = [{
    name: 'Jumlah Nominal',
    data: value?.data || [],
  }];

  return (
    <div className="flex justify-center">
      <div className="w-10/12">
        <Chart
          options={{ ...chartOptions, ...additionalChartOptions }}
          series={chartSeries}
          type="bar"
          width="100%"
          height="auto"
        />
      </div>
    </div>
  );
}

function GraphTotal({ value }) {
  /** @type {import('apexcharts').ApexOptions} */
  const additionalChartOptions = {
    xaxis: {
      categories: value?.categories || [],
      labels: {
        formatter: (val) => formatDateToDMMM(val), // format tanggal D MMM
      },
    },
    yaxis: [{
      labels: {
        formatter: (val) => val.toFixed(0), // format supaya integer
      },
    }],
    tooltip: {
      x: {
        formatter: (val) => (
          formatDateToDMMMYYYY(value?.categories[val - 1]) // format tanggal D MMM YYYY
        ),
      },
    },
  };

  /** @type {ApexAxisChartSeries} */
  const chartSeries = [{
    name: 'Jumlah Transaksi',
    data: value?.data || [],
  }, {
    name: 'Jumlah User Transaksi',
    data: value?.user || [],
  }];

  return (
    <div className="flex justify-center">
      <div className="w-10/12">
        <Chart
          options={{ ...chartOptions, ...additionalChartOptions }}
          series={chartSeries}
          type="line"
          width="100%"
          height="auto"
        />
      </div>
    </div>
  );
}

import React from 'react';
import { ModalForm } from './ModalForm';
import { Input, SimpleOption } from './Oripay';

export function CancelModal({
  visibility, handleVisibility, formik, listStatus, message,
}) {
  return (
    <ModalForm
      zIndex="z-[996]"
      message={message}
      visibility={visibility}
      handleVisibility={handleVisibility}
      buttonText="Submit Cancellation"
      buttonColor="danger"
      onSubmit={formik.handleSubmit}
      className="sm:w-[500px]"
    >
      <SimpleOption
        label="Reasoning"
        list={listStatus}
        value={formik.values.statusCode}
        placeholder="Masukkan Reasoning"
        name="statusCode"
        onSelect={formik.handleChange}
      />
      <Input
        type="text"
        value={formik.values.messageAdmin}
        styling="form"
        placeholder="Masukkan Notes"
        label="Notes"
        name="messageAdmin"
        onChange={formik.handleChange}
      />
    </ModalForm>
  );
}

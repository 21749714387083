import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/slice';
import { dispatchSetCSRF, refreshPage } from '../../redux/dispatch';

import { ListForm } from './form/ListForm';
import { TableContent } from '../../components/TableContent';
import Pagination from '../../components/Pagination';
import { NoDataView } from '../../components/Oripay';
import { Searchbar } from '../../components/Searchbar';
import { ModalForm } from '../../components/ModalForm';
import { ModalAlert } from '../../components/ModalAlert';
import {
  addBlacklist, deleteBlacklist, editBlacklist, getBlacklistList,
} from '../../services/blacklist-service';
import { DetailForm } from './form/DetailForm';
import { ModalImg } from '../../components/ModalImg';
import { getCSRF } from '../../services/request';

export function BlacklistList() {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.app).refreshPage;

  const [search, setSearch] = useState({
    searchWord: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    summary: 0,
  });

  const [blacklistList, setBlacklistList] = useState([]);
  const [modalFormVisibility, setModalFormVisibility] = useState(false);
  const [modalUpdateVisibility, setModalUpdateVisibility] = useState(false);
  const [modalDetailVisibility, setModalDetailVisibility] = useState(false);
  const [modalData, setModalData] = useState();
  const [deleteData, setDeleteData] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [modalAlert, setModalAlert] = useState({ visibility: false, type: '', message: '' });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [detailIndex, setDetailIndex] = useState(0);
  const [modalImg, setModalImg] = useState({ visibility: false, src: '' });
  const [sortColumn, setSortColumn] = useState({ sortBy: '', sortOrder: '' });

  const handleFile = (e) => {
    setSelectedFiles(e);
  };

  const getTokenCSRF = async () => {
    dispatch(startLoading());
    const response = await getCSRF();
    dispatch(stopLoading());

    if (response.error) {
      if (!deleteData) setResponseMessage(response.error);
      else setModalAlert({ visibility: true, type: 'failed', message: response.error });
    } else if (response.code !== 200) {
      if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
      else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
    } else {
      dispatchSetCSRF(response.message.data.csrfToken);
    }
  };

  useEffect(() => {
    if (deleteData || modalFormVisibility || modalUpdateVisibility) {
      getTokenCSRF();
    }
  }, [deleteData, modalFormVisibility, modalUpdateVisibility]);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      blacklistId: '',
      phoneNumber: '',
      phoneNumberName: '',
      blockReason: '',
      linkProof: '',
      adminNote: 'Blocked by admin',
    },
    onSubmit: async (values) => {
      const dataValue = { ...values };
      dispatch(startLoading());
      let response;
      if (modalUpdateVisibility) response = await editBlacklist(dataValue);
      else if (deleteData) response = await deleteBlacklist(dataValue);
      else response = await addBlacklist({ ...dataValue, files: selectedFiles });
      dispatch(stopLoading());

      if (response.error) {
        if (!deleteData) setResponseMessage(response.error);
        else setModalAlert({ visibility: true, type: 'failed', message: response.error });
      } else if (response.code !== 200) {
        if (!deleteData) setResponseMessage(response.errors[Object.keys(response.errors)[0]]);
        else setModalAlert({ visibility: true, type: 'failed', message: response.errors[Object.keys(response.errors)[0]] });
        getTokenCSRF();
      } else {
        refreshPage();
        setModalFormVisibility(false);
        setModalUpdateVisibility(false);
        setModalAlert({ visibility: true, type: 'success', message: response.message.message });
        setResponseMessage('');
        setSelectedFiles([]);
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      dispatch(startLoading());
      const response = await getBlacklistList({
        searchWord: search.searchWord,
        limit: pagination.limit,
        page: pagination.page,
        order: `${sortColumn.sortBy}_${sortColumn.sortOrder}`,
      });
      dispatch(stopLoading());
      setBlacklistList(response?.message?.list || []);
      setPagination((prev) => ({
        ...prev,
        summary: response?.message?.summary || 0,
      }));
    };
    getData();
  }, [search, pagination.page, refresh, pagination.limit]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
    refreshPage();
  }, [pagination.limit, search, sortColumn.sortBy, sortColumn.sortOrder]);

  useEffect(() => {
    setDetailIndex(0);
    if (modalData) {
      formik.setFieldValue('blacklistId', modalData.reportedId);
      formik.setFieldValue('blacklistNumber', modalData.number);
      formik.setFieldValue('blacklistDesc', modalData.desc);
      formik.setFieldValue('blacklistName', modalData.name);
    }
  }, [modalData, modalUpdateVisibility, modalDetailVisibility]);

  useEffect(() => {
    if (deleteData) {
      formik.setFieldValue('phoneNumber', deleteData);
      setModalAlert({ visibility: true, type: 'confirm', message: 'Apakah anda ingin menghapus data ini ?' });
    }
  }, [deleteData]);

  return (
    <div className="flex flex-col w-full border-[#ececec] p-8 rounded-2xl shadow-box border-[1px] gap-4">
      <ModalImg
        visibility={modalImg.visibility}
        url={modalImg.src}
        handleVisibility={() => { setModalImg({ visibility: false, src: '' }); }}
        alt=""
      />
      <ModalAlert
        visibility={modalAlert.visibility}
        type={modalAlert.type}
        message={modalAlert.message}
        handleVisibility={() => {
          setModalAlert((data) => ({ ...data, visibility: false }));
          setDeleteData('');
          formik.resetForm();
        }}
        handleConfirm={formik.handleSubmit}
      />
      <ModalForm
        visibility={modalDetailVisibility}
        buttonColor="info"
        handleVisibility={() => {
          setModalDetailVisibility(false);
        }}
        className="sm:max-w-[600px] md:max-w-[1000px] flex flex-col"
        onSubmit={() => {
          setDetailIndex((prev) => prev - 1);
        }}
        cancelButtonOnClick={() => {
          setDetailIndex((prev) => prev + 1);
        }}
        buttonText={(
          <div className="flex flex-row gap-2 text-white justify-center items-center">
            Previous Report
          </div>
        )}
        cancelButtonText={(
          <div className="flex flex-row gap-2 text-white justify-center items-center">
            Next Report
          </div>
        )}
        cancelButtonColor="bg-blue"
        enableButton={detailIndex !== 0}
        enableCancelButton={modalData?.details.length !== detailIndex + 1
          && modalData?.details.length !== 1}
        specialType
      >
        <DetailForm
          detail={modalData ? { phoneNumber: modalData.phoneNumber, ...modalData?.details[detailIndex] } : ''}
          fromList
          modalImg={setModalImg}
        />
      </ModalForm>
      <ModalForm
        visibility={modalFormVisibility || modalUpdateVisibility}
        handleVisibility={() => {
          setModalFormVisibility(false);
          setModalUpdateVisibility(false);
          formik.resetForm();
          setResponseMessage('');
          setSelectedFiles([]);
        }}
        buttonText={modalUpdateVisibility ? 'Ubah Blacklist' : 'Tambah Blacklist'}
        onSubmit={formik.handleSubmit}
        className="sm:max-w-[600px]"
        height="300px"
        message={responseMessage}
      >
        <ListForm
          formik={formik}
          isUpdate={modalUpdateVisibility}
          selectedFiles={selectedFiles}
          handleFile={handleFile}
        />
      </ModalForm>
      <Searchbar
        onChange={setSearch}
        withButton
        buttonName="Tambah Blacklist"
        noNominal
        onClick={() => { setModalFormVisibility(true); formik.resetForm(); }}
      />
      {pagination.summary > 0
        ? (
          <>
            <TableContent
              type="blacklistList"
              dataList={blacklistList}
              modalUpdate={setModalUpdateVisibility}
              selectedData={setModalData}
              deleteData={setDeleteData}
              customFunc={{ setModalDetailVisibility }}
              setSortColumn={setSortColumn}
            />
            <Pagination
              pagination={pagination}
              setPagination={(e) => setPagination(e)}
            />
          </>
        )
        : <NoDataView />}
    </div>
  );
}

import React from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { formatDate, formatMoney } from '../utils/formatter';
import { Button } from './Oripay';
import { getProofUser } from '../services/transaction-service';

function Card(props) {
  const {
    type, data, modalData, modalVisibility, formik, cancelModalVisibility, proofUser, imgModal,
    customFunc,
  } = props;

  async function getUserProof() {
    const response = await getProofUser({ idTrx: data.id_trx });
    return response?.message?.message || '';
  }

  const cardTopBorder = () => {
    let borderColor = '';
    if (type?.includes('unverified') || type?.includes('unpaid') || type?.includes('refund')) {
      switch (data.ke_nama_payment) {
        case 'GOPAY': borderColor = '#28A745'; break;
        case 'SHOPEEPAY': borderColor = '#FF7A00'; break;
        case 'DANA': borderColor = '#379FFF'; break;
        case 'OVO': borderColor = '#50005E'; break;
        default: borderColor = '#000000'; break;
      }
      return { borderTopWidth: '7px', borderTopColor: borderColor };
    }
    if (type?.includes('pemeliharaan') || type?.includes('banner')) {
      return { };
    }
    return '';
  };

  const cardHeader = () => {
    if (type?.includes('unverified') || type?.includes('unpaid') || type?.includes('refund')) {
      return (
        <div className="flex flex-row justify-between">
          <div className="flex flex-row justify-items-start grow">
            <div>{data.id_trx_base64}</div>
            <div className="border-[#00000033] border-l-[1px] mx-2" />
            <div>{data.dari_nama}</div>
          </div>
          <div className="flex flex-row">
            <div>{formatDate(data.tgl_trx)}</div>
          </div>
        </div>
      );
    }
    if (type?.includes('pemeliharaan')) {
      return (
        <div className="flex flex-row justify-between">
          <div className="flex flex-row justify-items-start grow ">
            <div className="font-bold self-center">{data.WARNING_ID}</div>
          </div>
          {
            !data.ACTIVE
              ? (
                <button
                  type="button"
                  className="p-2 bg-white border-[#1B692D] border-2 rounded-md text-[#1B692D]"
                  onClick={() => {
                    customFunc.setChangeStatusData(data);
                  }}
                >
                  Set Active
                </button>
              )
              : (
                <button
                  type="button"
                  className="p-2 bg-[#9CE7AD] border-[#1B692D] border-2 rounded-md text-[#1B692D]"
                  onClick={() => {
                    customFunc.setChangeStatusData(data);
                  }}
                >
                  Active
                </button>
              )
          }
        </div>
      );
    }
    if (type?.includes('banner')) {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex justify-start gap-2">
            {data.bannerType === 'popup'
            && <div className="bg-indigo-600 text-white rounded-lg px-2 py-0">Pop Up</div>}
            {data.bannerType === 'carousel'
            && <div className="bg-emerald-500 text-white rounded-lg px-2 py-0">Carousel</div>}
            {data.status === 'ENDED'
            && <div className="bg-red-600 text-white rounded-lg px-2 py-0">Ended</div>}
            {data.status === 'UNSTARTED'
            && <div className="bg-yellow-500 text-white rounded-lg px-2 py-0">Not Started</div>}
          </div>
          <hr />
          <div className="flex flex-row justify-between">
            <div className="flex flex-row justify-items-start grow gap-2">
              <div className="font-bold self-center pr-2 border-r-2">{data.idBanner}</div>
              <div className="font-bold self-center">{data.bannerName}</div>
            </div>
            {
            !data.active
              ? (
                <button
                  type="button"
                  className="p-2 bg-white border-[#1B692D] border-2 rounded-md text-[#1B692D]"
                  onClick={() => {
                    customFunc.setChangeStatusData(data);
                  }}
                >
                  Set Active
                </button>
              )
              : (
                <button
                  type="button"
                  className="p-2 bg-[#9CE7AD] border-[#1B692D] border-2 rounded-md text-[#1B692D]"
                  onClick={() => {
                    customFunc.setChangeStatusData(data);
                  }}
                >
                  Active
                </button>
              )
            }
          </div>
        </div>
      );
    }
    return (<div hidden />);
  };

  const getProsesComponent = () => {
    if (type?.includes('unverified')) {
      return (
        <div className="flex flex-row">
          <Button
            onClick={() => {
              modalData(data); modalVisibility(true);
              formik.setFieldValue('idTrx', data.id_trx);
            }}
            className="w-full"
            styling="filter"
            autoFill
          >
            Verifikasi
          </Button>
        </div>
      );
    }
    if (type?.includes('unpaid')) {
      return (
        <div className="flex flex-row gap-1 flex-1 min-w-[300px]">
          <Button
            onClick={() => {
              modalData(data); modalVisibility(true); formik.setFieldValue('idTrx', data.id_trx);
            }}
            className="flex w-full"
            autoFill
            styling="filter"
          >
            Unggah Bukti
          </Button>
          <Button
            onClick={() => { formik.setFieldValue('idTrx', data.id_trx); cancelModalVisibility(true); }}
            color="danger"
            autoFill
            className="flex w-full"
            styling="filter"
          >
            Cancel Transaksi
          </Button>
        </div>
      );
    }
    if (type?.includes('refund')) {
      return (
        <div className="flex flex-row gap-1 min-w-[300px]">
          <Button
            onClick={() => {
              modalData(data); modalVisibility(true); formik.setFieldValue('idTrx', data.id_trx);
            }}
            className="flex w-full"
            autoFill
            styling="filter"
          >
            Unggah Bukti
          </Button>
          <Button
            onClick={() => { formik.setFieldValue('idTrx', data.id_trx); cancelModalVisibility(true); }}
            color="danger"
            autoFill
            className="flex w-full"
            styling="filter"
          >
            Cancel Transaksi
          </Button>
        </div>
      );
    }
    if (type?.includes('banner')) {
      return (
        <div className="flex flex-row gap-4">
          <Button
            color="danger"
            autoFill
            className="flex w-full"
            styling="filter"
            onClick={() => {
              customFunc.setDeleteData(data.idBanner);
            }}
          >
            Hapus Banner
          </Button>
          <Button
            className="flex w-full"
            autoFill
            styling="filter"
            onClick={() => {
              modalData(data);
              modalVisibility(true);
            }}
          >
            Ubah Banner
          </Button>
        </div>
      );
    }
    return <div hidden />;
  };

  const cardBody = () => {
    if (type?.includes('unverified') || type?.includes('unpaid') || type?.includes('refund')) {
      return (
        <div className="flex flex-row justify-start gap-3">
          <div className="flex flex-row justify-start items-center gap-1 flex-1">
            {type?.includes('refund')
              ? <div hidden />
              : (
                <div className="flex border-[2px] rounded-[8px] p-2 my-2 h-full
                  items-center w-full min-w-[150px]"
                >
                  <div>
                    <img
                      src={data.dari_payment_logo}
                      alt={data.dari_payment_logo}
                      className="h-[25px]"
                    />
                  </div>
                </div>
              )}
            {type?.includes('refund')
              ? <div hidden />
              : (
                <div className="flex justify-center items-center flex-none">
                  <BiRightArrowAlt size="50px" />
                </div>
              )}
            <div className="flex flex-col border-[2px] rounded-[8px] p-2 my-2 h-full
              justify-center w-full min-w-[150px]"
            >
              <div>
                {type?.includes('-ukt')
                  ? (
                    <img
                      src="/icons/logo-polban.png"
                      alt="logo polban"
                      className="h-[25px]"
                    />
                  )
                  : (
                    <img
                      src={data.ke_wallet_logo}
                      alt={data.ke_wallet_logo}
                      className="h-[25px]"
                    />
                  )}
              </div>
              <div className="text-">{data.ke_no_telepon}</div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between gap-2 flex-1">
            {type?.includes('refund')
              ? (
                <div className="flex flex-col justify-start min-w-[200px]">
                  <div className="flex">Bukti Transfer</div>
                  <Button
                    onClick={async () => {
                      const src = await getUserProof();
                      proofUser(src); imgModal(true);
                    }}
                    color="light-blue"
                    autoFill
                    className="flex w-full grow"
                    styling="filter"
                  >
                    Lihat Bukti Transfer
                  </Button>
                </div>
              )
              : (
                <div className="flex flex-col justify-start min-w-[200px]">
                  <div className="flex">Nominal</div>
                  <div className="flex text-[29px] font-bold">{formatMoney(data.nominal)}</div>
                </div>
              )}
            <div className="flex flex-col justify-start min-w-[150px] flex-1 max-w-[400px]">
              <div className="flex">Proses</div>
              {getProsesComponent()}
            </div>
          </div>
        </div>
      );
    }
    if (type?.includes('pemeliharaan')) {
      return (
        <div className="flex flex-col grow justify-between gap-4 overflow-x-auto">
          <div className="flex flex-row justify-between flex-1 gap-4">
            <div className="flex flex-col gap-2 w-2/5">
              <div className="font-bold">
                Judul Pemeliharaan
              </div>
              <div>
                {data.MESSAGE}
              </div>
            </div>
            <div className="flex flex-col gap-2 grow">
              <div className="font-bold">
                Deskripsi Pemeliharaan
              </div>
              <div>
                {data.DESCRIPTION}
              </div>
            </div>
            <div className="flex flex-col gap-2 grow">
              <div className="font-bold">
                URL Webview
              </div>
              <a
                href={data.WEBVIEW_URL}
                target="_blank"
                rel="noreferrer"
                className="text-[#4A86E8] underline"
              >
                {data.WEBVIEW_URL}
              </a>
            </div>
          </div>
          <div className="flex flex-col ">
            {getProsesComponent()}
          </div>
        </div>
      );
    }
    if (type?.includes('banner')) {
      return (
        <div className="flex flex-col grow justify-between gap-4">
          <div className="flex flex-row justify-between flex-1 gap-4 items-center">
            <div
              className="flex flex-col w-80 rounded-lg bg-black overflow-hidden h-fit cursor-pointer"
              onClick={() => { imgModal({ visibility: true, src: data.bannerImage }); }}
            >
              <img
                src={data.bannerImage}
                alt="test"
                className="w-full h-20 object-cover"
              />
            </div>
            <div className="flex flex-col gap-2 grow">
              <div className="flex flex-row justify-between gap-2">
                <div className="font-bold">
                  Pengiklan
                </div>
                <div>
                  {data.adsSupplier}
                </div>
              </div>
              <div className="flex flex-row justify-between gap-2">
                <div className="font-bold">
                  Start Date
                </div>
                <div>
                  {formatDate(data.startDate)}
                </div>
              </div>
              <div className="flex flex-row justify-between gap-2">
                <div className="font-bold">
                  End Date
                </div>
                <div>
                  {formatDate(data.endDate)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            {getProsesComponent()}
          </div>
        </div>
      );
    }
    return (<div hidden />);
  };
  if (type?.includes('pemeliharaan')) {
    return (
      <div className="flex flex-1">
        <div
          style={cardTopBorder()}
          className="flex flex-col w-full gap-2 grow border-[2px] rounded-[8px] p-4"
        >
          {cardHeader()}
          {cardBody()}
        </div>
      </div>
    );
  }
  if (type?.includes('banner')) {
    return (
      <div className="flex w-[300px] sm:w-[calc(50%-8px)] flex-1 sm:flex-none">
        <div
          style={cardTopBorder()}
          className="flex flex-col w-full gap-2 grow border-[2px] rounded-[8px] p-4"
        >
          {cardHeader()}
          {cardBody()}
        </div>
      </div>
    );
  }
  return (
    <div className="flex w-full">
      <div
        style={cardTopBorder()}
        className="flex flex-col gap-2 border-[2px] grow rounded-[8px] p-2 my-2"
      >
        {cardHeader()}
        {cardBody()}
      </div>
    </div>
  );
}

export function CardList({
  type, dataList, modalData, modalVisibility, formik, cancelModalVisibility, proofUser, imgModal,
  customFunc,
}) {
  if (type?.includes('pemeliharaan')) {
    return (
      <div className="flex w-full h-full">
        <div className="flex flex-wrap gap-4 w-0 flex-1 h-full overflow-x-auto">
          {
          dataList.map((data, index) => (
            <Card
              key={index}
              type={type}
              formik={formik}
              imgModal={imgModal}
              proofUser={proofUser}
              cancelModalVisibility={cancelModalVisibility}
              modalData={modalData}
              modalVisibility={modalVisibility}
              data={data}
              customFunc={customFunc}
            />
          ))
}
        </div>
      </div>
    );
  }
  if (type?.includes('banner')) {
    return (
      <div className="flex w-full h-full">
        <div className="flex flex-wrap gap-4 w-0 flex-1 h-full overflow-x-auto">
          {
          dataList.map((data, index) => (
            <Card
              key={index}
              type={type}
              formik={formik}
              imgModal={imgModal}
              proofUser={proofUser}
              cancelModalVisibility={cancelModalVisibility}
              modalData={modalData}
              modalVisibility={modalVisibility}
              data={data}
              customFunc={customFunc}
            />
          ))
}
        </div>
      </div>
    );
  }
  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col w-0 flex-1 h-full overflow-x-auto">
        {dataList.map((data, index) => (
          <Card
            key={index}
            type={type}
            formik={formik}
            imgModal={imgModal}
            proofUser={proofUser}
            cancelModalVisibility={cancelModalVisibility}
            modalData={modalData}
            modalVisibility={modalVisibility}
            data={data}
          />
        ))}
      </div>
    </div>
  );
}
